<input
    [ngClass]="'dpm-input-' + inputStyle"
    [type]="inputType"
    autocomplete="off"
    [placeholder]="placeholder"
    [formControl]="formControl"
    (change)="handleInputChange($event)"
    (keyup)="handleKeyUp($event)"
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    [disabled]="disabled"
    cdkFocusInitial
    *ngIf="autoFocus && !multiline && !matAutocomplete"
/>
<input
    #input
    [ngClass]="'dpm-input-' + inputStyle"
    [type]="inputType"
    autocomplete="off"
    [placeholder]="placeholder"
    [formControl]="formControl"
    (change)="handleInputChange($event)"
    (keyup)="handleKeyUp($event)"
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    [disabled]="disabled"
    *ngIf="!autoFocus && !multiline && !matAutocomplete"
/>
<input
    [ngClass]="'dpm-input-' + inputStyle"
    [type]="inputType"
    [matAutocomplete]="matAutocomplete"
    [placeholder]="placeholder"
    [formControl]="formControl"
    (change)="handleInputChange($event)"
    (keyup)="handleKeyUp($event)"
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    [disabled]="disabled"
    *ngIf="matAutocomplete"
/>
<textarea
    [ngClass]="'dpm-input-' + inputStyle"
    autocomplete="off"
    [placeholder]="placeholder"
    [formControl]="formControl"
    (change)="handleInputChange($event)"
    (keyup)="handleKeyUp($event)"
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    [disabled]="disabled"
    cdkFocusInitial
    *ngIf="autoFocus && multiline && !matAutocomplete"
></textarea>
<textarea
    [ngClass]="'dpm-input-' + inputStyle"
    autocomplete="off"
    [placeholder]="placeholder"
    [formControl]="formControl"
    (change)="handleInputChange($event)"
    (keyup)="handleKeyUp($event)"
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    [disabled]="disabled"
    *ngIf="!autoFocus && multiline && !matAutocomplete"
></textarea>
<div class="text-danger mt-1 ml-1" *ngIf="error">{{ error }}</div>
