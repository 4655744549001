<div class="dialog-wrapper-account" id="logIn">
    <div class="dialog-close-wrapper">
        <button mat-button mat-dialog-close class="dialog-close">close</button>
    </div>
    <h1 mat-dialog-title class="dialog-title">datapm</h1>
    <p class="dialog-description">Welcome back!</p>
    <div mat-dialog-content>
        <form [formGroup]="loginForm" (ngSubmit)="formSubmit()">
            <app-input
                placeholder="Username or email address"
                [group]="loginForm"
                class="rounded-input"
                controlName="username"
                [error]="loginForm | inputError: 'username' | async"
                cdkFocusInitial
            >
            </app-input>
            <app-input
                inputType="password"
                placeholder="Password"
                [group]="loginForm"
                controlName="password"
                class="rounded-input"
                [error]="loginForm | inputError: 'password' | async"
            >
            </app-input>

            <div class="alert-div">
                <div class="alert-warn" *ngIf="state == State.LOGIN_ERROR_VALIDATE_EMAIL">
                    You have not verified your email address. Check your email inbox for a "Verify Your Account" email,
                    and click the "Verify My Email Address" button. You can then log-in.
                </div>
                <div class="alert-warn" *ngIf="state == State.INCORRECT_LOGIN">
                    Your username and password were not correct.
                </div>
                <div class="alert-warn" *ngIf="state == State.LOGIN_ERROR_ACCOUNT_SUSPENDED">
                    Your account has been suspended.
                </div>
                <div class="alert-warn" *ngIf="state == State.LOGIN_ERROR">
                    There was a problem communicating with the server. Please try again, or contact support.
                </div>
            </div>

            <p class="forgot-pass text-left mt-2 mb-0">
                <a href="#" (click)="openForgotPasswordDialog($event)">Forgot password?</a>
            </p>
            <p class="forgot-pass text-left">
                <a href="#" (click)="openSignupDialog($event)">Need to create an account?</a>
            </p>
            <br />

            <div class="content-btn text-center">
                <button type="submit" class="app-raised-button mb-1 mt-4" [disabled]="!loginForm.valid">
                    <span *ngIf="state != State.AWAITING_RESPONSE" class="btn-text">Login</span>
                    <span *ngIf="state == State.AWAITING_RESPONSE" class="loading-spinner">
                        <mat-spinner diameter="20"></mat-spinner>
                    </span>
                </button>
                <br />
            </div>
        </form>
    </div>
</div>
