<div id="collectionList">
    <div class="mb-4 pb-1">
        <div class="collections-container">
            <swiper
                [direction]="'horizontal'"
                [slidesPerView]="3"
                [slidesPerColumn]="2"
                [pagination]="false"
                [navigation]="true"
                class="mySwiper"
                [mousewheel]="true"
                [keyboard]="{
                    enabled: true
                }"
                [breakpoints]="{
                    '@0.00': {
                        slidesPerView: 1,
                        slidesPerColumn: 2,
                        spaceBetween: 0
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        slidesPerColumn: 2,
                        spaceBetween: 0
                    },
                    '@1.00': {
                        slidesPerView: 2,
                        slidesPerColumn: 2,
                        spaceBetween: 0
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        slidesPerColumn: 2,
                        spaceBetween: 0
                    }
                }"
            >
                <ng-template swiperSlide *ngFor="let collection of collections">
                    <div class="collection-item" (click)="goToCollection(collection.identifier.collectionSlug)">
                        {{ collection.name }}
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
</div>
