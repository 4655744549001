<div class="container-fluid p-0">
    <div class="row">
        <div class="col-md-12">
            <sd-header></sd-header>
            <div class="content">
                <router-outlet></router-outlet>
            </div>
            <sd-footer></sd-footer>
        </div>
    </div>
</div>
