<div class="container">
    <div class="row">
        <div class="col-md-12 p-0" id="deleteUser">
            <div mat-dialog-title id="contentTitle">{{ title }}</div>
            <div mat-dialog-content id="contentBorder" style="padding-bottom: 15px">
                <div *ngIf="warning" class="white-border">
                    <p>{{ warning }}</p>
                </div>

                <div>
                    <p [innerHTML]="content"></p>
                </div>

                <div *ngIf="showConfirmationInputField">
                    <p>
                        Type <span class="slug">{{ confirmationInputFieldRequiredValue }}</span> here
                    </p>

                    <div class="one-line-input py-3">
                        <input matInput [(ngModel)]="confirmationInputFieldValue" autocomplete="off" />
                    </div>
                </div>
            </div>

            <div mat-dialog-actions class="d-flex justify-content-between">
                <button class="d-none" cdkFocusInitial></button>
                <button mat-dialog-close class="modal-close-btn">{{ cancelButtonText }}</button>
                <button class="btn-delete" (click)="confirm()" [disabled]="shouldDisableConfirmButton">
                    {{ confirmButtonText }}
                </button>
            </div>
        </div>
    </div>
</div>
