<app-input
    placeholder=""
    [group]="form"
    class="flat-input px-0"
    controlName="catalogSlug"
    inputStyle="flat"
    [matAutocomplete]="auto"
    (keydown)="catalogInputKeyDown()"
    [error]="form | inputError: 'catalogSlug' | async"
></app-input>
<mat-autocomplete #auto="matAutocomplete" class="search-autocomplete">
    <mat-option *ngIf="catalogKeyDownHasHappened && !autoCompleteResult && form.value.length > 1">
        <mat-spinner [diameter]="30" class="ml-3"></mat-spinner>
    </mat-option>
    <ng-container *ngIf="autoCompleteResult">
        <mat-option *ngFor="let option of autoCompleteResult.catalogs" [value]="option.identifier.catalogSlug">
            <div class="d-flex">
                <div>
                    <app-avatar class="align-top user-img" [catalog]="option" [size]="40"> </app-avatar>
                </div>

                <div class="autocomplete-contents">
                    <div class="autocomplete-title">
                        {{ option.identifier.catalogSlug }}
                    </div>

                    <div class="autocomplete-description">{{ option.description }}</div>
                </div>
            </div>
        </mat-option>
    </ng-container>
</mat-autocomplete>
