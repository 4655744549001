<div class="container" id="packagesPage">
    <div class="row">
        <div class="col-md-12">
            <div class="big-div-package">
                <div *ngIf="state == State.SUCCESS">
                    <div class="text-main-title col-md-12 no-pd-mobile">
                        {{ isCurrentUser ? "My Packages" : "Packages" }}
                    </div>
                    <div class="col-lg-9 no-pd-mobile">
                        <span *ngIf="isCurrentUser" class="text-dark-gray">
                            <span *ngIf="packages.length == 0">
                                You have no published packages in your user catalog on this registry.
                            </span>
                            <span *ngIf="packages.length > 0">
                                Below are the packages you have published in your user catalog on this registry.
                            </span>
                            <a href="/docs/publish-data" target="_blank">
                                <span style="font-size: 13px">Learn how to publish a package.</span>
                            </a>
                        </span>

                        <p *ngIf="!isCurrentUser && packages.length > 0" class="text-dark-gray">
                            Packages of data published by <span style="font-weight: 500">{{ username }}</span
                            >.
                        </p>
                        <p *ngIf="!isCurrentUser && packages.length == 0" class="text-dark-gray">
                            Either <span style="font-weight: 500">{{ username }}</span> has not published a package, or
                            you do not have permission to any of the packages they have published.
                        </p>
                    </div>

                    <div class="container no-pd-mobile">
                        <app-package-item
                            [item]="package"
                            shouldShowActionButton="true"
                            actionButtonText="Add To Collection"
                            (action)="addToCollection(package)"
                            *ngFor="let package of packages"
                        ></app-package-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="state == State.LOADING">
        <div class="col-12">
            <div class="loading-container">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
        </div>
    </div>
</div>
