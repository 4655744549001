<div class="container">
    <div class="row">
        <div class="col-md-12 p-0" id="changeStatus">
            <div mat-dialog-title id="contentTitle">Confirm user status change</div>
            <div mat-dialog-content id="contentBorder">
                <div>
                    <p>
                        Are you sure you want to change this <span id="username-label">{{ user.username }}</span
                        >'s status?
                    </p>
                </div>

                <div>
                    <p>They will be notified for this change via email.</p>
                </div>

                <mat-select [(value)]="status">
                    <mat-option [value]="UserStatus.ACTIVE">Active</mat-option>
                    <mat-option [value]="UserStatus.SUSPENDED">Suspended</mat-option>
                </mat-select>

                <div class="mt-3">
                    Type your message here.
                    <div class="one-line-input py-2">
                        <input matInput [(ngModel)]="message" autocomplete="off" />
                    </div>
                </div>
            </div>

            <div mat-dialog-actions class="d-flex justify-content-between">
                <button mat-dialog-close class="modal-close-btn" (click)="cancel()">Cancel</button>
                <button class="modal-submit-btn" (click)="confirm()" [disabled]="loading">
                    <span>Confirm</span>
                </button>
            </div>
        </div>
    </div>
</div>
