<ng-container *ngIf="loadedCollectionsInitially && loadedPackagesInitially && loadedCatalogsInitially; else pageLoader">

    <div *ngIf="catalogs?.length">
        <div class="container mt-5">
            <div class="text-main-title text-left no-pd-mobile">Catalogs</div>
            <div *ngFor="let catalog of catalogs">
                <app-catalog-item [item]="catalog" (click)="catalogClick(catalog)"></app-catalog-item>
            </div>
    
            <div *ngIf="hasMoreCatalogs" class="container text-center">
                <button class="app-outlined-button ctaButton" [class.disabled]="loadingCatalogs"
                    (click)="requestMoreCatalogs()">
                    Show More
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="collections?.length">
        <div class="container">
            <div class="text-main-title mt-4 text-left no-pd-mobile">Collections</div>
            <app-collections-horizontal-list [collections]="collections"></app-collections-horizontal-list>
        </div>
    </div>

    <div *ngIf="packages.length">
        <div class="container mt-5">
            <div class="text-main-title text-left no-pd-mobile">{{title}}</div>
            <div *ngFor="let package of packages">
                <app-package-item [item]="package"></app-package-item>
            </div>

            <div *ngIf="hasMorePackages" class="container text-center">
                <button
                    class="app-outlined-button ctaButton"
                    [class.disabled]="loadingPackages"
                    (click)="requestMorePackages()"
                >
                    Show More
                </button>
            </div>
        </div>
    </div>

    <div class="loading-spinner" *ngIf="loadingPackages">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div *ngIf="!loadingPackages && !packages.length && !collections.length && !catalogs.length && !hasPackageErrors">
        <ng-container *ngTemplateOutlet="emptyStateTemplate"></ng-container>
    </div>

    <ng-template #emptyStateTemplate><ng-content select="[packagesEmptyState]"></ng-content></ng-template>

    <ng-template #errorStateTemplate><ng-content select="[packagesErrorState]"></ng-content></ng-template>

    <div *ngIf="!loadingPackages && !packages.length && hasPackageErrors">
        <ng-container *ngTemplateOutlet="errorStateTemplate"></ng-container>
    </div>

    <ng-template #collectionsLoadingTemplate> <mat-spinner [diameter]="50"></mat-spinner> </ng-template>
</ng-container>

<ng-template class="loading-spinner text-center" #pageLoader>
    <mat-spinner style="margin: 0 auto" [diameter]="50"></mat-spinner>
</ng-template>
