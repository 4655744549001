<form id="editPassword">
    <div mat-dialog-title id="contentTitle">Change My Password</div>
    <div mat-dialog-content id="contentBorder">
        <div class="d-flex bd-highlight shadow-div my-3">
            <div class="pr-3 py-2 bd-highlight div-question">Current password</div>
            <div class="bd-highlight div-answer">
                <input
                    [formControl]="currentPassword"
                    type="password"
                    class="flat-input"
                    placeholder="Current password"
                />
                <div class="div-with-errors">
                    <div class="alert-warn" *ngIf="state == State.WRONG_CREDENTIALS">
                        Does not match your current password
                    </div>
                    <div class="alert-warn" *ngIf="currentPassword.touched && currentPassword.errors?.REQUIRED">
                        Current password is required
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex bd-highlight shadow-div my-3 pb-2">
            <div class="pr-3 py-2 bd-highlight div-question">New password</div>
            <div class="bd-highlight div-answer">
                <input
                    [formControl]="newPassword"
                    type="password"
                    class="flat-input"
                    placeholder="New password"
                    autocomplete="off"
                />
                <div class="div-with-errors">
                    <div class="alert-warn" *ngIf="newPassword.touched && newPassword.errors?.REQUIRED">
                        New password is required
                    </div>
                    <div class="alert-warn" *ngIf="newPassword.touched && newPassword.errors?.PASSWORD_TOO_LONG">
                        New password is too long
                    </div>
                    <div class="alert-warn" *ngIf="newPassword.touched && newPassword.errors?.PASSWORD_TOO_SHORT">
                        New password must be at least 8 characters
                    </div>
                    <div class="alert-warn" *ngIf="newPassword.touched && newPassword.errors?.INVALID_CHARACTERS">
                        Passwords less than 16 characters must include one number or a special character (@ # $ % !)
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button (click)="submit()" class="ml-auto modal-submit-btn" [class.disabled]="form.invalid">
            <span>save</span>
        </button>
    </div>
</form>
