<form id="createCatalog" [formGroup]="form">
    <div mat-dialog-title id="contentTitle">Create Catalog</div>
    <app-cover [editable]="true" (upload)="addCoverImage($event)"></app-cover>

    <app-avatar
        class="catalog-avatar"
        [circled]="false"
        [size]="100"
        [editable]="true"
        (upload)="addAvatarImage($event)"
    >
    </app-avatar>
    <div mat-dialog-content id="contentBorder">
        <div class="d-flex bd-highlight shadow-div my-3">
            <div class="pr-3 py-2 bd-highlight div-question">Catalog Name*</div>
            <div class="bd-highlight div-answer">
                <input formControlName="displayName" class="flat-input" placeholder="Catalog Name" cdkFocusInitial />
            </div>
        </div>
        <div class="d-flex bd-highlight shadow-div mb-3">
            <div class="pr-3 py-2 bd-highlight div-question">Description</div>
            <div class="bd-highlight div-answer">
                <textarea formControlName="description" class="flat-input" placeholder="Description"></textarea>
            </div>
        </div>
        <div class="d-flex bd-highlight shadow-div mb-1">
            <div class="pr-3 py-2 bd-highlight div-question">Website</div>
            <div class="bd-highlight div-answer">
                <input formControlName="website" class="flat-input" placeholder="Website" />
            </div>
        </div>
        <div class="d-flex bd-highlight shadow-div">
            <div class="pr-3 py-2 bd-highlight div-question">Public</div>
            <div class="bd-highlight d-flex align-items-center div-answer">
                <mat-slide-toggle class="app-slide-toggle" formControlName="isPublic"></mat-slide-toggle>
            </div>
        </div>
        <div class="d-flex bd-highlight shadow-div mb-3" *ngIf="currentUser.isAdmin">
            <div class="pr-3 py-2 bd-highlight div-question">Unclaimed</div>
            <div class="bd-highlight d-flex align-items-center div-answer">
                <mat-slide-toggle class="app-slide-toggle" formControlName="unclaimed"></mat-slide-toggle>
            </div>
        </div>
        <div class="text-danger pl-3" *ngIf="state === 'ERROR'">
            {{ error }}
        </div>
    </div>
    <div mat-dialog-actions>
        <button (click)="submit()" class="ml-auto modal-submit-btn" [class.disabled]="form.invalid">
            <span *ngIf="state !== 'LOADING'">create</span>
            <mat-spinner [diameter]="20" *ngIf="state === 'LOADING'"></mat-spinner>
        </button>
    </div>
</form>
