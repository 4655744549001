<div class="container">
    <div class="row">
        <form [formGroup]="form" id="editCollection">
            <div mat-dialog-title id="contentTitle">Edit collection</div>
            <app-cover
                [collectionSlug]="data.identifier.collectionSlug"
                [editable]="true"
                (upload)="uploadCover($event)"
            ></app-cover>
            <div mat-dialog-content id="contentBorder">
                <div class="d-flex bd-highlight" *ngIf="data.myPermissions.includes(Permission.MANAGE)">
                    <div class="pr-3 py-2 bd-highlight div-question long-txt">Make this collection public</div>
                    <div class="ml-auto py-2 bd-highlight slide-right d-flex align-items-center">
                        <span *ngIf="isPublicControl.value" class="public-text mr-2">public</span>
                        <span *ngIf="!isPublicControl.value" class="not-public-text mr-2">not public</span>
                        <mat-slide-toggle
                            class="app-slide-toggle"
                            [disabled]="!data.myPermissions.includes(Permission.MANAGE)"
                            formControlName="isPublic"
                        ></mat-slide-toggle>
                    </div>
                </div>
                <div class="d-flex bd-highlight shadow-div mb-3">
                    <div class="pr-3 py-2 bd-highlight div-question">Name</div>
                    <div class="bd-highlight div-answer">
                        <app-input
                            [group]="form"
                            controlName="name"
                            placeholder="Name"
                            [autoFocus]="true"
                            class="flat-input-parent"
                            [error]="form | inputError: 'name':errorMsg['name'] | async"
                        ></app-input>
                    </div>
                </div>
                <div class="d-flex bd-highlight shadow-div mb-3">
                    <div class="pr-3 py-2 bd-highlight div-question">Collection slug</div>
                    <div class="bd-highlight div-answer">
                        <app-input
                            [group]="form"
                            controlName="newCollectionSlug"
                            placeholder="Collection slug"
                            (blur)="openConfirmDialog()"
                            class="flat-input-parent"
                            [error]="form | inputError: 'newCollectionSlug':errorMsg['newCollectionSlug'] | async"
                        ></app-input>
                    </div>
                </div>
                <div class="d-flex bd-highlight shadow-div mb-3">
                    <div class="pr-3 py-2 bd-highlight div-question">Description</div>
                    <div class="bd-highlight div-answer">
                        <app-input
                            class="flat-input-parent"
                            [group]="form"
                            controlName="description"
                            placeholder="Description"
                            [autoFocus]="true"
                            [multiline]="true"
                        ></app-input>
                    </div>
                </div>
                <div class="text-danger mt-3" *ngIf="state === 'ERROR'">Error occured while updating collection.</div>
            </div>
            <div mat-dialog-actions class="d-flex justify-content-between pb-2">
                <button mat-dialog-close class="modal-close-btn">Close</button>
                <button class="ml-auto modal-submit-btn" [disabled]="form.invalid" (click)="save()">
                    <span *ngIf="state !== 'LOADING'">Save</span>
                    <mat-spinner [diameter]="20" class="mx-auto" *ngIf="state === 'LOADING'"></mat-spinner>
                </button>
            </div>
        </form>
    </div>
</div>
