<div class="footer-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <app-builder-io-renderer *ngIf="loaded; else loadingTemplate" [apiKey]="apiKey" [entry]="entry">
                    <div class="container">Failed to load footer</div>
                </app-builder-io-renderer>

                <ng-template #loadingTemplate></ng-template>
            </div>
        </div>
    </div>
</div>

<div class="w-100 mb-3">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 main-col-footer">
                <div class="text-dark-gray px-2">DataPM is a service of <a href="https://www.bigarmor.com/">Big Armor</a></div>
            </div>
            <div class="col-sm-6 darkmode-div">
                <a aria-label="Angular on twitter" title="Dark theme" class="px-2">
                    <mat-slide-toggle class="footer-slide-toggle" [checked]="darkModeEnabled" (change)="toggleTheme()">
                        <span class="text-dark-gray" *ngIf="!darkModeEnabled">Dark mode</span>
                        <span class="text-dark-gray" *ngIf="darkModeEnabled">Light mode</span>
                    </mat-slide-toggle>
                </a>
            </div>
        </div>
    </div>
</div>
