<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div id="myCollections" class="big-div-collection">
                <div class="mb-3">
                    <div *ngIf="state === State.SUCCESS">
                        <div class="text-main-title col-md-12 no-pd-mobile">
                            {{ isCurrentUser ? "My Collections" : "Collections" }}
                        </div>
                        <ng-container *ngIf="collections.length === 0">
                            <div class="mb-3 col-lg-8 no-pd-mobile">
                                <span *ngIf="isCurrentUser" class="text-dark-gray"
                                    >Create collections to organize and share data sets you discover.
                                </span>
                                <span *ngIf="!isCurrentUser" class="text-dark-gray"
                                    >Either <span style="font-weight: 500">{{ username }}</span> has not created a
                                    collection, or you do not have permission to view of any of the collections they
                                    have created.
                                </span>

                                <a target="_blank" href="/docs/organize-data">
                                    <span style="font-size: 13px">Learn more about collections.</span>
                                </a>
                            </div>
                            <div class="col-md-12 no-pd-mobile my-3" *ngIf="isCurrentUser">
                                <a class="app-outlined-button" (click)="createCollection()">Create A Collection</a>
                            </div>
                        </ng-container>

                        <div *ngIf="collections.length > 0">
                            <div class="mb-3 col-lg-8 no-pd-mobile">
                                <p>
                                    <span *ngIf="isCurrentUser" class="text-dark-gray">
                                        Create collections to organize and share data sets you discover.
                                    </span>
                                    <span *ngIf="!isCurrentUser" class="text-dark-gray">
                                        Collections of data created by
                                        <span style="font-weight: 500">{{ username }}</span
                                        >.
                                    </span>
                                    <a target="_blank" href="/docs/organize-data">
                                        <span style="font-size: 13px"> Learn more about collections.</span>
                                    </a>
                                </p>
                            </div>

                            <div class="mb-4 col-md-12 no-pd-mobile" *ngIf="state !== State.LOADING && isCurrentUser">
                                <a class="app-outlined-button" (click)="createCollection()">Create A Collection</a>
                            </div>

                            <div class="col-lg-10 col-md-12 no-pd-mobile">

                                <app-collection-item [item]="collection" (edited)="loadMyCollections()" (deleted)="loadMyCollections()" (click)="goToCollection(collection)" *ngFor="let collection of collections">

                                </app-collection-item>

                                
                            </div>
                        </div>
                    </div>

                    <div class="loading-container" *ngIf="state === State.LOADING">
                        <mat-spinner [diameter]="40"></mat-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
