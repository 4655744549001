import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** ISO 8601 serialized Date strings */
  Date: Date;
  /** Package files are JSON serialized and validated using JSONSchema */
  PackageFileJSON: any;
  /** A no-return value, mapped to void in typescript */
  Void: void;
  /** Generic object JSON serialized strings */
  JSON: any;
  /** ValidationString is used at runtime by the validate***Directive classes to allow apollo a intermediate type for conversions */
  ValidationString: any;
  /** A string that uniquely identifies a catalog - this is an intermediate type only for internal validation */
  CatalogSlug: any;
  /** A string that uniquely identifies a package - this is an intermediate type only for internal validation */
  PackageSlug: any;
  /** A string that uniquely identifies a collection - this is an intermediate type only for internal validation */
  CollectionSlug: any;
  /** A string that uniquely identifies an email address - this is an intermediate type only for internal validation */
  EmailAddress: any;
  /** A string that is a user's password - this is an intermediate type only for internal validation */
  Password: any;
  /** A string that uniquely identifies a Username - this is an intermediate type only for internal validation */
  Username: any;
  /** A string of markdown content - this is an intermidiate type only for internal validation */
  markdown: any;
  /** A string that uniquely identifies a Username or Email address - this is an intermediate type only for internal validation */
  UsernameOrEmailAddress: any;
};






















/** Represents a logged user activity */
export type ActivityLog = {
  __typename?: 'ActivityLog';
  id: Scalars['Int'];
  user?: Maybe<User>;
  targetPackage?: Maybe<Package>;
  targetPackageIssue?: Maybe<PackageIssue>;
  targetPackageVersion?: Maybe<Version>;
  targetCollection?: Maybe<Collection>;
  targetUser?: Maybe<User>;
  targetGroup?: Maybe<Group>;
  targetCatalog?: Maybe<Catalog>;
  permissions?: Maybe<Array<Scalars['String']>>;
  eventType: ActivityLogEventType;
  changeType?: Maybe<ActivityLogChangeType>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  propertiesEdited?: Maybe<Array<Scalars['String']>>;
};

export enum ActivityLogChangeType {
  VERSION_FIRST_VERSION = 'VERSION_FIRST_VERSION',
  VERSION_TRIVIAL_CHANGE = 'VERSION_TRIVIAL_CHANGE',
  VERSION_PATCH_CHANGE = 'VERSION_PATCH_CHANGE',
  VERSION_MINOR_CHANGE = 'VERSION_MINOR_CHANGE',
  VERSION_MAJOR_CHANGE = 'VERSION_MAJOR_CHANGE',
  PUBLIC_ENABLED = 'PUBLIC_ENABLED',
  PUBLIC_DISABLED = 'PUBLIC_DISABLED',
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
  UNCLAIMED_ENABLED = 'UNCLAIMED_ENABLED',
  UNCLAIMED_DISABLED = 'UNCLAIMED_DISABLED',
  COVER_IMAGE_UPDATED = 'COVER_IMAGE_UPDATED',
  COVER_IMAGE_REMOVED = 'COVER_IMAGE_REMOVED',
  AVATAR_IMAGE_UPDATED = 'AVATAR_IMAGE_UPDATED',
  AVATAR_IMAGE_REMOVED = 'AVATAR_IMAGE_REMOVED'
}

/** Activity Log Event types */
export enum ActivityLogEventType {
  PACKAGE_CREATED = 'PACKAGE_CREATED',
  PACKAGE_DELETED = 'PACKAGE_DELETED',
  PACKAGE_VIEWED = 'PACKAGE_VIEWED',
  PACKAGE_FETCHED = 'PACKAGE_FETCHED',
  PACKAGE_PUBLIC_CHANGED = 'PACKAGE_PUBLIC_CHANGED',
  PACKAGE_EDIT = 'PACKAGE_EDIT',
  PACKAGE_USER_PERMISSION_ADDED_UPDATED = 'PACKAGE_USER_PERMISSION_ADDED_UPDATED',
  PACKAGE_USER_PERMISSION_REMOVED = 'PACKAGE_USER_PERMISSION_REMOVED',
  PACKAGE_GROUP_PERMISSION_ADDED_UPDATED = 'PACKAGE_GROUP_PERMISSION_ADDED_UPDATED',
  PACKAGE_GROUP_PERMISSION_REMOVED = 'PACKAGE_GROUP_PERMISSION_REMOVED',
  DATA_BATCH_UPLOAD_STARTED = 'DATA_BATCH_UPLOAD_STARTED',
  DATA_BATCH_UPLOAD_STOPPED = 'DATA_BATCH_UPLOAD_STOPPED',
  DATA_BATCH_DOWNLOAD_STARTED = 'DATA_BATCH_DOWNLOAD_STARTED',
  DATA_BATCH_DOWNLOAD_STOPPED = 'DATA_BATCH_DOWNLOAD_STOPPED',
  DATA_BATCH_ACTIVE_CHANGED = 'DATA_BATCH_ACTIVE_CHANGED',
  DATA_SINK_STATE_REQUESTED = 'DATA_SINK_STATE_REQUESTED',
  PACKAGE_ISSUE_CREATED = 'PACKAGE_ISSUE_CREATED',
  PACKAGE_ISSUE_EDIT = 'PACKAGE_ISSUE_EDIT',
  PACKAGE_ISSUE_COMMENT_CREATED = 'PACKAGE_ISSUE_COMMENT_CREATED',
  PACKAGE_ISSUE_COMMENT_EDIT = 'PACKAGE_ISSUE_COMMENT_EDIT',
  PACKAGE_ISSUE_COMMENT_DELETED = 'PACKAGE_ISSUE_COMMENT_DELETED',
  PACKAGE_ISSUE_DELETED = 'PACKAGE_ISSUE_DELETED',
  PACKAGE_ISSUE_STATUS_CHANGE = 'PACKAGE_ISSUE_STATUS_CHANGE',
  PACKAGE_UPDATE_JOB_STARTED = 'PACKAGE_UPDATE_JOB_STARTED',
  PACKAGE_UPDATE_JOB_ENDED = 'PACKAGE_UPDATE_JOB_ENDED',
  PACKAGE_JOB_STARTED = 'PACKAGE_JOB_STARTED',
  PACKAGE_JOB_ENDED = 'PACKAGE_JOB_ENDED',
  FETCH_JOB_STARTED = 'FETCH_JOB_STARTED',
  FETCH_JOB_ENDED = 'FETCH_JOB_ENDED',
  VERSION_CREATED = 'VERSION_CREATED',
  VERSION_UPDATED = 'VERSION_UPDATED',
  VERSION_DELETED = 'VERSION_DELETED',
  COLLECTION_CREATED = 'COLLECTION_CREATED',
  COLLECTION_DELETED = 'COLLECTION_DELETED',
  COLLECTION_VIEWED = 'COLLECTION_VIEWED',
  COLLECTION_EDIT = 'COLLECTION_EDIT',
  COLLECTION_PACKAGE_ADDED = 'COLLECTION_PACKAGE_ADDED',
  COLLECTION_PACKAGE_REMOVED = 'COLLECTION_PACKAGE_REMOVED',
  COLLECTION_PUBLIC_CHANGED = 'COLLECTION_PUBLIC_CHANGED',
  COLLECTION_USER_PERMISSION_ADDED_UPDATED = 'COLLECTION_USER_PERMISSION_ADDED_UPDATED',
  COLLECTION_USER_PERMISSION_REMOVED = 'COLLECTION_USER_PERMISSION_REMOVED',
  COLLECTION_GROUP_PERMISSION_ADDED_UPDATED = 'COLLECTION_GROUP_PERMISSION_ADDED_UPDATED',
  COLLECTION_GROUP_PERMISSION_REMOVED = 'COLLECTION_GROUP_PERMISSION_REMOVED',
  CATALOG_CREATED = 'CATALOG_CREATED',
  CATALOG_DELETED = 'CATALOG_DELETED',
  CATALOG_PUBLIC_CHANGED = 'CATALOG_PUBLIC_CHANGED',
  CATALOG_UNCLAIMED_CHANGED = 'CATALOG_UNCLAIMED_CHANGED',
  CATALOG_EDIT = 'CATALOG_EDIT',
  CATALOG_PACKAGE_ADDED = 'CATALOG_PACKAGE_ADDED',
  CATALOG_PACKAGE_REMOVED = 'CATALOG_PACKAGE_REMOVED',
  CATALOG_USER_PERMISSION_ADDED_UPDATED = 'CATALOG_USER_PERMISSION_ADDED_UPDATED',
  CATALOG_USER_PERMISSION_REMOVED = 'CATALOG_USER_PERMISSION_REMOVED',
  CATALOG_GROUP_PERMISSION_ADDED_UPDATED = 'CATALOG_GROUP_PERMISSION_ADDED_UPDATED',
  CATALOG_GROUP_PERMISSION_REMOVED = 'CATALOG_GROUP_PERMISSION_REMOVED',
  USER_CREATED = 'USER_CREATED',
  USER_DELETED = 'USER_DELETED',
  USER_EDIT = 'USER_EDIT',
  USER_STATUS_CHANGED = 'USER_STATUS_CHANGED',
  GROUP_CREATED = 'GROUP_CREATED',
  GROUP_DELETED = 'GROUP_DELETED',
  GROUP_EDIT = 'GROUP_EDIT',
  GROUP_MEMBER_REMOVED = 'GROUP_MEMBER_REMOVED',
  GROUP_MEMBER_PERMISSION_ADDED_UPDATED = 'GROUP_MEMBER_PERMISSION_ADDED_UPDATED'
}

/** For filtering Activity Logs by event type */
export type ActivityLogFilterInput = {
  eventType?: Maybe<Array<Maybe<ActivityLogEventType>>>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

/** The response for a search term auto-complete request */
export type ActivityLogResult = {
  __typename?: 'ActivityLogResult';
  logs: Array<ActivityLog>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/**
 * Represents the API Key, not including the secret. API Keys are secrets that are
 * used provided as authorization by the registry clients.
 */
export type APIKey = {
  __typename?: 'APIKey';
  label: Scalars['String'];
  id: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  lastUsed?: Maybe<Scalars['Date']>;
  scopes: Array<Scope>;
};

/** This response is only sent when creating a new API key, so that the secret is shared only once */
export type APIKeyWithSecret = {
  __typename?: 'APIKeyWithSecret';
  label: Scalars['String'];
  id: Scalars['String'];
  secret: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  scopes: Array<Scope>;
};

export enum AUTHENTICATION_ERROR {
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  EMAIL_ADDRESS_NOT_VERIFIED = 'EMAIL_ADDRESS_NOT_VERIFIED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  ACCOUNT_SUSPENDED = 'ACCOUNT_SUSPENDED'
}

/** The response for a search term auto-complete request */
export type AutoCompleteResult = {
  __typename?: 'AutoCompleteResult';
  catalogs?: Maybe<Array<Catalog>>;
  packages?: Maybe<Array<Package>>;
  collections?: Maybe<Array<Collection>>;
  users?: Maybe<Array<User>>;
};

export type Base64ImageUpload = {
  base64: Scalars['String'];
};

/** The complete identifier for a package on a registry */
export type BasePackageIdentifier = {
  __typename?: 'BasePackageIdentifier';
  catalogSlug: Scalars['String'];
  packageSlug: Scalars['String'];
};

/** Represents a single builderIO page */
export type BuilderIOPage = {
  __typename?: 'BuilderIOPage';
  apiKey: Scalars['String'];
  template?: Maybe<BuilderIOTemplate>;
};

/** Platform template settings type */
export type BuilderIOSettings = {
  __typename?: 'BuilderIOSettings';
  apiKey: Scalars['String'];
  templates?: Maybe<Array<BuilderIOTemplate>>;
};

/** Template type */
export type BuilderIOTemplate = {
  __typename?: 'BuilderIOTemplate';
  key: Scalars['String'];
  entry: Scalars['String'];
};

/** A collection of packages offered by a person or an organization. */
export type Catalog = {
  __typename?: 'Catalog';
  identifier: CatalogIdentifier;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  creator?: Maybe<User>;
  isPublic?: Maybe<Scalars['Boolean']>;
  unclaimed?: Maybe<Scalars['Boolean']>;
  /** The permissions of the requesting user to the catalog */
  myPermissions?: Maybe<Array<Permission>>;
  packages?: Maybe<Array<Maybe<Package>>>;
};

/** The complete identifier for a catalog on a registry */
export type CatalogIdentifier = {
  __typename?: 'CatalogIdentifier';
  registryURL?: Maybe<Scalars['String']>;
  catalogSlug: Scalars['String'];
};

/** The complete identifier for a catalog assuming the registry is based on the HTTP request URL */
export type CatalogIdentifierInput = {
  catalogSlug: Scalars['String'];
};


/** The response for a catalog list request */
export type CatalogsResult = {
  __typename?: 'CatalogsResult';
  catalogs?: Maybe<Array<Catalog>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/**
 *  A collection is a curated list of one or more packages from across one or more
 * catalogs on a given topic. Collections are created and maintained
 * by consumer side users, and generally not the publishers
 */
export type Collection = {
  __typename?: 'Collection';
  creator?: Maybe<User>;
  identifier: CollectionIdentifier;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<Package>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  myPermissions?: Maybe<Array<Permission>>;
};

/** The basic data used to display a collection */
export type CollectionBasicData = {
  __typename?: 'CollectionBasicData';
  identifier: CollectionIdentifier;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** The complete identifier for a collections on a registry */
export type CollectionIdentifier = {
  __typename?: 'CollectionIdentifier';
  registryURL?: Maybe<Scalars['String']>;
  collectionSlug: Scalars['String'];
};

/** The complete identifier for a collection assuming the registry is based on the HTTP request URL */
export type CollectionIdentifierInput = {
  collectionSlug: Scalars['String'];
};

/** Represents a single package's listing in a collection, and information about how it was added */
export type CollectionPackage = {
  __typename?: 'CollectionPackage';
  collection: Collection;
  package: Package;
  addedByUser?: Maybe<User>;
};


/** The response for collections list request */
export type CollectionsResult = {
  __typename?: 'CollectionsResult';
  collections?: Maybe<Array<Collection>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** Defines the a connector and it's properties */
export type ConnectorDescription = {
  __typename?: 'ConnectorDescription';
  connectorType: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  hasSource?: Maybe<Scalars['Boolean']>;
  hasSink?: Maybe<Scalars['Boolean']>;
};

/** The information necessary to create an API Key */
export type CreateAPIKeyInput = {
  label: Scalars['String'];
  scopes: Array<Scope>;
};

/** The properties for creating a catalog */
export type CreateCatalogInput = {
  slug: Scalars['String'];
  displayName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  isPublic: Scalars['Boolean'];
  unclaimed?: Maybe<Scalars['Boolean']>;
};

/** For creating a new collection on the registry. */
export type CreateCollectionInput = {
  collectionSlug: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

/** The properties for creating a package list. */
export type CreatePackageInput = {
  packageSlug: Scalars['String'];
  catalogSlug: Scalars['String'];
  displayName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

/** The input to create a package issue comment */
export type CreatePackageIssueCommentInput = {
  content: Scalars['String'];
};

/** The package issue creation input */
export type CreatePackageIssueInput = {
  subject: Scalars['String'];
  content: Scalars['String'];
};

/** For self service new user sign up */
export type CreateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
  uiDarkModeEnabled?: Maybe<Scalars['Boolean']>;
};

/** For creating an new user for other people as an administrator */
export type CreateUserInputAdmin = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  username: Scalars['String'];
  isAdmin: Scalars['Boolean'];
};

/** To create a new package version, submit a valid package file */
export type CreateVersionInput = {
  packageFile?: Maybe<Scalars['PackageFileJSON']>;
};

export type Credential = {
  __typename?: 'Credential';
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  creator?: Maybe<User>;
  credentialIdentifier?: Maybe<Scalars['String']>;
};

export type CredentialsResult = {
  __typename?: 'CredentialsResult';
  credentials?: Maybe<Array<Credential>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** Information about the current user */
export type CurrentUser = {
  __typename?: 'CurrentUser';
  user: User;
  isAdmin: Scalars['Boolean'];
};



/** The follow type */
export type Follow = {
  __typename?: 'Follow';
  user?: Maybe<User>;
  notificationFrequency: NotificationFrequency;
  eventTypes: Array<Maybe<NotificationEventType>>;
  catalog?: Maybe<Catalog>;
  package?: Maybe<Package>;
  collection?: Maybe<Collection>;
  packageIssue?: Maybe<PackageIssue>;
  followAllPackages?: Maybe<Scalars['Boolean']>;
  followAllPackageIssues?: Maybe<Scalars['Boolean']>;
  changeType?: Maybe<Array<Maybe<ActivityLogChangeType>>>;
};

/** The response for followers list request */
export type FollowersResult = {
  __typename?: 'FollowersResult';
  followers?: Maybe<Array<User>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** The follow identifier input */
export type FollowIdentifierInput = {
  catalog?: Maybe<CatalogIdentifierInput>;
  package?: Maybe<PackageIdentifierInput>;
  collection?: Maybe<CollectionIdentifierInput>;
  packageIssue?: Maybe<PackageIssueFullIdentifierInput>;
  user?: Maybe<UserIdentifierInput>;
};

/** The following entities activity logs type */
export type FollowingActivity = {
  __typename?: 'FollowingActivity';
  logs: Array<ActivityLog>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** Follow Type */
export enum FollowType {
  CATALOG = 'CATALOG',
  COLLECTION = 'COLLECTION',
  PACKAGE = 'PACKAGE',
  PACKAGE_ISSUE = 'PACKAGE_ISSUE',
  USER = 'USER'
}

export type Group = {
  __typename?: 'Group';
  slug: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  users?: Maybe<Array<GroupUser>>;
  myPermissions?: Maybe<Array<Permission>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  creator?: Maybe<User>;
  packagePermissions?: Maybe<Array<GroupPackagePermission>>;
  catalogPermissions?: Maybe<Array<GroupCatalogPermission>>;
  collectionPermissions?: Maybe<Array<GroupCollectionPermission>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type GroupCatalogPermission = {
  __typename?: 'GroupCatalogPermission';
  group?: Maybe<Group>;
  catalog?: Maybe<Catalog>;
  permissions?: Maybe<Array<Permission>>;
  packagePermissions?: Maybe<Array<Permission>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  creator?: Maybe<User>;
};

export type GroupCollectionPermission = {
  __typename?: 'GroupCollectionPermission';
  group?: Maybe<Group>;
  collection?: Maybe<Collection>;
  permissions?: Maybe<Array<Permission>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  creator?: Maybe<User>;
};

export type GroupPackagePermission = {
  __typename?: 'GroupPackagePermission';
  group?: Maybe<Group>;
  package?: Maybe<Package>;
  permissions?: Maybe<Array<Permission>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  creator?: Maybe<User>;
};

export type GroupUser = {
  __typename?: 'GroupUser';
  user?: Maybe<User>;
  group?: Maybe<Group>;
  permissions?: Maybe<Array<Permission>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  creator?: Maybe<User>;
};

export type Image = {
  __typename?: 'Image';
  id?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  mimeType: Scalars['String'];
};

export enum IMAGE_UPLOAD_ERROR_TYPE {
  IMAGE_NOT_INITIALIZED = 'IMAGE_NOT_INITIALIZED',
  IMAGE_FORMAT_NOT_SUPPORTED = 'IMAGE_FORMAT_NOT_SUPPORTED',
  IMAGE_TOO_LARGE = 'IMAGE_TOO_LARGE',
  UNKNOWN = 'UNKNOWN'
}

export enum INVALID_EMAIL_ADDRESS_ERROR {
  REQUIRED = 'REQUIRED',
  TOO_LONG = 'TOO_LONG',
  INVALID_EMAIL_ADDRESS_FORMAT = 'INVALID_EMAIL_ADDRESS_FORMAT'
}

export enum INVALID_PASSWORD_ERROR {
  PASSWORD_REQUIRED = 'PASSWORD_REQUIRED',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PASSWORD_TOO_LONG = 'PASSWORD_TOO_LONG',
  INVALID_CHARACTERS = 'INVALID_CHARACTERS'
}

export enum INVALID_USERNAME_ERROR {
  USERNAME_REQUIRED = 'USERNAME_REQUIRED',
  USERNAME_TOO_SHORT = 'USERNAME_TOO_SHORT',
  USERNAME_TOO_LONG = 'USERNAME_TOO_LONG',
  INVALID_CHARACTERS = 'INVALID_CHARACTERS'
}

export enum JobType {
  INSTANT_NOTIFICATIONS = 'INSTANT_NOTIFICATIONS',
  HOURLY_NOTIFICATIONS = 'HOURLY_NOTIFICATIONS',
  DAILY_NOTIFICATIONS = 'DAILY_NOTIFICATIONS',
  WEEKLY_NOTIFICATIONS = 'WEEKLY_NOTIFICATIONS',
  MONTHLY_NOTIFICATIONS = 'MONTHLY_NOTIFICATIONS',
  PACKAGE_UPDATE = 'PACKAGE_UPDATE'
}


/** The response for latest packages request */
export type LatestPackagesResult = {
  __typename?: 'LatestPackagesResult';
  packages?: Maybe<Array<Package>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};


export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a new user with the given input. Returns the user's authorization token. */
  createMe?: Maybe<Scalars['Void']>;
  /** Updates the requesting user's information. All fields optional. */
  updateMe: CurrentUser;
  /** Updates the user's admin status. */
  setAsAdmin?: Maybe<Scalars['Void']>;
  /** Updates the group's admin status. */
  setGroupAsAdmin?: Maybe<Scalars['Void']>;
  /** Updates the user's status and sends a notification email to them */
  adminSetUserStatus?: Maybe<Scalars['Void']>;
  /** Updates the logged in users' password */
  updateMyPassword?: Maybe<Scalars['Void']>;
  /** Sends email to user if password is forgotten */
  forgotMyPassword?: Maybe<Scalars['Void']>;
  /** Sends email to user to update their password with token */
  recoverMyPassword?: Maybe<Scalars['Void']>;
  /** Updates the avatar image of the authenticated user */
  setMyCoverImage?: Maybe<Scalars['Void']>;
  /** Updates the avatar image of the authenticated user */
  setMyAvatarImage?: Maybe<Scalars['Void']>;
  /** Deletes the requesting user's account */
  deleteMe?: Maybe<Scalars['Void']>;
  /** Deletes a user's account */
  adminDeleteUser?: Maybe<Scalars['Void']>;
  /** Deletes a group */
  adminDeleteGroup?: Maybe<Scalars['Void']>;
  /** Using a username or email address and a password, requests authentication information for the given user */
  login: Scalars['String'];
  /** Destroys the current user's session. */
  logout?: Maybe<Scalars['Void']>;
  /** Generates a new API Key for the requesting user */
  createAPIKey: APIKeyWithSecret;
  /** Deletes an existing API Key for the requesting user */
  deleteAPIKey: APIKey;
  /** Creates a new catalog which is owned by the requesting user */
  createCatalog: Catalog;
  /** Updates an existing catalog. */
  updateCatalog: Catalog;
  /** Updates the cover image of the catalog */
  setCatalogAvatarImage?: Maybe<Scalars['Void']>;
  /** Updates the cover image of the catalog */
  deleteCatalogAvatarImage?: Maybe<Scalars['Void']>;
  /** Updates the cover image of the catalog */
  setCatalogCoverImage?: Maybe<Scalars['Void']>;
  /** Updates the catalog permissions of a user */
  setUserCatalogPermission?: Maybe<Scalars['Void']>;
  /** Deletes and existing catalog. All packages in the catalog are then deleted */
  deleteCatalog?: Maybe<Scalars['Void']>;
  /** Creates a new package based on the information provided, and gives the requesting user all permissions to the package */
  createPackage: Package;
  /** Updates an existing package's descriptive information. See createVersion for how to update the package data definitions */
  updatePackage: Package;
  /** Moves a package to a different catalog */
  movePackage?: Maybe<Scalars['Void']>;
  /** Updates the cover image of the package */
  setPackageCoverImage?: Maybe<Scalars['Void']>;
  /** Deletes an existing package */
  deletePackage?: Maybe<Scalars['Void']>;
  /** Called by a client to indicate a package has been fetched */
  packageFetched?: Maybe<Scalars['Void']>;
  /** Creates a package issue */
  createPackageIssue?: Maybe<PackageIssue>;
  /** Updates a package issue */
  updatePackageIssue?: Maybe<PackageIssue>;
  /** Updates a package issue's status */
  updatePackageIssueStatus?: Maybe<PackageIssue>;
  /** Updates selected package issues' statuses */
  updatePackageIssuesStatuses?: Maybe<Scalars['Void']>;
  /** Updates selected package issues' statuses */
  deletePackageIssues?: Maybe<Scalars['Void']>;
  /** Delete a package issue */
  deletePackageIssue?: Maybe<Scalars['Void']>;
  /** Creates a package issue comment */
  createPackageIssueComment?: Maybe<PackageIssueComment>;
  /** Creates a package issue comment */
  updatePackageIssueComment?: Maybe<PackageIssueComment>;
  /** Deletes a package issue */
  deletePackageIssueComment?: Maybe<Scalars['Void']>;
  /** Creates a new collection, which is managed by the requesting user */
  createCollection: Collection;
  /** Updates an existing collection */
  updateCollection: Collection;
  /** Updates the cover image of the collection */
  setCollectionCoverImage?: Maybe<Scalars['Void']>;
  /** Deletes an existing collection. */
  deleteCollection?: Maybe<Scalars['Void']>;
  /** Includes an existing package in an existing collection */
  addPackageToCollection: CollectionPackage;
  /** Removes an existing package from a collection */
  removePackageFromCollection?: Maybe<Scalars['Void']>;
  /** Sets the permissions for a given user collection */
  setUserCollectionPermissions?: Maybe<Scalars['Void']>;
  /** Removes all permissions for a user on a given collection. Requires the manage collection permission */
  deleteUserCollectionPermissions?: Maybe<Scalars['Void']>;
  /** Removes all permissions for a user on a given catalog. Requires the manage catalog permission */
  deleteUserCatalogPermissions?: Maybe<Scalars['Void']>;
  /** Sets the permissions for a given set of users */
  setPackagePermissions?: Maybe<Scalars['Void']>;
  /** Removes all permissions for a user on a given package. Requires the manage package permission */
  removePackagePermissions?: Maybe<Scalars['Void']>;
  /**
   * Creates a new version of the package file on a package listing. There is no
   * update, as any update should also be published as a new version
   */
  createVersion: Version;
  /** Deletes a given version from a package. Use this sparingly, to allow for a viewable history of change for the package */
  deleteVersion?: Maybe<Scalars['Void']>;
  /** Given a token, validate a user's email address */
  verifyEmailAddress?: Maybe<Scalars['Void']>;
  /** Accept invite emails to finish creating user */
  acceptInvite?: Maybe<Scalars['Void']>;
  /** Follow changes for a catalog, package, collection, issue or user. */
  saveFollow?: Maybe<Scalars['Void']>;
  /** Delete a follow */
  deleteFollow?: Maybe<Scalars['Void']>;
  /** Delete all authenticated user's follows */
  deleteAllMyFollows?: Maybe<Scalars['Void']>;
  /** Create or update platform settings */
  savePlatformSettings: PlatformSettings;
  /** Run a named job. Key is a shared key. Job is the name of the job to run */
  runJob?: Maybe<Scalars['Void']>;
  /** Creates a repository used by the server to access a specific source/sink */
  createRepository: Repository;
  /** Set the credentials used by the server to access a specific source in a package */
  createCredential: Credential;
  /** Permanently deletes a repository matching the given parameters */
  deleteRepository?: Maybe<Scalars['Void']>;
  /** Permanently deletes a credential matching the given parameters */
  deleteCredential?: Maybe<Scalars['Void']>;
  createGroup: Group;
  updateGroup: Group;
  deleteGroup?: Maybe<Scalars['Void']>;
  addOrUpdateUserToGroup?: Maybe<Scalars['Void']>;
  removeUserFromGroup: Group;
  addOrUpdateGroupToCollection: GroupCollectionPermission;
  removeGroupFromCollection?: Maybe<Scalars['Void']>;
  addOrUpdateGroupToPackage: GroupPackagePermission;
  removeGroupFromPackage?: Maybe<Scalars['Void']>;
  addOrUpdateGroupToCatalog: GroupCatalogPermission;
  removeGroupFromCatalog?: Maybe<Scalars['Void']>;
};


export type MutationcreateMeArgs = {
  value: CreateUserInput;
};


export type MutationupdateMeArgs = {
  value: UpdateUserInput;
};


export type MutationsetAsAdminArgs = {
  username: Scalars['String'];
  isAdmin: Scalars['Boolean'];
};


export type MutationsetGroupAsAdminArgs = {
  groupSlug: Scalars['String'];
  isAdmin: Scalars['Boolean'];
};


export type MutationadminSetUserStatusArgs = {
  username: Scalars['String'];
  status: UserStatus;
  message?: Maybe<Scalars['String']>;
};


export type MutationupdateMyPasswordArgs = {
  value: UpdateMyPasswordInput;
};


export type MutationforgotMyPasswordArgs = {
  emailAddress: Scalars['String'];
};


export type MutationrecoverMyPasswordArgs = {
  value: RecoverMyPasswordInput;
};


export type MutationsetMyCoverImageArgs = {
  image: Base64ImageUpload;
};


export type MutationsetMyAvatarImageArgs = {
  image: Base64ImageUpload;
};


export type MutationadminDeleteUserArgs = {
  usernameOrEmailAddress: Scalars['String'];
};


export type MutationadminDeleteGroupArgs = {
  groupSlug: Scalars['String'];
};


export type MutationloginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type MutationcreateAPIKeyArgs = {
  value: CreateAPIKeyInput;
};


export type MutationdeleteAPIKeyArgs = {
  id: Scalars['String'];
};


export type MutationcreateCatalogArgs = {
  value: CreateCatalogInput;
};


export type MutationupdateCatalogArgs = {
  identifier: CatalogIdentifierInput;
  value: UpdateCatalogInput;
};


export type MutationsetCatalogAvatarImageArgs = {
  identifier: CatalogIdentifierInput;
  image: Base64ImageUpload;
};


export type MutationdeleteCatalogAvatarImageArgs = {
  identifier: CatalogIdentifierInput;
};


export type MutationsetCatalogCoverImageArgs = {
  identifier: CatalogIdentifierInput;
  image: Base64ImageUpload;
};


export type MutationsetUserCatalogPermissionArgs = {
  identifier: CatalogIdentifierInput;
  value: Array<SetUserCatalogPermissionInput>;
  message: Scalars['String'];
};


export type MutationdeleteCatalogArgs = {
  identifier: CatalogIdentifierInput;
};


export type MutationcreatePackageArgs = {
  value: CreatePackageInput;
};


export type MutationupdatePackageArgs = {
  identifier: PackageIdentifierInput;
  value: UpdatePackageInput;
};


export type MutationmovePackageArgs = {
  identifier: PackageIdentifierInput;
  catalogIdentifier: CatalogIdentifierInput;
};


export type MutationsetPackageCoverImageArgs = {
  identifier: PackageIdentifierInput;
  image: Base64ImageUpload;
};


export type MutationdeletePackageArgs = {
  identifier: PackageIdentifierInput;
};


export type MutationpackageFetchedArgs = {
  identifier: VersionIdentifierInput;
};


export type MutationcreatePackageIssueArgs = {
  packageIdentifier: PackageIdentifierInput;
  issue: CreatePackageIssueInput;
};


export type MutationupdatePackageIssueArgs = {
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  issue: UpdatePackageIssueInput;
};


export type MutationupdatePackageIssueStatusArgs = {
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  status: UpdatePackageIssueStatusInput;
};


export type MutationupdatePackageIssuesStatusesArgs = {
  packageIdentifier: PackageIdentifierInput;
  issuesIdentifiers: Array<PackageIssueIdentifierInput>;
  status: UpdatePackageIssueStatusInput;
};


export type MutationdeletePackageIssuesArgs = {
  packageIdentifier: PackageIdentifierInput;
  issuesIdentifiers: Array<PackageIssueIdentifierInput>;
};


export type MutationdeletePackageIssueArgs = {
  packageIdentifier: PackageIdentifierInput;
  packageIssueIdentifier: PackageIssueIdentifierInput;
};


export type MutationcreatePackageIssueCommentArgs = {
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  comment: CreatePackageIssueCommentInput;
};


export type MutationupdatePackageIssueCommentArgs = {
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  issueCommentIdentifier: PackageIssueCommentIdentifierInput;
  comment: UpdatePackageIssueCommentInput;
};


export type MutationdeletePackageIssueCommentArgs = {
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  issueCommentIdentifier: PackageIssueCommentIdentifierInput;
};


export type MutationcreateCollectionArgs = {
  value: CreateCollectionInput;
};


export type MutationupdateCollectionArgs = {
  identifier: CollectionIdentifierInput;
  value: UpdateCollectionInput;
};


export type MutationsetCollectionCoverImageArgs = {
  identifier: CollectionIdentifierInput;
  image: Base64ImageUpload;
};


export type MutationdeleteCollectionArgs = {
  identifier: CollectionIdentifierInput;
};


export type MutationaddPackageToCollectionArgs = {
  collectionIdentifier: CollectionIdentifierInput;
  packageIdentifier: PackageIdentifierInput;
};


export type MutationremovePackageFromCollectionArgs = {
  collectionIdentifier: CollectionIdentifierInput;
  packageIdentifier: PackageIdentifierInput;
};


export type MutationsetUserCollectionPermissionsArgs = {
  identifier: CollectionIdentifierInput;
  value: Array<SetUserCollectionPermissionsInput>;
  message: Scalars['String'];
};


export type MutationdeleteUserCollectionPermissionsArgs = {
  identifier: CollectionIdentifierInput;
  usernameOrEmailAddress: Scalars['String'];
};


export type MutationdeleteUserCatalogPermissionsArgs = {
  identifier: CatalogIdentifierInput;
  usernameOrEmailAddress: Scalars['String'];
};


export type MutationsetPackagePermissionsArgs = {
  identifier: PackageIdentifierInput;
  value: Array<SetPackagePermissionInput>;
  message: Scalars['String'];
};


export type MutationremovePackagePermissionsArgs = {
  identifier: PackageIdentifierInput;
  usernameOrEmailAddress: Scalars['String'];
};


export type MutationcreateVersionArgs = {
  identifier: PackageIdentifierInput;
  value: CreateVersionInput;
};


export type MutationdeleteVersionArgs = {
  identifier: VersionIdentifierInput;
};


export type MutationverifyEmailAddressArgs = {
  token: Scalars['String'];
};


export type MutationacceptInviteArgs = {
  token: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};


export type MutationsaveFollowArgs = {
  follow: SaveFollowInput;
};


export type MutationdeleteFollowArgs = {
  follow: FollowIdentifierInput;
};


export type MutationsavePlatformSettingsArgs = {
  settings: PlatformSettingsInput;
};


export type MutationrunJobArgs = {
  key: Scalars['String'];
  job: JobType;
};


export type MutationcreateRepositoryArgs = {
  identifier: PackageIdentifierInput;
  repositoryIdentifier: Scalars['String'];
  connectorType: Scalars['String'];
  connectionConfiguration: Scalars['JSON'];
};


export type MutationcreateCredentialArgs = {
  identifier: PackageIdentifierInput;
  repositoryIdentifier: Scalars['String'];
  connectorType: Scalars['String'];
  credentialIdentifier: Scalars['String'];
  credential: Scalars['JSON'];
};


export type MutationdeleteRepositoryArgs = {
  identifier: PackageIdentifierInput;
  repositoryIdentifier: Scalars['String'];
  connectorType: Scalars['String'];
};


export type MutationdeleteCredentialArgs = {
  identifier: PackageIdentifierInput;
  repositoryIdentifier: Scalars['String'];
  connectorType: Scalars['String'];
  credentialIdentifier: Scalars['String'];
};


export type MutationcreateGroupArgs = {
  groupSlug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};


export type MutationupdateGroupArgs = {
  groupSlug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};


export type MutationdeleteGroupArgs = {
  groupSlug: Scalars['String'];
};


export type MutationaddOrUpdateUserToGroupArgs = {
  groupSlug: Scalars['String'];
  userPermissions: Array<SetUserGroupPermissionsInput>;
};


export type MutationremoveUserFromGroupArgs = {
  groupSlug: Scalars['String'];
  username: Scalars['String'];
};


export type MutationaddOrUpdateGroupToCollectionArgs = {
  groupSlug: Scalars['String'];
  collectionIdentifier: CollectionIdentifierInput;
  permissions: Array<Permission>;
};


export type MutationremoveGroupFromCollectionArgs = {
  groupSlug: Scalars['String'];
  collectionIdentifier: CollectionIdentifierInput;
};


export type MutationaddOrUpdateGroupToPackageArgs = {
  groupSlug: Scalars['String'];
  packageIdentifier: PackageIdentifierInput;
  permissions: Array<Permission>;
};


export type MutationremoveGroupFromPackageArgs = {
  groupSlug: Scalars['String'];
  packageIdentifier: PackageIdentifierInput;
};


export type MutationaddOrUpdateGroupToCatalogArgs = {
  groupSlug: Scalars['String'];
  catalogIdentifier: CatalogIdentifierInput;
  permissions: Array<Permission>;
  packagePermissions: Array<Permission>;
};


export type MutationremoveGroupFromCatalogArgs = {
  groupSlug: Scalars['String'];
  catalogIdentifier: CatalogIdentifierInput;
};

/** My Follows result */
export type MyFollowsResult = {
  __typename?: 'MyFollowsResult';
  follows?: Maybe<Array<Follow>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** Notification Event type */
export enum NotificationEventType {
  PACKAGE_CREATED = 'PACKAGE_CREATED',
  VERSION_CREATED = 'VERSION_CREATED',
  PACKAGE_PATCH_CHANGE = 'PACKAGE_PATCH_CHANGE',
  PACKAGE_MINOR_CHANGE = 'PACKAGE_MINOR_CHANGE',
  PACKAGE_MAJOR_CHANGE = 'PACKAGE_MAJOR_CHANGE',
  PACKAGE_EDIT = 'PACKAGE_EDIT',
  COLLECTION_PACKAGE_ADDED = 'COLLECTION_PACKAGE_ADDED',
  COLLECTION_PACKAGE_REMOVED = 'COLLECTION_PACKAGE_REMOVED',
  CATALOG_PACKAGE_ADDED = 'CATALOG_PACKAGE_ADDED',
  CATALOG_PACKAGE_REMOVED = 'CATALOG_PACKAGE_REMOVED',
  PACKAGE_ISSUE_COMMENT_CREATED = 'PACKAGE_ISSUE_COMMENT_CREATED',
  PACKAGE_ISSUE_STATUS_CHANGE = 'PACKAGE_ISSUE_STATUS_CHANGE'
}

/** Notification frequency */
export enum NotificationFrequency {
  INSTANT = 'INSTANT',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  NEVER = 'NEVER'
}

/** Order by options */
export enum OrderBy {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

/**
 *  Represents a single offering on the registry. Packages contain one or more
 * versions of a Package File as it is updated over time. A package may contain one or more
 * data data sets, and is a member of one and only one catalog. A package may be included in zero or more collections.
 */
export type Package = {
  __typename?: 'Package';
  creator?: Maybe<User>;
  identifier: PackageIdentifier;
  catalog?: Maybe<Catalog>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  latestVersion?: Maybe<Version>;
  versions?: Maybe<Array<Maybe<Version>>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  myPermissions?: Maybe<Array<Permission>>;
  fetchedCount?: Maybe<Scalars['Int']>;
  viewedCount?: Maybe<Scalars['Int']>;
  updateMethods?: Maybe<Array<UpdateMethod>>;
};

/** The basic data used to display a collection */
export type PackageCollectionsResult = {
  __typename?: 'PackageCollectionsResult';
  collections?: Maybe<Array<Collection>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** The package difference type */
export type PackageDifference = {
  __typename?: 'PackageDifference';
  type?: Maybe<PackageDifferenceType>;
  pointer?: Maybe<Scalars['String']>;
};

/** The package differences response */
export type PackageDifferences = {
  __typename?: 'PackageDifferences';
  newVersion?: Maybe<VersionIdentifierValues>;
  oldVersion?: Maybe<VersionIdentifierValues>;
  differences?: Maybe<Array<Maybe<PackageDifference>>>;
};

/** Package difference types */
export enum PackageDifferenceType {
  REMOVE_SCHEMA = 'REMOVE_SCHEMA',
  REMOVE_HIDDEN_SCHEMA = 'REMOVE_HIDDEN_SCHEMA',
  ADD_SCHEMA = 'ADD_SCHEMA',
  REMOVE_SOURCE = 'REMOVE_SOURCE',
  CHANGE_PACKAGE_DISPLAY_NAME = 'CHANGE_PACKAGE_DISPLAY_NAME',
  CHANGE_PACKAGE_DESCRIPTION = 'CHANGE_PACKAGE_DESCRIPTION',
  CHANGE_SOURCE = 'CHANGE_SOURCE',
  CHANGE_SOURCE_CONFIGURATION = 'CHANGE_SOURCE_CONFIGURATION',
  CHANGE_SOURCE_CONNECTION = 'CHANGE_SOURCE_CONNECTION',
  CHANGE_SOURCE_CREDENTIALS = 'CHANGE_SOURCE_CREDENTIALS',
  CHANGE_SOURCE_URIS = 'CHANGE_SOURCE_URIS',
  CHANGE_STREAM_STATS = 'CHANGE_STREAM_STATS',
  CHANGE_STREAM_UPDATE_HASH = 'CHANGE_STREAM_UPDATE_HASH',
  CHANGE_STREAM_UPDATE_METHOD = 'CHANGE_STREAM_UPDATE_METHOD',
  ADD_PROPERTY = 'ADD_PROPERTY',
  HIDE_PROPERTY = 'HIDE_PROPERTY',
  UNHIDE_PROPERTY = 'UNHIDE_PROPERTY',
  REMOVE_PROPERTY = 'REMOVE_PROPERTY',
  REMOVE_HIDDEN_PROPERTY = 'REMOVE_HIDDEN_PROPERTY',
  CHANGE_PROPERTY_TYPE = 'CHANGE_PROPERTY_TYPE',
  CHANGE_PROPERTY_FORMAT = 'CHANGE_PROPERTY_FORMAT',
  CHANGE_PROPERTY_DESCRIPTION = 'CHANGE_PROPERTY_DESCRIPTION',
  CHANGE_PROPERTY_UNIT = 'CHANGE_PROPERTY_UNIT',
  CHANGE_GENERATED_BY = 'CHANGE_GENERATED_BY',
  CHANGE_UPDATED_DATE = 'CHANGE_UPDATED_DATE',
  CHANGE_VERSION = 'CHANGE_VERSION',
  CHANGE_README_MARKDOWN = 'CHANGE_README_MARKDOWN',
  CHANGE_LICENSE_MARKDOWN = 'CHANGE_LICENSE_MARKDOWN',
  CHANGE_README_FILE = 'CHANGE_README_FILE',
  CHANGE_LICENSE_FILE = 'CHANGE_LICENSE_FILE',
  CHANGE_WEBSITE = 'CHANGE_WEBSITE',
  CHANGE_CONTACT_EMAIL = 'CHANGE_CONTACT_EMAIL',
  REMOVE_STREAM_SET = 'REMOVE_STREAM_SET'
}


/** The complete identifier for a package on a registry */
export type PackageIdentifier = {
  __typename?: 'PackageIdentifier';
  registryURL: Scalars['String'];
  catalogSlug: Scalars['String'];
  packageSlug: Scalars['String'];
};

/** The complete identifier for a package, assuming the registry is based on the HTTP request URL */
export type PackageIdentifierInput = {
  catalogSlug: Scalars['String'];
  packageSlug: Scalars['String'];
};

/** A package issue represents an issue raised for a topic related to the package. */
export type PackageIssue = {
  __typename?: 'PackageIssue';
  issueNumber: Scalars['Int'];
  subject: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  author?: Maybe<User>;
  packageIdentifier?: Maybe<BasePackageIdentifier>;
  status?: Maybe<PackageIssueStatus>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PackageIssueComment = {
  __typename?: 'PackageIssueComment';
  commentNumber: Scalars['Int'];
  content: Scalars['String'];
  author: User;
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
};

/** The complete identifier for a package issue comment */
export type PackageIssueCommentIdentifierInput = {
  commentNumber: Scalars['Int'];
};

/** The response for a package issue comments request */
export type PackageIssueCommentsResult = {
  __typename?: 'PackageIssueCommentsResult';
  comments?: Maybe<Array<PackageIssueComment>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** The complete identifier for a package issue */
export type PackageIssueFullIdentifierInput = {
  packageIdentifier: PackageIdentifierInput;
  issueNumber: Scalars['Int'];
};

/** The complete identifier for a package issue */
export type PackageIssueIdentifierInput = {
  issueNumber: Scalars['Int'];
};

/** The response for a package issues request */
export type PackageIssuesResult = {
  __typename?: 'PackageIssuesResult';
  issues?: Maybe<Array<PackageIssue>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
  openIssuesCount: Scalars['Int'];
  closedIssuesCount: Scalars['Int'];
};

/** The package issue status */
export enum PackageIssueStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}


/** The response for packages list request */
export type PackagesResult = {
  __typename?: 'PackagesResult';
  packages?: Maybe<Array<Package>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** Root page content */
export type PageContent = {
  __typename?: 'PageContent';
  builderIOPage?: Maybe<BuilderIOPage>;
  catalog?: Maybe<Catalog>;
  user?: Maybe<User>;
};


/** General permission values, which are applied to users for catalogs, package, and other access */
export enum Permission {
  MANAGE = 'MANAGE',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  NONE = 'NONE'
}

/** Platform settings type */
export type PlatformSettings = {
  __typename?: 'PlatformSettings';
  key: Scalars['String'];
  serializedSettings: Scalars['String'];
  isPublic: Scalars['Boolean'];
};

/** Platform settings type */
export type PlatformSettingsInput = {
  key: Scalars['String'];
  serializedSettings: Scalars['String'];
  isPublic: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  /** Whether the specified username is available for sign up */
  usernameAvailable: Scalars['Boolean'];
  /** Whether the specified email address is avialable for sign up */
  emailAddressAvailable: Scalars['Boolean'];
  /** Who am i */
  me: CurrentUser;
  /** A list of the requesting user's exisiting API key information */
  myAPIKeys?: Maybe<Array<APIKey>>;
  /** Returns all of the users for a given catalog */
  usersByPackage: Array<UserPackagePermissions>;
  /** Returns all of the users for a given catalog */
  usersByCatalog: Array<UserCatalogPermissions>;
  /** Returns all of the users for a given collection */
  usersByCollection: Array<UserCollectionPermissions>;
  /** Returns all of the users for a given catalog */
  groupsByPackage: Array<GroupPackagePermission>;
  /** Returns all of the users for a given catalog */
  groupsByCatalog: Array<GroupCatalogPermission>;
  /** Returns all of the users for a given collection */
  groupsByCollection: Array<GroupCollectionPermission>;
  /** Return a user by a given username, and returns only the properties on that user the requester should be able to view */
  user: User;
  /** Returns package and collections with slugs that start with the given parameter */
  autoComplete: AutoCompleteResult;
  /** For testing whether a given catalog slug is available to be reserved */
  catalogSlugAvailable: Scalars['Boolean'];
  /** Returns the catalogs the requesting user either owns or has edit or manage permission to */
  myCatalogs: Array<Maybe<Catalog>>;
  /** Returns all groups the user can view */
  myGroups: Array<Group>;
  /** Returns a single group */
  group: Group;
  /** Returns catalogs matching the query string (which can include boolean logic combinations) with pagination */
  searchCatalogs: SearchCatalogsResult;
  /** Returns the catalog of the given identifier. Requires permission to view the catalog */
  catalog: Catalog;
  /** Returns the packages of the given identifier. Requires permission to view the catalog packages */
  catalogPackages: Array<Package>;
  /** Returns the package for the given identifier. Requires permission to view the package */
  package: Package;
  /** Package versions diff */
  packageVersionsDiff: PackageDifferences;
  /** Package versions diffs */
  packageVersionsDiffs?: Maybe<Array<PackageDifferences>>;
  /** Package related collections */
  packageCollections?: Maybe<PackageCollectionsResult>;
  /** Get package issue */
  packageIssue?: Maybe<PackageIssue>;
  /** Get package issues */
  packageIssues?: Maybe<PackageIssuesResult>;
  /** Get package issue comments */
  packageIssueComments?: Maybe<PackageIssueCommentsResult>;
  /** Returns list of the requesting users packages */
  myPackages: PackagesResult;
  /** Returns packages that match the given search query (which can contain boolean search logic). This request is paginated. */
  searchPackages: SearchPackagesResult;
  /** Returns a list of packages the requesting user has recently viewed */
  myRecentlyViewedPackages: ActivityLogResult;
  /** Returns users that match the given search query */
  searchUsers: SearchUsersResult;
  /** Returns users that match the given search query with no restrictions */
  adminSearchUsers: SearchUsersResult;
  /** Returns users that match the given search query with no restrictions */
  adminSearchGroups: SearchGroupsResult;
  /** Returns the latest packages */
  latestPackages: LatestPackagesResult;
  /** Whether the specified colleciton slug is available */
  collectionSlugAvailable: Scalars['Boolean'];
  /** Returns the collection specified only if the user has permission to view it */
  collection: Collection;
  /** Returns all of the collections the user has permission to view */
  collections?: Maybe<Array<Collection>>;
  /** Returns list of authenicated users collections */
  myCollections: CollectionsResult;
  /** Returns list of collections for the given user */
  userCollections: CollectionsResult;
  /** Returns list of catalogs for the given user */
  userCatalogs: CatalogsResult;
  /** Returns list of packages for the given user */
  userPackages: PackagesResult;
  /** Returns collections whose names match the given query. This request is paginated */
  searchCollections: SearchCollectionResult;
  /** Returns the latest collections. This request is paginated */
  latestCollections: CollectionsResult;
  /** Returns a list of collections the requesting user has recently viewed */
  myRecentlyViewedCollections: ActivityLogResult;
  /** Returns collections equal to collectionSlug, gives all packages associated with collectionSlug */
  collectionPackages: Array<Package>;
  /** Returns activity for the requesting user */
  myActivity: ActivityLogResult;
  /** Activity for a given collection */
  collectionActivities: ActivityLogResult;
  /** Activity for a given package */
  packageActivities: ActivityLogResult;
  /** Activity for a given catalog */
  catalogActivities: ActivityLogResult;
  /** Follow object for a target entity */
  getFollow?: Maybe<Follow>;
  /** For validating that the server is up, and requesting it's status */
  registryStatus: RegistryStatusResult;
  /** Get authenticated user's follows */
  myFollows: MyFollowsResult;
  /** Get platform settings */
  platformSettings?: Maybe<Array<PlatformSettings>>;
  /** Get platform settings */
  publicPlatformSettingsByKey: PlatformSettings;
  /** Get root page content */
  pageContent: PageContent;
  /** Get following entities activities */
  myFollowingActivity?: Maybe<FollowingActivity>;
  /** Get package followers */
  packageFollowers: FollowersResult;
  /** Get package issue followers */
  packageIssueFollowers: FollowersResult;
  /** Get catalog followers */
  catalogFollowers: FollowersResult;
  /** Get collection followers */
  collectionFollowers: FollowersResult;
  /** Get user followers */
  userFollowers: FollowersResult;
  /** Get package followers count */
  packageFollowersCount: Scalars['Int'];
  /** Get package issue followers count */
  packageIssueFollowersCount: Scalars['Int'];
  /** Get catalog followers count */
  catalogFollowersCount: Scalars['Int'];
  /** Get collection followers count */
  collectionFollowersCount: Scalars['Int'];
  /** Get user followers count */
  userFollowersCount: Scalars['Int'];
  /** Lists the credential identifiers, but not the credentials themselves */
  listRepositories: RepositoriesResult;
  /** Lists the connectors available to use via the web server */
  listConnectors: Array<ConnectorDescription>;
};


export type QueryusernameAvailableArgs = {
  username: Scalars['String'];
};


export type QueryemailAddressAvailableArgs = {
  emailAddress: Scalars['String'];
};


export type QueryusersByPackageArgs = {
  identifier: PackageIdentifierInput;
};


export type QueryusersByCatalogArgs = {
  identifier: CatalogIdentifierInput;
};


export type QueryusersByCollectionArgs = {
  identifier: CollectionIdentifierInput;
};


export type QuerygroupsByPackageArgs = {
  packageIdentifier: PackageIdentifierInput;
};


export type QuerygroupsByCatalogArgs = {
  catalogIdentifier: CatalogIdentifierInput;
};


export type QuerygroupsByCollectionArgs = {
  collectionIdentifier: CollectionIdentifierInput;
};


export type QueryuserArgs = {
  username: Scalars['String'];
};


export type QueryautoCompleteArgs = {
  startsWith: Scalars['String'];
};


export type QuerycatalogSlugAvailableArgs = {
  catalogSlug: Scalars['String'];
};


export type QuerygroupArgs = {
  groupSlug: Scalars['String'];
};


export type QuerysearchCatalogsArgs = {
  query: Scalars['String'];
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerycatalogArgs = {
  identifier: CatalogIdentifierInput;
};


export type QuerycatalogPackagesArgs = {
  identifier: CatalogIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerypackageArgs = {
  identifier: PackageIdentifierInput;
};


export type QuerypackageVersionsDiffArgs = {
  newVersion: VersionIdentifierInput;
  oldVersion: VersionIdentifierInput;
};


export type QuerypackageVersionsDiffsArgs = {
  packageIdentifier: PackageIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerypackageCollectionsArgs = {
  packageIdentifier: PackageIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerypackageIssueArgs = {
  packageIdentifier: PackageIdentifierInput;
  packageIssueIdentifier: PackageIssueIdentifierInput;
};


export type QuerypackageIssuesArgs = {
  packageIdentifier: PackageIdentifierInput;
  includeOpenIssues: Scalars['Boolean'];
  includeClosedIssues: Scalars['Boolean'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orderBy: OrderBy;
};


export type QuerypackageIssueCommentsArgs = {
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orderBy: OrderBy;
};


export type QuerymyPackagesArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerysearchPackagesArgs = {
  query: Scalars['String'];
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerymyRecentlyViewedPackagesArgs = {
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerysearchUsersArgs = {
  value: Scalars['String'];
  limit: Scalars['Int'];
  offSet: Scalars['Int'];
};


export type QueryadminSearchUsersArgs = {
  value: Scalars['String'];
  limit: Scalars['Int'];
  offSet: Scalars['Int'];
};


export type QueryadminSearchGroupsArgs = {
  value: Scalars['String'];
  limit: Scalars['Int'];
  offSet: Scalars['Int'];
};


export type QuerylatestPackagesArgs = {
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerycollectionSlugAvailableArgs = {
  collectionSlug: Scalars['String'];
};


export type QuerycollectionArgs = {
  identifier: CollectionIdentifierInput;
};


export type QuerymyCollectionsArgs = {
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryuserCollectionsArgs = {
  username: Scalars['String'];
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryuserCatalogsArgs = {
  username: Scalars['String'];
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryuserPackagesArgs = {
  username: Scalars['String'];
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerysearchCollectionsArgs = {
  query: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerylatestCollectionsArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerymyRecentlyViewedCollectionsArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerycollectionPackagesArgs = {
  identifier: CollectionIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerymyActivityArgs = {
  filter: ActivityLogFilterInput;
};


export type QuerycollectionActivitiesArgs = {
  identifier: CollectionIdentifierInput;
  filter: ActivityLogFilterInput;
};


export type QuerypackageActivitiesArgs = {
  identifier: PackageIdentifierInput;
  filter: ActivityLogFilterInput;
};


export type QuerycatalogActivitiesArgs = {
  identifier: CatalogIdentifierInput;
  filter: ActivityLogFilterInput;
};


export type QuerygetFollowArgs = {
  follow: FollowIdentifierInput;
};


export type QuerymyFollowsArgs = {
  type: FollowType;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerypublicPlatformSettingsByKeyArgs = {
  key: Scalars['String'];
};


export type QuerypageContentArgs = {
  route: Scalars['String'];
};


export type QuerymyFollowingActivityArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerypackageFollowersArgs = {
  identifier: PackageIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerypackageIssueFollowersArgs = {
  identifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerycatalogFollowersArgs = {
  identifier: CatalogIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerycollectionFollowersArgs = {
  identifier: CollectionIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryuserFollowersArgs = {
  username: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QuerypackageFollowersCountArgs = {
  identifier: PackageIdentifierInput;
};


export type QuerypackageIssueFollowersCountArgs = {
  identifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
};


export type QuerycatalogFollowersCountArgs = {
  identifier: CatalogIdentifierInput;
};


export type QuerycollectionFollowersCountArgs = {
  identifier: CollectionIdentifierInput;
};


export type QueryuserFollowersCountArgs = {
  username: Scalars['String'];
};


export type QuerylistRepositoriesArgs = {
  identifier: PackageIdentifierInput;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

/** For sending a token response to recover and update users password */
export type RecoverMyPasswordInput = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

/** Server status states */
export enum RegistryStatus {
  SERVING_REQUESTS = 'SERVING_REQUESTS'
}

export type RegistryStatusResult = {
  __typename?: 'RegistryStatusResult';
  status: RegistryStatus;
  version: Scalars['String'];
  registryUrl: Scalars['String'];
};

export type RepositoriesResult = {
  __typename?: 'RepositoriesResult';
  repositories?: Maybe<Array<Repository>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

export type Repository = {
  __typename?: 'Repository';
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  package?: Maybe<Package>;
  credentials?: Maybe<Array<Credential>>;
  creator?: Maybe<User>;
  connectorType?: Maybe<Scalars['String']>;
  repositoryIdentifier?: Maybe<Scalars['String']>;
};

/** The save follow identifier input */
export type SaveFollowInput = {
  notificationFrequency: NotificationFrequency;
  catalog?: Maybe<CatalogIdentifierInput>;
  package?: Maybe<PackageIdentifierInput>;
  collection?: Maybe<CollectionIdentifierInput>;
  packageIssue?: Maybe<PackageIssueFullIdentifierInput>;
  user?: Maybe<UserIdentifierInput>;
  followAllPackages?: Maybe<Scalars['Boolean']>;
  followAllPackageIssues?: Maybe<Scalars['Boolean']>;
  changeType?: Maybe<Array<Maybe<ActivityLogChangeType>>>;
};

/** Scopes for an API Key */
export enum Scope {
  MANAGE_API_KEYS = 'MANAGE_API_KEYS',
  READ_PRIVATE_ASSETS = 'READ_PRIVATE_ASSETS',
  MANAGE_PRIVATE_ASSETS = 'MANAGE_PRIVATE_ASSETS'
}

/** The response for a catalog search request */
export type SearchCatalogsResult = {
  __typename?: 'SearchCatalogsResult';
  catalogs?: Maybe<Array<Catalog>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** " The response for a collection search request */
export type SearchCollectionResult = {
  __typename?: 'SearchCollectionResult';
  collections?: Maybe<Array<Collection>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** The response for searching users */
export type SearchGroupsResult = {
  __typename?: 'SearchGroupsResult';
  groups: Array<Maybe<Group>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** The response for a package search request */
export type SearchPackagesResult = {
  __typename?: 'SearchPackagesResult';
  packages?: Maybe<Array<Package>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** The response for searching users */
export type SearchUsersResult = {
  __typename?: 'SearchUsersResult';
  users: Array<Maybe<User>>;
  hasMore: Scalars['Boolean'];
  count: Scalars['Int'];
};

/** The properties for updating a specific user's permissions on a package */
export type SetPackagePermissionInput = {
  usernameOrEmailAddress: Scalars['String'];
  permissions: Array<Permission>;
};

export type SetUserCatalogPermissionInput = {
  usernameOrEmailAddress: Scalars['String'];
  /** The users permissions for the catalog itself */
  permission: Array<Permission>;
  /** The users permissions for all packages in the catalog */
  packagePermissions: Array<Permission>;
};

/** The properties for setting a specific user's permissions on a collection */
export type SetUserCollectionPermissionsInput = {
  usernameOrEmailAddress: Scalars['String'];
  permissions: Array<Permission>;
};

/** The properties for setting a specific user's permissions in a group */
export type SetUserGroupPermissionsInput = {
  usernameOrEmailAddress: Scalars['String'];
  permissions: Array<Permission>;
};

/** The properties of a catalog that can be updated. All are optional. Only the ones specified are applied. */
export type UpdateCatalogInput = {
  newSlug?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  unclaimed?: Maybe<Scalars['Boolean']>;
};

/** For updating an existing collection. All properties are optional, and only the ones specified are applied */
export type UpdateCollectionInput = {
  newCollectionSlug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export enum UpdateMethod {
  CONTINUOUS = 'CONTINUOUS',
  BATCH_FULL_SET = 'BATCH_FULL_SET',
  APPEND_ONLY_LOG = 'APPEND_ONLY_LOG'
}

/** For updating a user's password */
export type UpdateMyPasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

/** The properties for updating an existing package. All are optional. Only the ones specified are applied. */
export type UpdatePackageInput = {
  newCatalogSlug?: Maybe<Scalars['String']>;
  newPackageSlug?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

/** The input to update a package issue comment */
export type UpdatePackageIssueCommentInput = {
  content: Scalars['String'];
};

/** The package issue update input */
export type UpdatePackageIssueInput = {
  subject: Scalars['String'];
  content: Scalars['String'];
};

/** The package issue update input */
export type UpdatePackageIssueStatusInput = {
  status: PackageIssueStatus;
};

/** For updating a user, or your own user. All values are optional, and only those specified are applied. */
export type UpdateUserInput = {
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  twitterHandle?: Maybe<Scalars['String']>;
  gitHubHandle?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  websiteIsPublic?: Maybe<Scalars['Boolean']>;
  nameIsPublic?: Maybe<Scalars['Boolean']>;
  locationIsPublic?: Maybe<Scalars['Boolean']>;
  twitterHandleIsPublic?: Maybe<Scalars['Boolean']>;
  gitHubHandleIsPublic?: Maybe<Scalars['Boolean']>;
  emailAddressIsPublic?: Maybe<Scalars['Boolean']>;
  uiDarkModeEnabled?: Maybe<Scalars['Boolean']>;
};

/** Represents one real world person, and their information */
export type User = {
  __typename?: 'User';
  username: Scalars['String'];
  displayName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  twitterHandle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  gitHubHandle?: Maybe<Scalars['String']>;
  nameIsPublic?: Maybe<Scalars['Boolean']>;
  locationIsPublic?: Maybe<Scalars['Boolean']>;
  twitterHandleIsPublic?: Maybe<Scalars['Boolean']>;
  gitHubHandleIsPublic?: Maybe<Scalars['Boolean']>;
  emailAddressIsPublic?: Maybe<Scalars['Boolean']>;
  websiteIsPublic?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  status?: Maybe<UserStatus>;
  uiDarkModeEnabled?: Maybe<Scalars['Boolean']>;
};

/** Represents users, and their permissions on a catalog */
export type UserCatalogPermissions = {
  __typename?: 'UserCatalogPermissions';
  user: User;
  permissions: Array<Permission>;
  packagePermissions: Array<Permission>;
};

/** Represents users, and their permissions on a collection */
export type UserCollectionPermissions = {
  __typename?: 'UserCollectionPermissions';
  user: User;
  permissions: Array<Permission>;
};

export type UserIdentifierInput = {
  username: Scalars['String'];
};



/** Represents a user, and their permissions on a package */
export type UserPackagePermissions = {
  __typename?: 'UserPackagePermissions';
  user?: Maybe<User>;
  permissions: Array<Permission>;
};

export enum UserStatus {
  PENDING_SIGN_UP = 'PENDING_SIGN_UP',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED'
}


/** A single version of a package file for a package listing on the registry */
export type Version = {
  __typename?: 'Version';
  identifier: VersionIdentifier;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  package?: Maybe<Package>;
  /** The user that created this version */
  author?: Maybe<User>;
  /**
   * A package file that may be modified by the server to remove sensitive information. See the PackageFile.canonical value
   * to determine whether it has been modified.
   */
  packageFile?: Maybe<Scalars['PackageFileJSON']>;
  /** An unmodified copy of the package file that is only returned when the requesting user has EDIT permissions on the package. */
  canonicalPackageFile?: Maybe<Scalars['PackageFileJSON']>;
  updateMethods?: Maybe<Array<UpdateMethod>>;
};

/** Potential responses when creating a new version on a package */
export enum VersionConflict {
  VERSION_EXISTS = 'VERSION_EXISTS',
  HIGHER_VERSION_EXISTS = 'HIGHER_VERSION_EXISTS',
  HIGHER_VERSION_REQUIRED = 'HIGHER_VERSION_REQUIRED'
}

/** The complete identifier for a version of a package on a registry */
export type VersionIdentifier = {
  __typename?: 'VersionIdentifier';
  registryURL?: Maybe<Scalars['String']>;
  catalogSlug: Scalars['String'];
  packageSlug: Scalars['String'];
  versionMajor: Scalars['Int'];
  versionMinor: Scalars['Int'];
  versionPatch: Scalars['Int'];
};

/** The complete identifier for a version of a package, assuming the registry is based on the HTTP request URL */
export type VersionIdentifierInput = {
  catalogSlug: Scalars['String'];
  packageSlug: Scalars['String'];
  versionMajor: Scalars['Int'];
  versionMinor: Scalars['Int'];
  versionPatch: Scalars['Int'];
};

/** The version values */
export type VersionIdentifierValues = {
  __typename?: 'VersionIdentifierValues';
  versionMajor: Scalars['Int'];
  versionMinor: Scalars['Int'];
  versionPatch: Scalars['Int'];
};


export type AcceptInviteMutationVariables = Exact<{
  token: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type AcceptInviteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'acceptInvite'>
);

export type AddOrUpdateGroupToCatalogMutationVariables = Exact<{
  groupSlug: Scalars['String'];
  catalogIdentifier: CatalogIdentifierInput;
  permissions: Array<Permission>;
  packagePermissions: Array<Permission>;
}>;


export type AddOrUpdateGroupToCatalogMutation = (
  { __typename?: 'Mutation' }
  & { addOrUpdateGroupToCatalog: (
    { __typename?: 'GroupCatalogPermission' }
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'slug' | 'name'>
    )>, catalog?: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'displayName' | 'description' | 'website' | 'myPermissions' | 'isPublic' | 'unclaimed'>
      & { identifier: (
        { __typename?: 'CatalogIdentifier' }
        & Pick<CatalogIdentifier, 'registryURL' | 'catalogSlug'>
      ) }
    )> }
  ) }
);

export type AddOrUpdateGroupToCollectionMutationVariables = Exact<{
  groupSlug: Scalars['String'];
  collectionIdentifier: CollectionIdentifierInput;
  permissions: Array<Permission>;
}>;


export type AddOrUpdateGroupToCollectionMutation = (
  { __typename?: 'Mutation' }
  & { addOrUpdateGroupToCollection: (
    { __typename?: 'GroupCollectionPermission' }
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'slug' | 'name'>
    )>, collection?: Maybe<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'name' | 'description' | 'myPermissions' | 'isPublic'>
      & { identifier: (
        { __typename?: 'CollectionIdentifier' }
        & Pick<CollectionIdentifier, 'registryURL' | 'collectionSlug'>
      ) }
    )> }
  ) }
);

export type AddOrUpdateGroupToPackageMutationVariables = Exact<{
  groupSlug: Scalars['String'];
  packageIdentifier: PackageIdentifierInput;
  permissions: Array<Permission>;
}>;


export type AddOrUpdateGroupToPackageMutation = (
  { __typename?: 'Mutation' }
  & { addOrUpdateGroupToPackage: (
    { __typename?: 'GroupPackagePermission' }
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'slug' | 'name'>
    )> }
  ) }
);

export type AddOrUpdateUserToGroupMutationVariables = Exact<{
  groupSlug: Scalars['String'];
  userPermissions: Array<SetUserGroupPermissionsInput>;
}>;


export type AddOrUpdateUserToGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addOrUpdateUserToGroup'>
);

export type AddPackageToCollectionMutationVariables = Exact<{
  collectionIdentifier: CollectionIdentifierInput;
  packageIdentifier: PackageIdentifierInput;
}>;


export type AddPackageToCollectionMutation = (
  { __typename?: 'Mutation' }
  & { addPackageToCollection: (
    { __typename?: 'CollectionPackage' }
    & { collection: (
      { __typename?: 'Collection' }
      & Pick<Collection, 'name' | 'description'>
      & { identifier: (
        { __typename?: 'CollectionIdentifier' }
        & Pick<CollectionIdentifier, 'collectionSlug'>
      ) }
    ), package: (
      { __typename?: 'Package' }
      & Pick<Package, 'displayName' | 'description'>
      & { identifier: (
        { __typename?: 'PackageIdentifier' }
        & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
      ), latestVersion?: Maybe<(
        { __typename?: 'Version' }
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )> }
    ) }
  ) }
);

export type AdminDeleteGroupMutationVariables = Exact<{
  groupSlug: Scalars['String'];
}>;


export type AdminDeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeleteGroup'>
);

export type AdminDeleteUserMutationVariables = Exact<{
  usernameOrEmailAddress: Scalars['String'];
}>;


export type AdminDeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeleteUser'>
);

export type AdminSearchGroupsQueryVariables = Exact<{
  value: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type AdminSearchGroupsQuery = (
  { __typename?: 'Query' }
  & { adminSearchGroups: (
    { __typename?: 'SearchGroupsResult' }
    & Pick<SearchGroupsResult, 'hasMore' | 'count'>
    & { groups: Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'slug' | 'name' | 'description' | 'isAdmin' | 'createdAt' | 'updatedAt'>
      & { creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username' | 'firstName' | 'lastName' | 'emailAddress' | 'displayName' | 'status' | 'isAdmin'>
      )> }
    )>> }
  ) }
);

export type AdminSearchUsersQueryVariables = Exact<{
  value: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type AdminSearchUsersQuery = (
  { __typename?: 'Query' }
  & { adminSearchUsers: (
    { __typename?: 'SearchUsersResult' }
    & Pick<SearchUsersResult, 'hasMore' | 'count'>
    & { users: Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'emailAddress' | 'displayName' | 'status' | 'isAdmin'>
    )>> }
  ) }
);

export type AdminSetUserStatusMutationVariables = Exact<{
  username: Scalars['String'];
  status: UserStatus;
  message?: Maybe<Scalars['String']>;
}>;


export type AdminSetUserStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminSetUserStatus'>
);

export type AutoCompleteQueryVariables = Exact<{
  startsWith: Scalars['String'];
}>;


export type AutoCompleteQuery = (
  { __typename?: 'Query' }
  & { autoComplete: (
    { __typename?: 'AutoCompleteResult' }
    & { catalogs?: Maybe<Array<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'displayName' | 'description' | 'isPublic'>
      & { identifier: (
        { __typename?: 'CatalogIdentifier' }
        & Pick<CatalogIdentifier, 'registryURL' | 'catalogSlug'>
      ) }
    )>>, packages?: Maybe<Array<(
      { __typename?: 'Package' }
      & Pick<Package, 'updateMethods' | 'updatedAt' | 'displayName' | 'description'>
      & { catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName' | 'isPublic' | 'unclaimed'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username' | 'firstName' | 'lastName' | 'displayName' | 'nameIsPublic'>
      )>, identifier: (
        { __typename?: 'PackageIdentifier' }
        & Pick<PackageIdentifier, 'registryURL' | 'catalogSlug' | 'packageSlug'>
      ), latestVersion?: Maybe<(
        { __typename?: 'Version' }
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'catalogSlug' | 'packageSlug' | 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )> }
    )>>, collections?: Maybe<Array<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'name' | 'description'>
      & { identifier: (
        { __typename?: 'CollectionIdentifier' }
        & Pick<CollectionIdentifier, 'collectionSlug'>
      ) }
    )>>, users?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    )>> }
  ) }
);

export type AutoCompleteUserQueryVariables = Exact<{
  startsWith: Scalars['String'];
}>;


export type AutoCompleteUserQuery = (
  { __typename?: 'Query' }
  & { autoComplete: (
    { __typename?: 'AutoCompleteResult' }
    & { users?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'displayName'>
    )>> }
  ) }
);

export type AutoCompletePackageQueryVariables = Exact<{
  startsWith: Scalars['String'];
}>;


export type AutoCompletePackageQuery = (
  { __typename?: 'Query' }
  & { autoComplete: (
    { __typename?: 'AutoCompleteResult' }
    & { packages?: Maybe<Array<(
      { __typename?: 'Package' }
      & Pick<Package, 'updatedAt' | 'displayName' | 'description'>
      & { identifier: (
        { __typename?: 'PackageIdentifier' }
        & Pick<PackageIdentifier, 'registryURL' | 'catalogSlug' | 'packageSlug'>
      ), catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName' | 'isPublic' | 'unclaimed'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic'>
      )>, latestVersion?: Maybe<(
        { __typename?: 'Version' }
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'catalogSlug' | 'packageSlug' | 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )> }
    )>> }
  ) }
);

export type AutoCompleteCollectionQueryVariables = Exact<{
  startsWith: Scalars['String'];
}>;


export type AutoCompleteCollectionQuery = (
  { __typename?: 'Query' }
  & { autoComplete: (
    { __typename?: 'AutoCompleteResult' }
    & { collections?: Maybe<Array<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'name' | 'description'>
      & { identifier: (
        { __typename?: 'CollectionIdentifier' }
        & Pick<CollectionIdentifier, 'collectionSlug'>
      ) }
    )>> }
  ) }
);

export type AutoCompleteCatalogQueryVariables = Exact<{
  startsWith: Scalars['String'];
}>;


export type AutoCompleteCatalogQuery = (
  { __typename?: 'Query' }
  & { autoComplete: (
    { __typename?: 'AutoCompleteResult' }
    & { catalogs?: Maybe<Array<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'displayName' | 'description' | 'isPublic' | 'myPermissions'>
      & { identifier: (
        { __typename?: 'CatalogIdentifier' }
        & Pick<CatalogIdentifier, 'registryURL' | 'catalogSlug'>
      ) }
    )>> }
  ) }
);

export type GetCatalogQueryVariables = Exact<{
  identifier: CatalogIdentifierInput;
}>;


export type GetCatalogQuery = (
  { __typename?: 'Query' }
  & { catalog: (
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'displayName' | 'description' | 'website' | 'myPermissions' | 'isPublic' | 'unclaimed'>
    & { identifier: (
      { __typename?: 'CatalogIdentifier' }
      & Pick<CatalogIdentifier, 'registryURL' | 'catalogSlug'>
    ) }
  ) }
);

export type CatalogActivitiesQueryVariables = Exact<{
  identifier: CatalogIdentifierInput;
  filter: ActivityLogFilterInput;
}>;


export type CatalogActivitiesQuery = (
  { __typename?: 'Query' }
  & { catalogActivities: (
    { __typename?: 'ActivityLogResult' }
    & Pick<ActivityLogResult, 'count' | 'hasMore'>
    & { logs: Array<(
      { __typename?: 'ActivityLog' }
      & Pick<ActivityLog, 'eventType' | 'changeType' | 'createdAt' | 'updatedAt'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'emailAddress' | 'username' | 'firstName' | 'lastName' | 'displayName'>
      )>, targetPackage?: Maybe<(
        { __typename?: 'Package' }
        & { creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'emailAddress'>
        )>, identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
        ) }
      )>, targetPackageVersion?: Maybe<(
        { __typename?: 'Version' }
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )>, targetCollection?: Maybe<(
        { __typename?: 'Collection' }
        & Pick<Collection, 'name'>
        & { identifier: (
          { __typename?: 'CollectionIdentifier' }
          & Pick<CollectionIdentifier, 'collectionSlug'>
        ) }
      )>, targetCatalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )> }
    )> }
  ) }
);

export type CatalogFollowersQueryVariables = Exact<{
  identifier: CatalogIdentifierInput;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type CatalogFollowersQuery = (
  { __typename?: 'Query' }
  & { catalogFollowers: (
    { __typename?: 'FollowersResult' }
    & Pick<FollowersResult, 'hasMore' | 'count'>
    & { followers?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    )>> }
  ) }
);

export type CatalogFollowersCountQueryVariables = Exact<{
  identifier: CatalogIdentifierInput;
}>;


export type CatalogFollowersCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'catalogFollowersCount'>
);

export type CatalogPackagesQueryVariables = Exact<{
  identifier: CatalogIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type CatalogPackagesQuery = (
  { __typename?: 'Query' }
  & { catalogPackages: Array<(
    { __typename?: 'Package' }
    & Pick<Package, 'displayName' | 'description' | 'createdAt' | 'updatedAt' | 'isPublic' | 'myPermissions'>
  )> }
);

export type CollectionQueryVariables = Exact<{
  identifier: CollectionIdentifierInput;
}>;


export type CollectionQuery = (
  { __typename?: 'Query' }
  & { collection: (
    { __typename?: 'Collection' }
    & Pick<Collection, 'name' | 'description' | 'isPublic' | 'isRecommended' | 'myPermissions' | 'updatedAt'>
    & { creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )>, identifier: (
      { __typename?: 'CollectionIdentifier' }
      & Pick<CollectionIdentifier, 'collectionSlug'>
    ), packages?: Maybe<Array<(
      { __typename?: 'Package' }
      & Pick<Package, 'displayName' | 'description' | 'isPublic' | 'myPermissions' | 'updateMethods'>
      & { identifier: (
        { __typename?: 'PackageIdentifier' }
        & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
      ), catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName' | 'isPublic' | 'unclaimed' | 'myPermissions' | 'description'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )>, latestVersion?: Maybe<(
        { __typename?: 'Version' }
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )> }
    )>> }
  ) }
);

export type CollectionActivitiesQueryVariables = Exact<{
  identifier: CollectionIdentifierInput;
  filter: ActivityLogFilterInput;
}>;


export type CollectionActivitiesQuery = (
  { __typename?: 'Query' }
  & { collectionActivities: (
    { __typename?: 'ActivityLogResult' }
    & Pick<ActivityLogResult, 'count' | 'hasMore'>
    & { logs: Array<(
      { __typename?: 'ActivityLog' }
      & Pick<ActivityLog, 'eventType' | 'changeType' | 'createdAt' | 'updatedAt'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'emailAddress' | 'username' | 'firstName' | 'lastName' | 'displayName'>
      )>, targetPackage?: Maybe<(
        { __typename?: 'Package' }
        & { creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'emailAddress'>
        )>, identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
        ) }
      )>, targetPackageVersion?: Maybe<(
        { __typename?: 'Version' }
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )>, targetCollection?: Maybe<(
        { __typename?: 'Collection' }
        & Pick<Collection, 'name'>
        & { identifier: (
          { __typename?: 'CollectionIdentifier' }
          & Pick<CollectionIdentifier, 'collectionSlug'>
        ) }
      )>, targetCatalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )> }
    )> }
  ) }
);

export type CollectionFollowersQueryVariables = Exact<{
  identifier: CollectionIdentifierInput;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type CollectionFollowersQuery = (
  { __typename?: 'Query' }
  & { collectionFollowers: (
    { __typename?: 'FollowersResult' }
    & Pick<FollowersResult, 'hasMore' | 'count'>
    & { followers?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    )>> }
  ) }
);

export type CollectionFollowersCountQueryVariables = Exact<{
  identifier: CollectionIdentifierInput;
}>;


export type CollectionFollowersCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'collectionFollowersCount'>
);

export type CollectionPackagesQueryVariables = Exact<{
  identifier: CollectionIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type CollectionPackagesQuery = (
  { __typename?: 'Query' }
  & { collectionPackages: Array<(
    { __typename?: 'Package' }
    & Pick<Package, 'isPublic' | 'myPermissions' | 'updateMethods'>
    & { identifier: (
      { __typename?: 'PackageIdentifier' }
      & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
    ), catalog?: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'displayName' | 'isPublic' | 'myPermissions'>
    )>, latestVersion?: Maybe<(
      { __typename?: 'Version' }
      & Pick<Version, 'createdAt' | 'updatedAt'>
      & { identifier: (
        { __typename?: 'VersionIdentifier' }
        & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
      ) }
    )>, creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic'>
    )> }
  )> }
);

export type CollectionSlugAvailableQueryVariables = Exact<{
  collectionSlug: Scalars['String'];
}>;


export type CollectionSlugAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'collectionSlugAvailable'>
);

export type CreateAPIKeyMutationVariables = Exact<{
  value: CreateAPIKeyInput;
}>;


export type CreateAPIKeyMutation = (
  { __typename?: 'Mutation' }
  & { createAPIKey: (
    { __typename?: 'APIKeyWithSecret' }
    & Pick<APIKeyWithSecret, 'secret' | 'label' | 'scopes' | 'id'>
  ) }
);

export type CreateCatalogMutationVariables = Exact<{
  value: CreateCatalogInput;
}>;


export type CreateCatalogMutation = (
  { __typename?: 'Mutation' }
  & { createCatalog: (
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'myPermissions' | 'displayName' | 'description' | 'website' | 'isPublic' | 'unclaimed'>
    & { identifier: (
      { __typename?: 'CatalogIdentifier' }
      & Pick<CatalogIdentifier, 'catalogSlug'>
    ) }
  ) }
);

export type CreateCollectionMutationVariables = Exact<{
  value: CreateCollectionInput;
}>;


export type CreateCollectionMutation = (
  { __typename?: 'Mutation' }
  & { createCollection: (
    { __typename?: 'Collection' }
    & Pick<Collection, 'name' | 'description' | 'createdAt' | 'updatedAt'>
    & { creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'displayName' | 'username'>
    )>, identifier: (
      { __typename?: 'CollectionIdentifier' }
      & Pick<CollectionIdentifier, 'collectionSlug'>
    ), packages?: Maybe<Array<(
      { __typename?: 'Package' }
      & Pick<Package, 'displayName' | 'description'>
      & { identifier: (
        { __typename?: 'PackageIdentifier' }
        & Pick<PackageIdentifier, 'registryURL' | 'catalogSlug' | 'packageSlug'>
      ), latestVersion?: Maybe<(
        { __typename?: 'Version' }
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'catalogSlug' | 'packageSlug' | 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )> }
    )>> }
  ) }
);

export type CreateCredentialMutationVariables = Exact<{
  identifier: PackageIdentifierInput;
  connectorType: Scalars['String'];
  repositoryIdentifier: Scalars['String'];
  credentialIdentifier: Scalars['String'];
  credential: Scalars['JSON'];
}>;


export type CreateCredentialMutation = (
  { __typename?: 'Mutation' }
  & { createCredential: (
    { __typename?: 'Credential' }
    & Pick<Credential, 'credentialIdentifier' | 'createdAt' | 'updatedAt'>
    & { creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic'>
    )> }
  ) }
);

export type SaveFollowMutationVariables = Exact<{
  follow: SaveFollowInput;
}>;


export type SaveFollowMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveFollow'>
);

export type CreateGroupMutationVariables = Exact<{
  groupSlug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup: (
    { __typename?: 'Group' }
    & Pick<Group, 'slug' | 'name' | 'description' | 'createdAt'>
    & { creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username'>
    )> }
  ) }
);

export type CreateMeMutationVariables = Exact<{
  value: CreateUserInput;
}>;


export type CreateMeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMe'>
);

export type CreatePackageMutationVariables = Exact<{
  value: CreatePackageInput;
}>;


export type CreatePackageMutation = (
  { __typename?: 'Mutation' }
  & { createPackage: (
    { __typename?: 'Package' }
    & Pick<Package, 'displayName' | 'description'>
    & { identifier: (
      { __typename?: 'PackageIdentifier' }
      & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
    ), catalog?: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'myPermissions' | 'displayName'>
    )>, latestVersion?: Maybe<(
      { __typename?: 'Version' }
      & Pick<Version, 'packageFile'>
      & { identifier: (
        { __typename?: 'VersionIdentifier' }
        & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
      ) }
    )> }
  ) }
);

export type CreatePackageIssueMutationVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  issue: CreatePackageIssueInput;
}>;


export type CreatePackageIssueMutation = (
  { __typename?: 'Mutation' }
  & { createPackageIssue?: Maybe<(
    { __typename?: 'PackageIssue' }
    & Pick<PackageIssue, 'issueNumber' | 'subject' | 'content' | 'status' | 'createdAt' | 'updatedAt'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    )> }
  )> }
);

export type CreatePackageIssueCommentMutationVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  comment: CreatePackageIssueCommentInput;
}>;


export type CreatePackageIssueCommentMutation = (
  { __typename?: 'Mutation' }
  & { createPackageIssueComment?: Maybe<(
    { __typename?: 'PackageIssueComment' }
    & Pick<PackageIssueComment, 'commentNumber' | 'content' | 'createdAt' | 'updatedAt'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    ) }
  )> }
);

export type CreateRepositoryMutationVariables = Exact<{
  identifier: PackageIdentifierInput;
  connectorType: Scalars['String'];
  repositoryIdentifier: Scalars['String'];
  connectionConfiguration: Scalars['JSON'];
}>;


export type CreateRepositoryMutation = (
  { __typename?: 'Mutation' }
  & { createRepository: (
    { __typename?: 'Repository' }
    & Pick<Repository, 'connectorType' | 'repositoryIdentifier' | 'createdAt' | 'updatedAt'>
    & { creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic'>
    )> }
  ) }
);

export type CreateVersionMutationVariables = Exact<{
  identifier: PackageIdentifierInput;
  value: CreateVersionInput;
}>;


export type CreateVersionMutation = (
  { __typename?: 'Mutation' }
  & { createVersion: (
    { __typename?: 'Version' }
    & Pick<Version, 'createdAt' | 'updatedAt' | 'packageFile'>
    & { identifier: (
      { __typename?: 'VersionIdentifier' }
      & Pick<VersionIdentifier, 'catalogSlug' | 'packageSlug' | 'versionMajor' | 'versionMinor' | 'versionPatch'>
    ), package?: Maybe<(
      { __typename?: 'Package' }
      & Pick<Package, 'displayName' | 'description'>
      & { catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName' | 'description'>
      )> }
    )>, author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'displayName' | 'emailAddress' | 'twitterHandle' | 'gitHubHandle'>
    )> }
  ) }
);

export type DeleteAPIKeyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAPIKeyMutation = (
  { __typename?: 'Mutation' }
  & { deleteAPIKey: (
    { __typename?: 'APIKey' }
    & Pick<APIKey, 'label' | 'scopes' | 'id'>
  ) }
);

export type DeleteAllMyFollowsMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAllMyFollowsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAllMyFollows'>
);

export type DeleteCatalogMutationVariables = Exact<{
  identifier: CatalogIdentifierInput;
}>;


export type DeleteCatalogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCatalog'>
);

export type DeleteCatalogAvatarImageMutationVariables = Exact<{
  identifier: CatalogIdentifierInput;
}>;


export type DeleteCatalogAvatarImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCatalogAvatarImage'>
);

export type DeleteCollectionMutationVariables = Exact<{
  identifier: CollectionIdentifierInput;
}>;


export type DeleteCollectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCollection'>
);

export type DeleteCredentialMutationVariables = Exact<{
  identifier: PackageIdentifierInput;
  connectorType: Scalars['String'];
  repositoryIdentifier: Scalars['String'];
  credentialIdentifier: Scalars['String'];
}>;


export type DeleteCredentialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCredential'>
);

export type DeleteFollowMutationVariables = Exact<{
  follow: FollowIdentifierInput;
}>;


export type DeleteFollowMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFollow'>
);

export type DeleteGroupMutationVariables = Exact<{
  groupSlug: Scalars['String'];
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGroup'>
);

export type DeleteMeMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteMeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMe'>
);

export type DeletePackageMutationVariables = Exact<{
  identifier: PackageIdentifierInput;
}>;


export type DeletePackageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePackage'>
);

export type DeletePackageIssueMutationVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  packageIssueIdentifier: PackageIssueIdentifierInput;
}>;


export type DeletePackageIssueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePackageIssue'>
);

export type DeletePackageIssueCommentMutationVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  issueCommentIdentifier: PackageIssueCommentIdentifierInput;
}>;


export type DeletePackageIssueCommentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePackageIssueComment'>
);

export type DeletePackageIssuesMutationVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  issuesIdentifiers: Array<PackageIssueIdentifierInput>;
}>;


export type DeletePackageIssuesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePackageIssues'>
);

export type DeleteRepositoryMutationVariables = Exact<{
  identifier: PackageIdentifierInput;
  connectorType: Scalars['String'];
  repositoryIdentifier: Scalars['String'];
}>;


export type DeleteRepositoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRepository'>
);

export type DeleteUserCatalogPermissionsMutationVariables = Exact<{
  usernameOrEmailAddress: Scalars['String'];
  identifier: CatalogIdentifierInput;
}>;


export type DeleteUserCatalogPermissionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserCatalogPermissions'>
);

export type DeleteUserCollectionPermissionsMutationVariables = Exact<{
  identifier: CollectionIdentifierInput;
  usernameOrEmailAddress: Scalars['String'];
}>;


export type DeleteUserCollectionPermissionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserCollectionPermissions'>
);

export type DeleteVersionMutationVariables = Exact<{
  identifier: VersionIdentifierInput;
}>;


export type DeleteVersionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteVersion'>
);

export type EmailAddressAvailableQueryVariables = Exact<{
  emailAddress: Scalars['String'];
}>;


export type EmailAddressAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'emailAddressAvailable'>
);

export type ForgotMyPasswordMutationVariables = Exact<{
  emailAddress: Scalars['String'];
}>;


export type ForgotMyPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotMyPassword'>
);

export type GetFollowQueryVariables = Exact<{
  follow: FollowIdentifierInput;
}>;


export type GetFollowQuery = (
  { __typename?: 'Query' }
  & { getFollow?: Maybe<(
    { __typename?: 'Follow' }
    & Pick<Follow, 'notificationFrequency' | 'eventTypes' | 'changeType' | 'followAllPackages' | 'followAllPackageIssues'>
  )> }
);

export type GroupQueryVariables = Exact<{
  groupSlug: Scalars['String'];
}>;


export type GroupQuery = (
  { __typename?: 'Query' }
  & { group: (
    { __typename?: 'Group' }
    & Pick<Group, 'slug' | 'name' | 'description' | 'myPermissions'>
    & { packagePermissions?: Maybe<Array<(
      { __typename?: 'GroupPackagePermission' }
      & Pick<GroupPackagePermission, 'permissions'>
      & { package?: Maybe<(
        { __typename?: 'Package' }
        & Pick<Package, 'isPublic' | 'myPermissions' | 'displayName' | 'description'>
        & { identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'registryURL' | 'catalogSlug' | 'packageSlug'>
        ), catalog?: Maybe<(
          { __typename?: 'Catalog' }
          & Pick<Catalog, 'displayName' | 'myPermissions' | 'isPublic' | 'unclaimed'>
          & { identifier: (
            { __typename?: 'CatalogIdentifier' }
            & Pick<CatalogIdentifier, 'catalogSlug'>
          ) }
        )>, creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic'>
        )> }
      )> }
    )>>, collectionPermissions?: Maybe<Array<(
      { __typename?: 'GroupCollectionPermission' }
      & Pick<GroupCollectionPermission, 'permissions'>
      & { collection?: Maybe<(
        { __typename?: 'Collection' }
        & Pick<Collection, 'name' | 'description' | 'isPublic' | 'isRecommended' | 'myPermissions'>
        & { identifier: (
          { __typename?: 'CollectionIdentifier' }
          & Pick<CollectionIdentifier, 'collectionSlug'>
        ) }
      )> }
    )>>, catalogPermissions?: Maybe<Array<(
      { __typename?: 'GroupCatalogPermission' }
      & Pick<GroupCatalogPermission, 'permissions' | 'packagePermissions'>
      & { catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName' | 'description' | 'website' | 'myPermissions' | 'isPublic' | 'unclaimed'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'registryURL' | 'catalogSlug'>
        ) }
      )> }
    )>>, users?: Maybe<Array<(
      { __typename?: 'GroupUser' }
      & Pick<GroupUser, 'permissions'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username' | 'firstName' | 'lastName' | 'displayName' | 'nameIsPublic'>
      )> }
    )>> }
  ) }
);

export type GroupsByCatalogQueryVariables = Exact<{
  catalogIdentifier: CatalogIdentifierInput;
}>;


export type GroupsByCatalogQuery = (
  { __typename?: 'Query' }
  & { groupsByCatalog: Array<(
    { __typename?: 'GroupCatalogPermission' }
    & Pick<GroupCatalogPermission, 'permissions' | 'packagePermissions'>
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'name' | 'description' | 'slug'>
      & { users?: Maybe<Array<(
        { __typename?: 'GroupUser' }
        & Pick<GroupUser, 'permissions'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic' | 'displayName'>
        )> }
      )>> }
    )> }
  )> }
);

export type GroupsByCollectionQueryVariables = Exact<{
  collectionIdentifier: CollectionIdentifierInput;
}>;


export type GroupsByCollectionQuery = (
  { __typename?: 'Query' }
  & { groupsByCollection: Array<(
    { __typename?: 'GroupCollectionPermission' }
    & Pick<GroupCollectionPermission, 'permissions'>
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'name' | 'slug' | 'description'>
      & { users?: Maybe<Array<(
        { __typename?: 'GroupUser' }
        & Pick<GroupUser, 'permissions'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic' | 'displayName'>
        )> }
      )>> }
    )> }
  )> }
);

export type GroupsByPackageQueryVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
}>;


export type GroupsByPackageQuery = (
  { __typename?: 'Query' }
  & { groupsByPackage: Array<(
    { __typename?: 'GroupPackagePermission' }
    & Pick<GroupPackagePermission, 'permissions'>
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'name' | 'slug' | 'description'>
      & { users?: Maybe<Array<(
        { __typename?: 'GroupUser' }
        & Pick<GroupUser, 'permissions'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic' | 'displayName'>
        )> }
      )>> }
    )> }
  )> }
);

export type GetLatestCollectionsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetLatestCollectionsQuery = (
  { __typename?: 'Query' }
  & { latestCollections: (
    { __typename?: 'CollectionsResult' }
    & Pick<CollectionsResult, 'hasMore' | 'count'>
    & { collections?: Maybe<Array<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'name' | 'description' | 'isPublic' | 'isRecommended'>
      & { identifier: (
        { __typename?: 'CollectionIdentifier' }
        & Pick<CollectionIdentifier, 'collectionSlug'>
      ) }
    )>> }
  ) }
);

export type GetLatestPackagesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetLatestPackagesQuery = (
  { __typename?: 'Query' }
  & { latestPackages: (
    { __typename?: 'LatestPackagesResult' }
    & Pick<LatestPackagesResult, 'hasMore' | 'count'>
    & { packages?: Maybe<Array<(
      { __typename?: 'Package' }
      & Pick<Package, 'updateMethods' | 'myPermissions' | 'isPublic' | 'displayName' | 'description'>
      & { identifier: (
        { __typename?: 'PackageIdentifier' }
        & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
      ), creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic'>
      )>, catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName' | 'isPublic' | 'unclaimed'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )> }
    )>> }
  ) }
);

export type ListConnectorsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListConnectorsQuery = (
  { __typename?: 'Query' }
  & { listConnectors: Array<(
    { __typename?: 'ConnectorDescription' }
    & Pick<ConnectorDescription, 'connectorType' | 'displayName' | 'hasSource' | 'hasSink'>
  )> }
);

export type ListRepositoriesQueryVariables = Exact<{
  identifier: PackageIdentifierInput;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type ListRepositoriesQuery = (
  { __typename?: 'Query' }
  & { listRepositories: (
    { __typename?: 'RepositoriesResult' }
    & Pick<RepositoriesResult, 'hasMore' | 'count'>
    & { repositories?: Maybe<Array<(
      { __typename?: 'Repository' }
      & Pick<Repository, 'repositoryIdentifier' | 'connectorType' | 'createdAt' | 'updatedAt'>
      & { package?: Maybe<(
        { __typename?: 'Package' }
        & { identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
        ), catalog?: Maybe<(
          { __typename?: 'Catalog' }
          & { identifier: (
            { __typename?: 'CatalogIdentifier' }
            & Pick<CatalogIdentifier, 'catalogSlug'>
          ) }
        )> }
      )>, credentials?: Maybe<Array<(
        { __typename?: 'Credential' }
        & Pick<Credential, 'credentialIdentifier' | 'createdAt' | 'updatedAt'>
        & { creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic'>
        )> }
      )>>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic'>
      )> }
    )>> }
  ) }
);

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'login'>
);

export type LogoutMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'CurrentUser' }
    & Pick<CurrentUser, 'isAdmin'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'emailAddress' | 'displayName' | 'firstName' | 'lastName' | 'username' | 'description' | 'location' | 'twitterHandle' | 'gitHubHandle' | 'website' | 'nameIsPublic' | 'locationIsPublic' | 'twitterHandleIsPublic' | 'gitHubHandleIsPublic' | 'emailAddressIsPublic' | 'websiteIsPublic' | 'uiDarkModeEnabled'>
    ) }
  ) }
);

export type MovePackageMutationVariables = Exact<{
  identifier: PackageIdentifierInput;
  catalogIdentifier: CatalogIdentifierInput;
}>;


export type MovePackageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'movePackage'>
);

export type MyAPIKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAPIKeysQuery = (
  { __typename?: 'Query' }
  & { myAPIKeys?: Maybe<Array<(
    { __typename?: 'APIKey' }
    & Pick<APIKey, 'id' | 'label' | 'scopes' | 'createdAt' | 'lastUsed'>
  )>> }
);

export type MyActivityQueryVariables = Exact<{
  filter: ActivityLogFilterInput;
}>;


export type MyActivityQuery = (
  { __typename?: 'Query' }
  & { myActivity: (
    { __typename?: 'ActivityLogResult' }
    & Pick<ActivityLogResult, 'count' | 'hasMore'>
    & { logs: Array<(
      { __typename?: 'ActivityLog' }
      & Pick<ActivityLog, 'propertiesEdited' | 'eventType' | 'changeType' | 'createdAt' | 'updatedAt' | 'permissions'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'emailAddress' | 'username' | 'firstName' | 'lastName' | 'displayName'>
      )>, targetPackage?: Maybe<(
        { __typename?: 'Package' }
        & { creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'emailAddress'>
        )>, identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
        ) }
      )>, targetPackageIssue?: Maybe<(
        { __typename?: 'PackageIssue' }
        & Pick<PackageIssue, 'issueNumber'>
        & { packageIdentifier?: Maybe<(
          { __typename?: 'BasePackageIdentifier' }
          & Pick<BasePackageIdentifier, 'catalogSlug' | 'packageSlug'>
        )>, author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'firstName' | 'lastName' | 'emailAddress'>
        )> }
      )>, targetGroup?: Maybe<(
        { __typename?: 'Group' }
        & Pick<Group, 'name' | 'slug'>
      )>, targetPackageVersion?: Maybe<(
        { __typename?: 'Version' }
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )>, targetCollection?: Maybe<(
        { __typename?: 'Collection' }
        & Pick<Collection, 'name'>
        & { identifier: (
          { __typename?: 'CollectionIdentifier' }
          & Pick<CollectionIdentifier, 'collectionSlug'>
        ) }
      )>, targetCatalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )>, targetUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'emailAddress' | 'username' | 'firstName' | 'lastName' | 'displayName'>
      )> }
    )> }
  ) }
);

export type MyCatalogsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCatalogsQuery = (
  { __typename?: 'Query' }
  & { myCatalogs: Array<Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'displayName' | 'description' | 'website' | 'isPublic' | 'unclaimed' | 'myPermissions'>
    & { identifier: (
      { __typename?: 'CatalogIdentifier' }
      & Pick<CatalogIdentifier, 'registryURL' | 'catalogSlug'>
    ) }
  )>> }
);

export type MyCollectionsQueryVariables = Exact<{
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type MyCollectionsQuery = (
  { __typename?: 'Query' }
  & { myCollections: (
    { __typename?: 'CollectionsResult' }
    & Pick<CollectionsResult, 'hasMore' | 'count'>
    & { collections?: Maybe<Array<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'name' | 'description' | 'isPublic' | 'isRecommended'>
      & { identifier: (
        { __typename?: 'CollectionIdentifier' }
        & Pick<CollectionIdentifier, 'collectionSlug'>
      ), packages?: Maybe<Array<(
        { __typename?: 'Package' }
        & Pick<Package, 'displayName' | 'description'>
        & { identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
        ), latestVersion?: Maybe<(
          { __typename?: 'Version' }
          & { identifier: (
            { __typename?: 'VersionIdentifier' }
            & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
          ) }
        )> }
      )>> }
    )>> }
  ) }
);

export type MyFollowingActivityQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type MyFollowingActivityQuery = (
  { __typename?: 'Query' }
  & { myFollowingActivity?: Maybe<(
    { __typename?: 'FollowingActivity' }
    & Pick<FollowingActivity, 'hasMore' | 'count'>
    & { logs: Array<(
      { __typename?: 'ActivityLog' }
      & Pick<ActivityLog, 'id' | 'eventType' | 'changeType' | 'createdAt' | 'updatedAt' | 'propertiesEdited'>
      & { targetCatalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )>, targetPackage?: Maybe<(
        { __typename?: 'Package' }
        & Pick<Package, 'displayName'>
        & { identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
        ) }
      )>, targetGroup?: Maybe<(
        { __typename?: 'Group' }
        & Pick<Group, 'name' | 'slug'>
      )>, targetPackageIssue?: Maybe<(
        { __typename?: 'PackageIssue' }
        & Pick<PackageIssue, 'issueNumber'>
        & { packageIdentifier?: Maybe<(
          { __typename?: 'BasePackageIdentifier' }
          & Pick<BasePackageIdentifier, 'catalogSlug' | 'packageSlug'>
        )> }
      )>, targetCollection?: Maybe<(
        { __typename?: 'Collection' }
        & Pick<Collection, 'name'>
        & { identifier: (
          { __typename?: 'CollectionIdentifier' }
          & Pick<CollectionIdentifier, 'collectionSlug'>
        ) }
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'username'>
      )> }
    )> }
  )> }
);

export type MyFollowsQueryVariables = Exact<{
  type: FollowType;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type MyFollowsQuery = (
  { __typename?: 'Query' }
  & { myFollows: (
    { __typename?: 'MyFollowsResult' }
    & Pick<MyFollowsResult, 'hasMore' | 'count'>
    & { follows?: Maybe<Array<(
      { __typename?: 'Follow' }
      & Pick<Follow, 'notificationFrequency' | 'eventTypes'>
      & { catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'registryURL' | 'catalogSlug'>
        ) }
      )>, package?: Maybe<(
        { __typename?: 'Package' }
        & Pick<Package, 'displayName'>
        & { identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'registryURL' | 'catalogSlug' | 'packageSlug'>
        ) }
      )>, packageIssue?: Maybe<(
        { __typename?: 'PackageIssue' }
        & Pick<PackageIssue, 'issueNumber' | 'subject'>
        & { packageIdentifier?: Maybe<(
          { __typename?: 'BasePackageIdentifier' }
          & Pick<BasePackageIdentifier, 'catalogSlug' | 'packageSlug'>
        )> }
      )>, collection?: Maybe<(
        { __typename?: 'Collection' }
        & Pick<Collection, 'name'>
        & { identifier: (
          { __typename?: 'CollectionIdentifier' }
          & Pick<CollectionIdentifier, 'collectionSlug'>
        ) }
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
      )> }
    )>> }
  ) }
);

export type MyGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyGroupsQuery = (
  { __typename?: 'Query' }
  & { myGroups: Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'slug' | 'name' | 'description' | 'myPermissions'>
  )> }
);

export type MyPackagesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type MyPackagesQuery = (
  { __typename?: 'Query' }
  & { myPackages: (
    { __typename?: 'PackagesResult' }
    & Pick<PackagesResult, 'hasMore' | 'count'>
    & { packages?: Maybe<Array<(
      { __typename?: 'Package' }
      & Pick<Package, 'myPermissions' | 'isPublic' | 'displayName' | 'description'>
      & { identifier: (
        { __typename?: 'PackageIdentifier' }
        & Pick<PackageIdentifier, 'registryURL' | 'catalogSlug' | 'packageSlug'>
      ), catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'myPermissions' | 'displayName' | 'isPublic' | 'unclaimed'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )>, latestVersion?: Maybe<(
        { __typename?: 'Version' }
        & Pick<Version, 'createdAt'>
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username' | 'firstName' | 'lastName'>
      )>, versions?: Maybe<Array<Maybe<(
        { __typename?: 'Version' }
        & Pick<Version, 'createdAt'>
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )>>> }
    )>> }
  ) }
);

export type MyRecentlyViewedCollectionsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type MyRecentlyViewedCollectionsQuery = (
  { __typename?: 'Query' }
  & { myRecentlyViewedCollections: (
    { __typename?: 'ActivityLogResult' }
    & Pick<ActivityLogResult, 'hasMore' | 'count'>
    & { logs: Array<(
      { __typename?: 'ActivityLog' }
      & { targetCollection?: Maybe<(
        { __typename?: 'Collection' }
        & Pick<Collection, 'name' | 'description' | 'isPublic' | 'isRecommended'>
        & { identifier: (
          { __typename?: 'CollectionIdentifier' }
          & Pick<CollectionIdentifier, 'collectionSlug'>
        ) }
      )> }
    )> }
  ) }
);

export type MyRecentlyViewedPackagesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type MyRecentlyViewedPackagesQuery = (
  { __typename?: 'Query' }
  & { myRecentlyViewedPackages: (
    { __typename?: 'ActivityLogResult' }
    & Pick<ActivityLogResult, 'hasMore' | 'count'>
    & { logs: Array<(
      { __typename?: 'ActivityLog' }
      & { targetPackage?: Maybe<(
        { __typename?: 'Package' }
        & Pick<Package, 'myPermissions' | 'isPublic' | 'displayName' | 'description'>
        & { identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
        ), catalog?: Maybe<(
          { __typename?: 'Catalog' }
          & Pick<Catalog, 'displayName' | 'isPublic' | 'unclaimed'>
          & { identifier: (
            { __typename?: 'CatalogIdentifier' }
            & Pick<CatalogIdentifier, 'catalogSlug'>
          ) }
        )>, creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic'>
        )> }
      )> }
    )> }
  ) }
);

export type PackageQueryVariables = Exact<{
  identifier: PackageIdentifierInput;
}>;


export type PackageQuery = (
  { __typename?: 'Query' }
  & { package: (
    { __typename?: 'Package' }
    & Pick<Package, 'isPublic' | 'myPermissions' | 'displayName' | 'description'>
    & { identifier: (
      { __typename?: 'PackageIdentifier' }
      & Pick<PackageIdentifier, 'registryURL' | 'catalogSlug' | 'packageSlug'>
    ), catalog?: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'displayName' | 'myPermissions' | 'isPublic' | 'unclaimed'>
      & { identifier: (
        { __typename?: 'CatalogIdentifier' }
        & Pick<CatalogIdentifier, 'catalogSlug'>
      ) }
    )>, creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic'>
    )>, latestVersion?: Maybe<(
      { __typename?: 'Version' }
      & Pick<Version, 'packageFile' | 'canonicalPackageFile' | 'createdAt' | 'updatedAt'>
      & { identifier: (
        { __typename?: 'VersionIdentifier' }
        & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
      ) }
    )>, versions?: Maybe<Array<Maybe<(
      { __typename?: 'Version' }
      & Pick<Version, 'createdAt'>
      & { identifier: (
        { __typename?: 'VersionIdentifier' }
        & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
      ), author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
      )> }
    )>>> }
  ) }
);

export type PackageActivitiesQueryVariables = Exact<{
  identifier: PackageIdentifierInput;
  filter: ActivityLogFilterInput;
}>;


export type PackageActivitiesQuery = (
  { __typename?: 'Query' }
  & { packageActivities: (
    { __typename?: 'ActivityLogResult' }
    & Pick<ActivityLogResult, 'count' | 'hasMore'>
    & { logs: Array<(
      { __typename?: 'ActivityLog' }
      & Pick<ActivityLog, 'eventType' | 'changeType' | 'createdAt' | 'updatedAt'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'emailAddress' | 'username' | 'firstName' | 'lastName' | 'displayName'>
      )>, targetPackage?: Maybe<(
        { __typename?: 'Package' }
        & { creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'emailAddress'>
        )>, identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
        ) }
      )>, targetPackageVersion?: Maybe<(
        { __typename?: 'Version' }
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )>, targetCollection?: Maybe<(
        { __typename?: 'Collection' }
        & Pick<Collection, 'name'>
        & { identifier: (
          { __typename?: 'CollectionIdentifier' }
          & Pick<CollectionIdentifier, 'collectionSlug'>
        ) }
      )>, targetCatalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )> }
    )> }
  ) }
);

export type PackageCollectionsQueryVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type PackageCollectionsQuery = (
  { __typename?: 'Query' }
  & { packageCollections?: Maybe<(
    { __typename?: 'PackageCollectionsResult' }
    & Pick<PackageCollectionsResult, 'hasMore' | 'count'>
    & { collections?: Maybe<Array<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'name' | 'description'>
      & { identifier: (
        { __typename?: 'CollectionIdentifier' }
        & Pick<CollectionIdentifier, 'collectionSlug'>
      ), packages?: Maybe<Array<(
        { __typename?: 'Package' }
        & Pick<Package, 'displayName' | 'description' | 'updateMethods' | 'isPublic' | 'myPermissions'>
        & { identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
        ), creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username'>
        )>, catalog?: Maybe<(
          { __typename?: 'Catalog' }
          & Pick<Catalog, 'displayName' | 'isPublic' | 'unclaimed'>
          & { identifier: (
            { __typename?: 'CatalogIdentifier' }
            & Pick<CatalogIdentifier, 'catalogSlug'>
          ) }
        )> }
      )>> }
    )>> }
  )> }
);

export type PackageFetchedMutationVariables = Exact<{
  identifier: VersionIdentifierInput;
}>;


export type PackageFetchedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'packageFetched'>
);

export type PackageFollowersQueryVariables = Exact<{
  identifier: PackageIdentifierInput;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type PackageFollowersQuery = (
  { __typename?: 'Query' }
  & { packageFollowers: (
    { __typename?: 'FollowersResult' }
    & Pick<FollowersResult, 'hasMore' | 'count'>
    & { followers?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    )>> }
  ) }
);

export type PackageFollowersCountQueryVariables = Exact<{
  identifier: PackageIdentifierInput;
}>;


export type PackageFollowersCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'packageFollowersCount'>
);

export type PackageIssueQueryVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  packageIssueIdentifier: PackageIssueIdentifierInput;
}>;


export type PackageIssueQuery = (
  { __typename?: 'Query' }
  & { packageIssue?: Maybe<(
    { __typename?: 'PackageIssue' }
    & Pick<PackageIssue, 'issueNumber' | 'subject' | 'content' | 'status' | 'createdAt' | 'updatedAt'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    )> }
  )> }
);

export type PackageIssueCommentsQueryVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orderBy: OrderBy;
}>;


export type PackageIssueCommentsQuery = (
  { __typename?: 'Query' }
  & { packageIssueComments?: Maybe<(
    { __typename?: 'PackageIssueCommentsResult' }
    & Pick<PackageIssueCommentsResult, 'hasMore' | 'count'>
    & { comments?: Maybe<Array<(
      { __typename?: 'PackageIssueComment' }
      & Pick<PackageIssueComment, 'commentNumber' | 'content' | 'createdAt' | 'updatedAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
      ) }
    )>> }
  )> }
);

export type PackageIssueFollowersQueryVariables = Exact<{
  identifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type PackageIssueFollowersQuery = (
  { __typename?: 'Query' }
  & { packageIssueFollowers: (
    { __typename?: 'FollowersResult' }
    & Pick<FollowersResult, 'hasMore' | 'count'>
    & { followers?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    )>> }
  ) }
);

export type PackageIssueFollowersCountQueryVariables = Exact<{
  identifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
}>;


export type PackageIssueFollowersCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'packageIssueFollowersCount'>
);

export type PackageIssuesQueryVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  includeOpenIssues: Scalars['Boolean'];
  includeClosedIssues: Scalars['Boolean'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orderBy: OrderBy;
}>;


export type PackageIssuesQuery = (
  { __typename?: 'Query' }
  & { packageIssues?: Maybe<(
    { __typename?: 'PackageIssuesResult' }
    & Pick<PackageIssuesResult, 'hasMore' | 'count' | 'openIssuesCount' | 'closedIssuesCount'>
    & { issues?: Maybe<Array<(
      { __typename?: 'PackageIssue' }
      & Pick<PackageIssue, 'issueNumber' | 'subject' | 'content' | 'status' | 'createdAt' | 'updatedAt'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
      )> }
    )>> }
  )> }
);

export type PackageVersionsDiffQueryVariables = Exact<{
  newVersion: VersionIdentifierInput;
  oldVersion: VersionIdentifierInput;
}>;


export type PackageVersionsDiffQuery = (
  { __typename?: 'Query' }
  & { packageVersionsDiff: (
    { __typename?: 'PackageDifferences' }
    & { newVersion?: Maybe<(
      { __typename?: 'VersionIdentifierValues' }
      & Pick<VersionIdentifierValues, 'versionMajor' | 'versionMinor' | 'versionPatch'>
    )>, oldVersion?: Maybe<(
      { __typename?: 'VersionIdentifierValues' }
      & Pick<VersionIdentifierValues, 'versionMajor' | 'versionMinor' | 'versionPatch'>
    )>, differences?: Maybe<Array<Maybe<(
      { __typename?: 'PackageDifference' }
      & Pick<PackageDifference, 'type' | 'pointer'>
    )>>> }
  ) }
);

export type PackageVersionsDiffsQueryVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type PackageVersionsDiffsQuery = (
  { __typename?: 'Query' }
  & { packageVersionsDiffs?: Maybe<Array<(
    { __typename?: 'PackageDifferences' }
    & { newVersion?: Maybe<(
      { __typename?: 'VersionIdentifierValues' }
      & Pick<VersionIdentifierValues, 'versionMajor' | 'versionMinor' | 'versionPatch'>
    )>, oldVersion?: Maybe<(
      { __typename?: 'VersionIdentifierValues' }
      & Pick<VersionIdentifierValues, 'versionMajor' | 'versionMinor' | 'versionPatch'>
    )>, differences?: Maybe<Array<Maybe<(
      { __typename?: 'PackageDifference' }
      & Pick<PackageDifference, 'type' | 'pointer'>
    )>>> }
  )>> }
);

export type GetPageContentQueryVariables = Exact<{
  route: Scalars['String'];
}>;


export type GetPageContentQuery = (
  { __typename?: 'Query' }
  & { pageContent: (
    { __typename?: 'PageContent' }
    & { catalog?: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'displayName' | 'description' | 'website' | 'myPermissions' | 'isPublic' | 'unclaimed'>
      & { identifier: (
        { __typename?: 'CatalogIdentifier' }
        & Pick<CatalogIdentifier, 'registryURL' | 'catalogSlug'>
      ), packages?: Maybe<Array<Maybe<(
        { __typename?: 'Package' }
        & Pick<Package, 'displayName' | 'description' | 'updateMethods' | 'isPublic' | 'myPermissions'>
        & { identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'registryURL' | 'catalogSlug' | 'packageSlug'>
        ), catalog?: Maybe<(
          { __typename?: 'Catalog' }
          & Pick<Catalog, 'displayName' | 'isPublic' | 'unclaimed'>
          & { identifier: (
            { __typename?: 'CatalogIdentifier' }
            & Pick<CatalogIdentifier, 'catalogSlug'>
          ) }
        )>, latestVersion?: Maybe<(
          { __typename?: 'Version' }
          & Pick<Version, 'createdAt' | 'updatedAt' | 'packageFile'>
          & { identifier: (
            { __typename?: 'VersionIdentifier' }
            & Pick<VersionIdentifier, 'registryURL' | 'catalogSlug' | 'packageSlug' | 'versionMajor' | 'versionMinor' | 'versionPatch'>
          ) }
        )>, creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'username' | 'displayName'>
        )> }
      )>>> }
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'emailAddress' | 'firstName' | 'lastName' | 'displayName' | 'username' | 'nameIsPublic' | 'location' | 'twitterHandle' | 'gitHubHandle' | 'website' | 'locationIsPublic' | 'twitterHandleIsPublic' | 'gitHubHandleIsPublic' | 'emailAddressIsPublic' | 'websiteIsPublic' | 'description'>
    )>, builderIOPage?: Maybe<(
      { __typename?: 'BuilderIOPage' }
      & Pick<BuilderIOPage, 'apiKey'>
      & { template?: Maybe<(
        { __typename?: 'BuilderIOTemplate' }
        & Pick<BuilderIOTemplate, 'key' | 'entry'>
      )> }
    )> }
  ) }
);

export type PlatformSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type PlatformSettingsQuery = (
  { __typename?: 'Query' }
  & { platformSettings?: Maybe<Array<(
    { __typename?: 'PlatformSettings' }
    & Pick<PlatformSettings, 'key' | 'isPublic' | 'serializedSettings'>
  )>> }
);

export type PublicPlatformSettingsByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type PublicPlatformSettingsByKeyQuery = (
  { __typename?: 'Query' }
  & { publicPlatformSettingsByKey: (
    { __typename?: 'PlatformSettings' }
    & Pick<PlatformSettings, 'serializedSettings'>
  ) }
);

export type RecoverMyPasswordMutationVariables = Exact<{
  value: RecoverMyPasswordInput;
}>;


export type RecoverMyPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recoverMyPassword'>
);

export type RegistryStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type RegistryStatusQuery = (
  { __typename?: 'Query' }
  & { registryStatus: (
    { __typename?: 'RegistryStatusResult' }
    & Pick<RegistryStatusResult, 'status' | 'version' | 'registryUrl'>
  ) }
);

export type RemoveGroupFromCatalogMutationVariables = Exact<{
  groupSlug: Scalars['String'];
  catalogIdentifier: CatalogIdentifierInput;
}>;


export type RemoveGroupFromCatalogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeGroupFromCatalog'>
);

export type RemoveGroupFromCollectionMutationVariables = Exact<{
  groupSlug: Scalars['String'];
  collectionIdentifier: CollectionIdentifierInput;
}>;


export type RemoveGroupFromCollectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeGroupFromCollection'>
);

export type RemoveGroupFromPackageMutationVariables = Exact<{
  groupSlug: Scalars['String'];
  packageIdentifier: PackageIdentifierInput;
}>;


export type RemoveGroupFromPackageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeGroupFromPackage'>
);

export type RemovePackageFromCollectionMutationVariables = Exact<{
  collectionIdentifier: CollectionIdentifierInput;
  packageIdentifier: PackageIdentifierInput;
}>;


export type RemovePackageFromCollectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removePackageFromCollection'>
);

export type RemovePackagePermissionsMutationVariables = Exact<{
  usernameOrEmailAddress: Scalars['String'];
  identifier: PackageIdentifierInput;
}>;


export type RemovePackagePermissionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removePackagePermissions'>
);

export type RemoveUserFromGroupMutationVariables = Exact<{
  username: Scalars['String'];
  groupSlug: Scalars['String'];
}>;


export type RemoveUserFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromGroup: (
    { __typename?: 'Group' }
    & Pick<Group, 'slug' | 'name'>
    & { users?: Maybe<Array<(
      { __typename?: 'GroupUser' }
      & Pick<GroupUser, 'permissions'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic' | 'displayName'>
      )> }
    )>> }
  ) }
);

export type RunJobMutationVariables = Exact<{
  key: Scalars['String'];
  job: JobType;
}>;


export type RunJobMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runJob'>
);

export type SavePlatformSettingsMutationVariables = Exact<{
  settings: PlatformSettingsInput;
}>;


export type SavePlatformSettingsMutation = (
  { __typename?: 'Mutation' }
  & { savePlatformSettings: (
    { __typename?: 'PlatformSettings' }
    & Pick<PlatformSettings, 'key' | 'isPublic' | 'serializedSettings'>
  ) }
);

export type SearchCatalogsQueryVariables = Exact<{
  query: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type SearchCatalogsQuery = (
  { __typename?: 'Query' }
  & { searchCatalogs: (
    { __typename?: 'SearchCatalogsResult' }
    & Pick<SearchCatalogsResult, 'hasMore' | 'count'>
    & { catalogs?: Maybe<Array<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'displayName' | 'description' | 'myPermissions'>
      & { identifier: (
        { __typename?: 'CatalogIdentifier' }
        & Pick<CatalogIdentifier, 'catalogSlug'>
      ) }
    )>> }
  ) }
);

export type SearchCollectionsQueryVariables = Exact<{
  query: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type SearchCollectionsQuery = (
  { __typename?: 'Query' }
  & { searchCollections: (
    { __typename?: 'SearchCollectionResult' }
    & Pick<SearchCollectionResult, 'hasMore' | 'count'>
    & { collections?: Maybe<Array<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'name' | 'description' | 'createdAt' | 'updatedAt'>
      & { creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'emailAddress'>
      )>, identifier: (
        { __typename?: 'CollectionIdentifier' }
        & Pick<CollectionIdentifier, 'registryURL' | 'collectionSlug'>
      ), packages?: Maybe<Array<(
        { __typename?: 'Package' }
        & Pick<Package, 'displayName' | 'description' | 'createdAt' | 'updatedAt'>
        & { creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'emailAddress'>
        )>, identifier: (
          { __typename?: 'PackageIdentifier' }
          & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
        ), catalog?: Maybe<(
          { __typename?: 'Catalog' }
          & Pick<Catalog, 'myPermissions' | 'displayName' | 'isPublic' | 'unclaimed'>
          & { identifier: (
            { __typename?: 'CatalogIdentifier' }
            & Pick<CatalogIdentifier, 'catalogSlug'>
          ) }
        )>, latestVersion?: Maybe<(
          { __typename?: 'Version' }
          & Pick<Version, 'createdAt' | 'updatedAt'>
          & { identifier: (
            { __typename?: 'VersionIdentifier' }
            & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
          ) }
        )> }
      )>> }
    )>> }
  ) }
);

export type SearchPackagesQueryVariables = Exact<{
  query: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type SearchPackagesQuery = (
  { __typename?: 'Query' }
  & { searchPackages: (
    { __typename?: 'SearchPackagesResult' }
    & Pick<SearchPackagesResult, 'hasMore' | 'count'>
    & { packages?: Maybe<Array<(
      { __typename?: 'Package' }
      & Pick<Package, 'isPublic' | 'myPermissions' | 'displayName' | 'description' | 'updateMethods' | 'createdAt' | 'updatedAt'>
      & { creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'username'>
      )>, identifier: (
        { __typename?: 'PackageIdentifier' }
        & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
      ), catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'displayName' | 'isPublic' | 'unclaimed'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )>, latestVersion?: Maybe<(
        { __typename?: 'Version' }
        & Pick<Version, 'createdAt' | 'updatedAt'>
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )> }
    )>> }
  ) }
);

export type SearchUsersQueryVariables = Exact<{
  value: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { searchUsers: (
    { __typename?: 'SearchUsersResult' }
    & Pick<SearchUsersResult, 'hasMore' | 'count'>
    & { users: Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'displayName' | 'nameIsPublic'>
    )>> }
  ) }
);

export type SetAsAdminMutationVariables = Exact<{
  username: Scalars['String'];
  isAdmin: Scalars['Boolean'];
}>;


export type SetAsAdminMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setAsAdmin'>
);

export type SetCatalogAvatarImageMutationVariables = Exact<{
  identifier: CatalogIdentifierInput;
  image: Base64ImageUpload;
}>;


export type SetCatalogAvatarImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setCatalogAvatarImage'>
);

export type SetCatalogCoverImageMutationVariables = Exact<{
  identifier: CatalogIdentifierInput;
  image: Base64ImageUpload;
}>;


export type SetCatalogCoverImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setCatalogCoverImage'>
);

export type SetCollectionCoverImageMutationVariables = Exact<{
  identifier: CollectionIdentifierInput;
  image: Base64ImageUpload;
}>;


export type SetCollectionCoverImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setCollectionCoverImage'>
);

export type SetGroupAsAdminMutationVariables = Exact<{
  groupSlug: Scalars['String'];
  isAdmin: Scalars['Boolean'];
}>;


export type SetGroupAsAdminMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setGroupAsAdmin'>
);

export type SetMyAvatarImageMutationVariables = Exact<{
  image: Base64ImageUpload;
}>;


export type SetMyAvatarImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMyAvatarImage'>
);

export type SetMyCoverImageMutationVariables = Exact<{
  image: Base64ImageUpload;
}>;


export type SetMyCoverImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMyCoverImage'>
);

export type SetPackageCoverImageMutationVariables = Exact<{
  identifier: PackageIdentifierInput;
  image: Base64ImageUpload;
}>;


export type SetPackageCoverImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setPackageCoverImage'>
);

export type SetPackagePermissionsMutationVariables = Exact<{
  identifier: PackageIdentifierInput;
  value: Array<SetPackagePermissionInput>;
  message: Scalars['String'];
}>;


export type SetPackagePermissionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setPackagePermissions'>
);

export type SetUserCatalogPermissionMutationVariables = Exact<{
  identifier: CatalogIdentifierInput;
  value: Array<SetUserCatalogPermissionInput>;
  message: Scalars['String'];
}>;


export type SetUserCatalogPermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setUserCatalogPermission'>
);

export type SetUserCollectionPermissionsMutationVariables = Exact<{
  identifier: CollectionIdentifierInput;
  value: Array<SetUserCollectionPermissionsInput>;
  message: Scalars['String'];
}>;


export type SetUserCollectionPermissionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setUserCollectionPermissions'>
);

export type UpdateCatalogMutationVariables = Exact<{
  identifier: CatalogIdentifierInput;
  value: UpdateCatalogInput;
}>;


export type UpdateCatalogMutation = (
  { __typename?: 'Mutation' }
  & { updateCatalog: (
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'displayName' | 'description' | 'myPermissions' | 'website' | 'isPublic' | 'unclaimed'>
    & { identifier: (
      { __typename?: 'CatalogIdentifier' }
      & Pick<CatalogIdentifier, 'catalogSlug'>
    ) }
  ) }
);

export type UpdateCollectionMutationVariables = Exact<{
  identifier: CollectionIdentifierInput;
  value: UpdateCollectionInput;
}>;


export type UpdateCollectionMutation = (
  { __typename?: 'Mutation' }
  & { updateCollection: (
    { __typename?: 'Collection' }
    & Pick<Collection, 'name' | 'description' | 'myPermissions' | 'isPublic' | 'isRecommended'>
    & { identifier: (
      { __typename?: 'CollectionIdentifier' }
      & Pick<CollectionIdentifier, 'collectionSlug'>
    ), packages?: Maybe<Array<(
      { __typename?: 'Package' }
      & Pick<Package, 'displayName' | 'description'>
      & { identifier: (
        { __typename?: 'PackageIdentifier' }
        & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
      ), latestVersion?: Maybe<(
        { __typename?: 'Version' }
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )>, catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'myPermissions' | 'displayName' | 'description'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )> }
    )>> }
  ) }
);

export type UpdateGroupMutationVariables = Exact<{
  groupSlug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup: (
    { __typename?: 'Group' }
    & Pick<Group, 'slug' | 'name' | 'description' | 'myPermissions'>
  ) }
);

export type UpdateMeMutationVariables = Exact<{
  value: UpdateUserInput;
}>;


export type UpdateMeMutation = (
  { __typename?: 'Mutation' }
  & { updateMe: (
    { __typename?: 'CurrentUser' }
    & Pick<CurrentUser, 'isAdmin'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'emailAddress' | 'displayName' | 'firstName' | 'lastName' | 'username' | 'description' | 'location' | 'twitterHandle' | 'gitHubHandle' | 'website' | 'nameIsPublic' | 'locationIsPublic' | 'twitterHandleIsPublic' | 'gitHubHandleIsPublic' | 'emailAddressIsPublic' | 'websiteIsPublic' | 'uiDarkModeEnabled'>
    ) }
  ) }
);

export type UpdateMyPasswordMutationVariables = Exact<{
  value: UpdateMyPasswordInput;
}>;


export type UpdateMyPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMyPassword'>
);

export type UpdatePackageMutationVariables = Exact<{
  identifier: PackageIdentifierInput;
  value: UpdatePackageInput;
}>;


export type UpdatePackageMutation = (
  { __typename?: 'Mutation' }
  & { updatePackage: (
    { __typename?: 'Package' }
    & Pick<Package, 'displayName' | 'description' | 'myPermissions' | 'isPublic'>
    & { identifier: (
      { __typename?: 'PackageIdentifier' }
      & Pick<PackageIdentifier, 'catalogSlug' | 'packageSlug'>
    ), catalog?: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'myPermissions' | 'displayName'>
    )>, latestVersion?: Maybe<(
      { __typename?: 'Version' }
      & Pick<Version, 'packageFile'>
      & { identifier: (
        { __typename?: 'VersionIdentifier' }
        & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
      ) }
    )> }
  ) }
);

export type UpdatePackageIssueMutationVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  issue: UpdatePackageIssueInput;
}>;


export type UpdatePackageIssueMutation = (
  { __typename?: 'Mutation' }
  & { updatePackageIssue?: Maybe<(
    { __typename?: 'PackageIssue' }
    & Pick<PackageIssue, 'issueNumber' | 'subject' | 'content' | 'status' | 'createdAt' | 'updatedAt'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    )> }
  )> }
);

export type UpdatePackageIssueCommentMutationVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  issueCommentIdentifier: PackageIssueCommentIdentifierInput;
  comment: UpdatePackageIssueCommentInput;
}>;


export type UpdatePackageIssueCommentMutation = (
  { __typename?: 'Mutation' }
  & { updatePackageIssueComment?: Maybe<(
    { __typename?: 'PackageIssueComment' }
    & Pick<PackageIssueComment, 'commentNumber' | 'content' | 'createdAt' | 'updatedAt'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    ) }
  )> }
);

export type UpdatePackageIssueStatusMutationVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  issueIdentifier: PackageIssueIdentifierInput;
  status: UpdatePackageIssueStatusInput;
}>;


export type UpdatePackageIssueStatusMutation = (
  { __typename?: 'Mutation' }
  & { updatePackageIssueStatus?: Maybe<(
    { __typename?: 'PackageIssue' }
    & Pick<PackageIssue, 'issueNumber' | 'subject' | 'content' | 'status' | 'createdAt' | 'updatedAt'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    )> }
  )> }
);

export type UpdatePackageIssuesStatusesMutationVariables = Exact<{
  packageIdentifier: PackageIdentifierInput;
  issuesIdentifiers: Array<PackageIssueIdentifierInput>;
  status: UpdatePackageIssueStatusInput;
}>;


export type UpdatePackageIssuesStatusesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePackageIssuesStatuses'>
);

export type UserQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'emailAddress' | 'firstName' | 'lastName' | 'username' | 'displayName' | 'nameIsPublic' | 'location' | 'twitterHandle' | 'gitHubHandle' | 'website' | 'locationIsPublic' | 'twitterHandleIsPublic' | 'gitHubHandleIsPublic' | 'emailAddressIsPublic' | 'websiteIsPublic' | 'description'>
  ) }
);

export type UserCatalogsQueryVariables = Exact<{
  username: Scalars['String'];
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type UserCatalogsQuery = (
  { __typename?: 'Query' }
  & { userCatalogs: (
    { __typename?: 'CatalogsResult' }
    & Pick<CatalogsResult, 'hasMore' | 'count'>
    & { catalogs?: Maybe<Array<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'displayName' | 'description' | 'website' | 'isPublic' | 'unclaimed' | 'myPermissions'>
      & { identifier: (
        { __typename?: 'CatalogIdentifier' }
        & Pick<CatalogIdentifier, 'registryURL' | 'catalogSlug'>
      ) }
    )>> }
  ) }
);

export type UserCollectionsQueryVariables = Exact<{
  username: Scalars['String'];
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type UserCollectionsQuery = (
  { __typename?: 'Query' }
  & { userCollections: (
    { __typename?: 'CollectionsResult' }
    & Pick<CollectionsResult, 'hasMore' | 'count'>
    & { collections?: Maybe<Array<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'name' | 'description' | 'myPermissions' | 'isPublic'>
      & { identifier: (
        { __typename?: 'CollectionIdentifier' }
        & Pick<CollectionIdentifier, 'collectionSlug'>
      ) }
    )>> }
  ) }
);

export type UserFollowersQueryVariables = Exact<{
  username: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type UserFollowersQuery = (
  { __typename?: 'Query' }
  & { userFollowers: (
    { __typename?: 'FollowersResult' }
    & Pick<FollowersResult, 'hasMore' | 'count'>
    & { followers?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'username' | 'displayName'>
    )>> }
  ) }
);

export type UserFollowersCountQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UserFollowersCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userFollowersCount'>
);

export type UserPackagesQueryVariables = Exact<{
  username: Scalars['String'];
  offSet: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type UserPackagesQuery = (
  { __typename?: 'Query' }
  & { userPackages: (
    { __typename?: 'PackagesResult' }
    & Pick<PackagesResult, 'hasMore' | 'count'>
    & { packages?: Maybe<Array<(
      { __typename?: 'Package' }
      & Pick<Package, 'myPermissions' | 'isPublic' | 'displayName' | 'description' | 'updateMethods'>
      & { identifier: (
        { __typename?: 'PackageIdentifier' }
        & Pick<PackageIdentifier, 'registryURL' | 'catalogSlug' | 'packageSlug'>
      ), catalog?: Maybe<(
        { __typename?: 'Catalog' }
        & Pick<Catalog, 'myPermissions' | 'displayName' | 'isPublic' | 'unclaimed'>
        & { identifier: (
          { __typename?: 'CatalogIdentifier' }
          & Pick<CatalogIdentifier, 'catalogSlug'>
        ) }
      )>, latestVersion?: Maybe<(
        { __typename?: 'Version' }
        & Pick<Version, 'createdAt'>
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username' | 'firstName' | 'lastName'>
      )>, versions?: Maybe<Array<Maybe<(
        { __typename?: 'Version' }
        & Pick<Version, 'createdAt'>
        & { identifier: (
          { __typename?: 'VersionIdentifier' }
          & Pick<VersionIdentifier, 'versionMajor' | 'versionMinor' | 'versionPatch'>
        ) }
      )>>> }
    )>> }
  ) }
);

export type UsernameAvailableQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UsernameAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'usernameAvailable'>
);

export type UsersByCatalogQueryVariables = Exact<{
  identifier: CatalogIdentifierInput;
}>;


export type UsersByCatalogQuery = (
  { __typename?: 'Query' }
  & { usersByCatalog: Array<(
    { __typename?: 'UserCatalogPermissions' }
    & Pick<UserCatalogPermissions, 'permissions' | 'packagePermissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic' | 'displayName'>
    ) }
  )> }
);

export type UsersByCollectionQueryVariables = Exact<{
  identifier: CollectionIdentifierInput;
}>;


export type UsersByCollectionQuery = (
  { __typename?: 'Query' }
  & { usersByCollection: Array<(
    { __typename?: 'UserCollectionPermissions' }
    & Pick<UserCollectionPermissions, 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic' | 'displayName'>
    ) }
  )> }
);

export type UsersByPackageQueryVariables = Exact<{
  identifier: PackageIdentifierInput;
}>;


export type UsersByPackageQuery = (
  { __typename?: 'Query' }
  & { usersByPackage: Array<(
    { __typename?: 'UserPackagePermissions' }
    & Pick<UserPackagePermissions, 'permissions'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'username' | 'firstName' | 'lastName' | 'nameIsPublic' | 'displayName'>
    )> }
  )> }
);

export type VerifyEmailAddressMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyEmailAddressMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyEmailAddress'>
);

export const AcceptInviteDocument = gql`
    mutation AcceptInvite($token: String!, $username: String!, $password: String!) {
  acceptInvite(token: $token, username: $username, password: $password)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptInviteGQL extends Apollo.Mutation<AcceptInviteMutation, AcceptInviteMutationVariables> {
    document = AcceptInviteDocument;
    
  }
export const AddOrUpdateGroupToCatalogDocument = gql`
    mutation AddOrUpdateGroupToCatalog($groupSlug: String!, $catalogIdentifier: CatalogIdentifierInput!, $permissions: [Permission!]!, $packagePermissions: [Permission!]!) {
  addOrUpdateGroupToCatalog(groupSlug: $groupSlug, catalogIdentifier: $catalogIdentifier, permissions: $permissions, packagePermissions: $packagePermissions) {
    group {
      slug
      name
    }
    catalog {
      identifier {
        registryURL
        catalogSlug
      }
      displayName
      description
      website
      myPermissions
      isPublic
      unclaimed
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddOrUpdateGroupToCatalogGQL extends Apollo.Mutation<AddOrUpdateGroupToCatalogMutation, AddOrUpdateGroupToCatalogMutationVariables> {
    document = AddOrUpdateGroupToCatalogDocument;
    
  }
export const AddOrUpdateGroupToCollectionDocument = gql`
    mutation AddOrUpdateGroupToCollection($groupSlug: String!, $collectionIdentifier: CollectionIdentifierInput!, $permissions: [Permission!]!) {
  addOrUpdateGroupToCollection(groupSlug: $groupSlug, collectionIdentifier: $collectionIdentifier, permissions: $permissions) {
    group {
      slug
      name
    }
    collection {
      identifier {
        registryURL
        collectionSlug
      }
      name
      description
      myPermissions
      isPublic
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddOrUpdateGroupToCollectionGQL extends Apollo.Mutation<AddOrUpdateGroupToCollectionMutation, AddOrUpdateGroupToCollectionMutationVariables> {
    document = AddOrUpdateGroupToCollectionDocument;
    
  }
export const AddOrUpdateGroupToPackageDocument = gql`
    mutation AddOrUpdateGroupToPackage($groupSlug: String!, $packageIdentifier: PackageIdentifierInput!, $permissions: [Permission!]!) {
  addOrUpdateGroupToPackage(groupSlug: $groupSlug, packageIdentifier: $packageIdentifier, permissions: $permissions) {
    group {
      slug
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddOrUpdateGroupToPackageGQL extends Apollo.Mutation<AddOrUpdateGroupToPackageMutation, AddOrUpdateGroupToPackageMutationVariables> {
    document = AddOrUpdateGroupToPackageDocument;
    
  }
export const AddOrUpdateUserToGroupDocument = gql`
    mutation AddOrUpdateUserToGroup($groupSlug: String!, $userPermissions: [SetUserGroupPermissionsInput!]!) {
  addOrUpdateUserToGroup(groupSlug: $groupSlug, userPermissions: $userPermissions)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddOrUpdateUserToGroupGQL extends Apollo.Mutation<AddOrUpdateUserToGroupMutation, AddOrUpdateUserToGroupMutationVariables> {
    document = AddOrUpdateUserToGroupDocument;
    
  }
export const AddPackageToCollectionDocument = gql`
    mutation AddPackageToCollection($collectionIdentifier: CollectionIdentifierInput!, $packageIdentifier: PackageIdentifierInput!) {
  addPackageToCollection(collectionIdentifier: $collectionIdentifier, packageIdentifier: $packageIdentifier) {
    collection {
      identifier {
        collectionSlug
      }
      name
      description
    }
    package {
      identifier {
        catalogSlug
        packageSlug
      }
      displayName
      description
      latestVersion {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddPackageToCollectionGQL extends Apollo.Mutation<AddPackageToCollectionMutation, AddPackageToCollectionMutationVariables> {
    document = AddPackageToCollectionDocument;
    
  }
export const AdminDeleteGroupDocument = gql`
    mutation AdminDeleteGroup($groupSlug: String!) {
  adminDeleteGroup(groupSlug: $groupSlug)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminDeleteGroupGQL extends Apollo.Mutation<AdminDeleteGroupMutation, AdminDeleteGroupMutationVariables> {
    document = AdminDeleteGroupDocument;
    
  }
export const AdminDeleteUserDocument = gql`
    mutation AdminDeleteUser($usernameOrEmailAddress: String!) {
  adminDeleteUser(usernameOrEmailAddress: $usernameOrEmailAddress)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminDeleteUserGQL extends Apollo.Mutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables> {
    document = AdminDeleteUserDocument;
    
  }
export const AdminSearchGroupsDocument = gql`
    query AdminSearchGroups($value: String!, $limit: Int!, $offset: Int!) {
  adminSearchGroups(value: $value, limit: $limit, offSet: $offset) {
    groups {
      slug
      name
      description
      isAdmin
      createdAt
      updatedAt
      creator {
        username
        firstName
        lastName
        emailAddress
        displayName
        status
        isAdmin
      }
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminSearchGroupsGQL extends Apollo.Query<AdminSearchGroupsQuery, AdminSearchGroupsQueryVariables> {
    document = AdminSearchGroupsDocument;
    
  }
export const AdminSearchUsersDocument = gql`
    query AdminSearchUsers($value: String!, $limit: Int!, $offset: Int!) {
  adminSearchUsers(value: $value, limit: $limit, offSet: $offset) {
    users {
      username
      firstName
      lastName
      emailAddress
      displayName
      status
      isAdmin
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminSearchUsersGQL extends Apollo.Query<AdminSearchUsersQuery, AdminSearchUsersQueryVariables> {
    document = AdminSearchUsersDocument;
    
  }
export const AdminSetUserStatusDocument = gql`
    mutation AdminSetUserStatus($username: String!, $status: UserStatus!, $message: String) {
  adminSetUserStatus(username: $username, status: $status, message: $message)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminSetUserStatusGQL extends Apollo.Mutation<AdminSetUserStatusMutation, AdminSetUserStatusMutationVariables> {
    document = AdminSetUserStatusDocument;
    
  }
export const AutoCompleteDocument = gql`
    query AutoComplete($startsWith: String!) {
  autoComplete(startsWith: $startsWith) {
    catalogs {
      identifier {
        registryURL
        catalogSlug
      }
      displayName
      description
      isPublic
    }
    packages {
      catalog {
        identifier {
          catalogSlug
        }
        displayName
        isPublic
        unclaimed
      }
      creator {
        username
        firstName
        lastName
        displayName
        nameIsPublic
      }
      updateMethods
      updatedAt
      identifier {
        registryURL
        catalogSlug
        packageSlug
      }
      displayName
      description
      latestVersion {
        identifier {
          catalogSlug
          packageSlug
          versionMajor
          versionMinor
          versionPatch
        }
      }
    }
    collections {
      identifier {
        collectionSlug
      }
      name
      description
    }
    users {
      firstName
      lastName
      username
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoCompleteGQL extends Apollo.Query<AutoCompleteQuery, AutoCompleteQueryVariables> {
    document = AutoCompleteDocument;
    
  }
export const AutoCompleteUserDocument = gql`
    query AutoCompleteUser($startsWith: String!) {
  autoComplete(startsWith: $startsWith) {
    users {
      username
      firstName
      lastName
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoCompleteUserGQL extends Apollo.Query<AutoCompleteUserQuery, AutoCompleteUserQueryVariables> {
    document = AutoCompleteUserDocument;
    
  }
export const AutoCompletePackageDocument = gql`
    query AutoCompletePackage($startsWith: String!) {
  autoComplete(startsWith: $startsWith) {
    packages {
      updatedAt
      identifier {
        registryURL
        catalogSlug
        packageSlug
      }
      catalog {
        identifier {
          catalogSlug
        }
        displayName
        isPublic
        unclaimed
      }
      creator {
        username
        firstName
        lastName
        nameIsPublic
      }
      displayName
      description
      latestVersion {
        identifier {
          catalogSlug
          packageSlug
          versionMajor
          versionMinor
          versionPatch
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoCompletePackageGQL extends Apollo.Query<AutoCompletePackageQuery, AutoCompletePackageQueryVariables> {
    document = AutoCompletePackageDocument;
    
  }
export const AutoCompleteCollectionDocument = gql`
    query AutoCompleteCollection($startsWith: String!) {
  autoComplete(startsWith: $startsWith) {
    collections {
      identifier {
        collectionSlug
      }
      name
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoCompleteCollectionGQL extends Apollo.Query<AutoCompleteCollectionQuery, AutoCompleteCollectionQueryVariables> {
    document = AutoCompleteCollectionDocument;
    
  }
export const AutoCompleteCatalogDocument = gql`
    query AutoCompleteCatalog($startsWith: String!) {
  autoComplete(startsWith: $startsWith) {
    catalogs {
      identifier {
        registryURL
        catalogSlug
      }
      displayName
      description
      isPublic
      myPermissions
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoCompleteCatalogGQL extends Apollo.Query<AutoCompleteCatalogQuery, AutoCompleteCatalogQueryVariables> {
    document = AutoCompleteCatalogDocument;
    
  }
export const GetCatalogDocument = gql`
    query GetCatalog($identifier: CatalogIdentifierInput!) {
  catalog(identifier: $identifier) {
    identifier {
      registryURL
      catalogSlug
    }
    displayName
    description
    website
    myPermissions
    isPublic
    unclaimed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCatalogGQL extends Apollo.Query<GetCatalogQuery, GetCatalogQueryVariables> {
    document = GetCatalogDocument;
    
  }
export const CatalogActivitiesDocument = gql`
    query CatalogActivities($identifier: CatalogIdentifierInput!, $filter: ActivityLogFilterInput!) {
  catalogActivities(identifier: $identifier, filter: $filter) {
    logs {
      user {
        emailAddress
        username
        firstName
        lastName
        displayName
      }
      targetPackage {
        creator {
          username
          emailAddress
        }
        identifier {
          catalogSlug
          packageSlug
        }
      }
      targetPackageVersion {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
      }
      targetCollection {
        identifier {
          collectionSlug
        }
        name
      }
      targetCatalog {
        identifier {
          catalogSlug
        }
        displayName
      }
      eventType
      changeType
      createdAt
      updatedAt
    }
    count
    hasMore
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CatalogActivitiesGQL extends Apollo.Query<CatalogActivitiesQuery, CatalogActivitiesQueryVariables> {
    document = CatalogActivitiesDocument;
    
  }
export const CatalogFollowersDocument = gql`
    query CatalogFollowers($identifier: CatalogIdentifierInput!, $limit: Int!, $offset: Int!) {
  catalogFollowers(identifier: $identifier, limit: $limit, offset: $offset) {
    followers {
      firstName
      lastName
      username
      displayName
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CatalogFollowersGQL extends Apollo.Query<CatalogFollowersQuery, CatalogFollowersQueryVariables> {
    document = CatalogFollowersDocument;
    
  }
export const CatalogFollowersCountDocument = gql`
    query CatalogFollowersCount($identifier: CatalogIdentifierInput!) {
  catalogFollowersCount(identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CatalogFollowersCountGQL extends Apollo.Query<CatalogFollowersCountQuery, CatalogFollowersCountQueryVariables> {
    document = CatalogFollowersCountDocument;
    
  }
export const CatalogPackagesDocument = gql`
    query CatalogPackages($identifier: CatalogIdentifierInput!, $offset: Int!, $limit: Int!) {
  catalogPackages(identifier: $identifier, offset: $offset, limit: $limit) {
    displayName
    description
    createdAt
    updatedAt
    isPublic
    myPermissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CatalogPackagesGQL extends Apollo.Query<CatalogPackagesQuery, CatalogPackagesQueryVariables> {
    document = CatalogPackagesDocument;
    
  }
export const CollectionDocument = gql`
    query Collection($identifier: CollectionIdentifierInput!) {
  collection(identifier: $identifier) {
    name
    description
    creator {
      username
    }
    identifier {
      collectionSlug
    }
    packages {
      identifier {
        catalogSlug
        packageSlug
      }
      catalog {
        identifier {
          catalogSlug
        }
        displayName
        isPublic
        unclaimed
        myPermissions
        description
      }
      displayName
      description
      isPublic
      myPermissions
      updateMethods
      latestVersion {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
      }
      creator {
        username
      }
      catalog {
        identifier {
          catalogSlug
        }
        myPermissions
        displayName
        description
      }
    }
    isPublic
    isRecommended
    myPermissions
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionGQL extends Apollo.Query<CollectionQuery, CollectionQueryVariables> {
    document = CollectionDocument;
    
  }
export const CollectionActivitiesDocument = gql`
    query CollectionActivities($identifier: CollectionIdentifierInput!, $filter: ActivityLogFilterInput!) {
  collectionActivities(identifier: $identifier, filter: $filter) {
    logs {
      user {
        emailAddress
        username
        firstName
        lastName
        displayName
      }
      targetPackage {
        creator {
          username
          emailAddress
        }
        identifier {
          catalogSlug
          packageSlug
        }
      }
      targetPackageVersion {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
      }
      targetCollection {
        identifier {
          collectionSlug
        }
        name
      }
      targetCatalog {
        identifier {
          catalogSlug
        }
        displayName
      }
      eventType
      changeType
      createdAt
      updatedAt
    }
    count
    hasMore
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionActivitiesGQL extends Apollo.Query<CollectionActivitiesQuery, CollectionActivitiesQueryVariables> {
    document = CollectionActivitiesDocument;
    
  }
export const CollectionFollowersDocument = gql`
    query CollectionFollowers($identifier: CollectionIdentifierInput!, $limit: Int!, $offset: Int!) {
  collectionFollowers(identifier: $identifier, limit: $limit, offset: $offset) {
    followers {
      firstName
      lastName
      username
      displayName
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionFollowersGQL extends Apollo.Query<CollectionFollowersQuery, CollectionFollowersQueryVariables> {
    document = CollectionFollowersDocument;
    
  }
export const CollectionFollowersCountDocument = gql`
    query CollectionFollowersCount($identifier: CollectionIdentifierInput!) {
  collectionFollowersCount(identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionFollowersCountGQL extends Apollo.Query<CollectionFollowersCountQuery, CollectionFollowersCountQueryVariables> {
    document = CollectionFollowersCountDocument;
    
  }
export const CollectionPackagesDocument = gql`
    query CollectionPackages($identifier: CollectionIdentifierInput!, $offset: Int!, $limit: Int!) {
  collectionPackages(identifier: $identifier, offset: $offset, limit: $limit) {
    identifier {
      catalogSlug
      packageSlug
    }
    catalog {
      displayName
      isPublic
      myPermissions
    }
    latestVersion {
      createdAt
      updatedAt
      identifier {
        versionMajor
        versionMinor
        versionPatch
      }
    }
    creator {
      username
      firstName
      lastName
      nameIsPublic
    }
    isPublic
    myPermissions
    updateMethods
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionPackagesGQL extends Apollo.Query<CollectionPackagesQuery, CollectionPackagesQueryVariables> {
    document = CollectionPackagesDocument;
    
  }
export const CollectionSlugAvailableDocument = gql`
    query CollectionSlugAvailable($collectionSlug: String!) {
  collectionSlugAvailable(collectionSlug: $collectionSlug)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CollectionSlugAvailableGQL extends Apollo.Query<CollectionSlugAvailableQuery, CollectionSlugAvailableQueryVariables> {
    document = CollectionSlugAvailableDocument;
    
  }
export const CreateAPIKeyDocument = gql`
    mutation CreateAPIKey($value: CreateAPIKeyInput!) {
  createAPIKey(value: $value) {
    secret
    label
    scopes
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAPIKeyGQL extends Apollo.Mutation<CreateAPIKeyMutation, CreateAPIKeyMutationVariables> {
    document = CreateAPIKeyDocument;
    
  }
export const CreateCatalogDocument = gql`
    mutation CreateCatalog($value: CreateCatalogInput!) {
  createCatalog(value: $value) {
    identifier {
      catalogSlug
    }
    myPermissions
    displayName
    description
    website
    isPublic
    unclaimed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCatalogGQL extends Apollo.Mutation<CreateCatalogMutation, CreateCatalogMutationVariables> {
    document = CreateCatalogDocument;
    
  }
export const CreateCollectionDocument = gql`
    mutation CreateCollection($value: CreateCollectionInput!) {
  createCollection(value: $value) {
    creator {
      displayName
      username
    }
    identifier {
      collectionSlug
    }
    name
    description
    packages {
      identifier {
        registryURL
        catalogSlug
        packageSlug
      }
      displayName
      description
      latestVersion {
        identifier {
          catalogSlug
          packageSlug
          versionMajor
          versionMinor
          versionPatch
        }
      }
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCollectionGQL extends Apollo.Mutation<CreateCollectionMutation, CreateCollectionMutationVariables> {
    document = CreateCollectionDocument;
    
  }
export const CreateCredentialDocument = gql`
    mutation CreateCredential($identifier: PackageIdentifierInput!, $connectorType: String!, $repositoryIdentifier: String!, $credentialIdentifier: String!, $credential: JSON!) {
  createCredential(identifier: $identifier, connectorType: $connectorType, repositoryIdentifier: $repositoryIdentifier, credentialIdentifier: $credentialIdentifier, credential: $credential) {
    credentialIdentifier
    createdAt
    updatedAt
    creator {
      username
      firstName
      lastName
      nameIsPublic
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCredentialGQL extends Apollo.Mutation<CreateCredentialMutation, CreateCredentialMutationVariables> {
    document = CreateCredentialDocument;
    
  }
export const SaveFollowDocument = gql`
    mutation SaveFollow($follow: SaveFollowInput!) {
  saveFollow(follow: $follow)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveFollowGQL extends Apollo.Mutation<SaveFollowMutation, SaveFollowMutationVariables> {
    document = SaveFollowDocument;
    
  }
export const CreateGroupDocument = gql`
    mutation CreateGroup($groupSlug: String!, $name: String!, $description: String!) {
  createGroup(groupSlug: $groupSlug, name: $name, description: $description) {
    slug
    name
    description
    createdAt
    creator {
      username
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGroupGQL extends Apollo.Mutation<CreateGroupMutation, CreateGroupMutationVariables> {
    document = CreateGroupDocument;
    
  }
export const CreateMeDocument = gql`
    mutation CreateMe($value: CreateUserInput!) {
  createMe(value: $value)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMeGQL extends Apollo.Mutation<CreateMeMutation, CreateMeMutationVariables> {
    document = CreateMeDocument;
    
  }
export const CreatePackageDocument = gql`
    mutation CreatePackage($value: CreatePackageInput!) {
  createPackage(value: $value) {
    identifier {
      catalogSlug
      packageSlug
    }
    catalog {
      myPermissions
      displayName
    }
    displayName
    description
    latestVersion {
      identifier {
        versionMajor
        versionMinor
        versionPatch
      }
      packageFile
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePackageGQL extends Apollo.Mutation<CreatePackageMutation, CreatePackageMutationVariables> {
    document = CreatePackageDocument;
    
  }
export const CreatePackageIssueDocument = gql`
    mutation CreatePackageIssue($packageIdentifier: PackageIdentifierInput!, $issue: CreatePackageIssueInput!) {
  createPackageIssue(packageIdentifier: $packageIdentifier, issue: $issue) {
    issueNumber
    subject
    content
    status
    author {
      firstName
      lastName
      username
      displayName
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePackageIssueGQL extends Apollo.Mutation<CreatePackageIssueMutation, CreatePackageIssueMutationVariables> {
    document = CreatePackageIssueDocument;
    
  }
export const CreatePackageIssueCommentDocument = gql`
    mutation CreatePackageIssueComment($packageIdentifier: PackageIdentifierInput!, $issueIdentifier: PackageIssueIdentifierInput!, $comment: CreatePackageIssueCommentInput!) {
  createPackageIssueComment(packageIdentifier: $packageIdentifier, issueIdentifier: $issueIdentifier, comment: $comment) {
    commentNumber
    content
    author {
      firstName
      lastName
      username
      displayName
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePackageIssueCommentGQL extends Apollo.Mutation<CreatePackageIssueCommentMutation, CreatePackageIssueCommentMutationVariables> {
    document = CreatePackageIssueCommentDocument;
    
  }
export const CreateRepositoryDocument = gql`
    mutation CreateRepository($identifier: PackageIdentifierInput!, $connectorType: String!, $repositoryIdentifier: String!, $connectionConfiguration: JSON!) {
  createRepository(identifier: $identifier, connectorType: $connectorType, repositoryIdentifier: $repositoryIdentifier, connectionConfiguration: $connectionConfiguration) {
    connectorType
    repositoryIdentifier
    createdAt
    updatedAt
    creator {
      username
      firstName
      lastName
      nameIsPublic
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRepositoryGQL extends Apollo.Mutation<CreateRepositoryMutation, CreateRepositoryMutationVariables> {
    document = CreateRepositoryDocument;
    
  }
export const CreateVersionDocument = gql`
    mutation CreateVersion($identifier: PackageIdentifierInput!, $value: CreateVersionInput!) {
  createVersion(identifier: $identifier, value: $value) {
    identifier {
      catalogSlug
      packageSlug
      versionMajor
      versionMinor
      versionPatch
    }
    createdAt
    updatedAt
    package {
      displayName
      description
      catalog {
        displayName
        description
      }
    }
    author {
      username
      firstName
      lastName
      displayName
      emailAddress
      twitterHandle
      gitHubHandle
    }
    packageFile
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateVersionGQL extends Apollo.Mutation<CreateVersionMutation, CreateVersionMutationVariables> {
    document = CreateVersionDocument;
    
  }
export const DeleteAPIKeyDocument = gql`
    mutation DeleteAPIKey($id: String!) {
  deleteAPIKey(id: $id) {
    label
    scopes
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAPIKeyGQL extends Apollo.Mutation<DeleteAPIKeyMutation, DeleteAPIKeyMutationVariables> {
    document = DeleteAPIKeyDocument;
    
  }
export const DeleteAllMyFollowsDocument = gql`
    mutation DeleteAllMyFollows {
  deleteAllMyFollows
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAllMyFollowsGQL extends Apollo.Mutation<DeleteAllMyFollowsMutation, DeleteAllMyFollowsMutationVariables> {
    document = DeleteAllMyFollowsDocument;
    
  }
export const DeleteCatalogDocument = gql`
    mutation DeleteCatalog($identifier: CatalogIdentifierInput!) {
  deleteCatalog(identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCatalogGQL extends Apollo.Mutation<DeleteCatalogMutation, DeleteCatalogMutationVariables> {
    document = DeleteCatalogDocument;
    
  }
export const DeleteCatalogAvatarImageDocument = gql`
    mutation DeleteCatalogAvatarImage($identifier: CatalogIdentifierInput!) {
  deleteCatalogAvatarImage(identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCatalogAvatarImageGQL extends Apollo.Mutation<DeleteCatalogAvatarImageMutation, DeleteCatalogAvatarImageMutationVariables> {
    document = DeleteCatalogAvatarImageDocument;
    
  }
export const DeleteCollectionDocument = gql`
    mutation DeleteCollection($identifier: CollectionIdentifierInput!) {
  deleteCollection(identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCollectionGQL extends Apollo.Mutation<DeleteCollectionMutation, DeleteCollectionMutationVariables> {
    document = DeleteCollectionDocument;
    
  }
export const DeleteCredentialDocument = gql`
    mutation DeleteCredential($identifier: PackageIdentifierInput!, $connectorType: String!, $repositoryIdentifier: String!, $credentialIdentifier: String!) {
  deleteCredential(identifier: $identifier, connectorType: $connectorType, repositoryIdentifier: $repositoryIdentifier, credentialIdentifier: $credentialIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCredentialGQL extends Apollo.Mutation<DeleteCredentialMutation, DeleteCredentialMutationVariables> {
    document = DeleteCredentialDocument;
    
  }
export const DeleteFollowDocument = gql`
    mutation DeleteFollow($follow: FollowIdentifierInput!) {
  deleteFollow(follow: $follow)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFollowGQL extends Apollo.Mutation<DeleteFollowMutation, DeleteFollowMutationVariables> {
    document = DeleteFollowDocument;
    
  }
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($groupSlug: String!) {
  deleteGroup(groupSlug: $groupSlug)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGroupGQL extends Apollo.Mutation<DeleteGroupMutation, DeleteGroupMutationVariables> {
    document = DeleteGroupDocument;
    
  }
export const DeleteMeDocument = gql`
    mutation DeleteMe {
  deleteMe
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMeGQL extends Apollo.Mutation<DeleteMeMutation, DeleteMeMutationVariables> {
    document = DeleteMeDocument;
    
  }
export const DeletePackageDocument = gql`
    mutation DeletePackage($identifier: PackageIdentifierInput!) {
  deletePackage(identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePackageGQL extends Apollo.Mutation<DeletePackageMutation, DeletePackageMutationVariables> {
    document = DeletePackageDocument;
    
  }
export const DeletePackageIssueDocument = gql`
    mutation DeletePackageIssue($packageIdentifier: PackageIdentifierInput!, $packageIssueIdentifier: PackageIssueIdentifierInput!) {
  deletePackageIssue(packageIdentifier: $packageIdentifier, packageIssueIdentifier: $packageIssueIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePackageIssueGQL extends Apollo.Mutation<DeletePackageIssueMutation, DeletePackageIssueMutationVariables> {
    document = DeletePackageIssueDocument;
    
  }
export const DeletePackageIssueCommentDocument = gql`
    mutation DeletePackageIssueComment($packageIdentifier: PackageIdentifierInput!, $issueIdentifier: PackageIssueIdentifierInput!, $issueCommentIdentifier: PackageIssueCommentIdentifierInput!) {
  deletePackageIssueComment(packageIdentifier: $packageIdentifier, issueIdentifier: $issueIdentifier, issueCommentIdentifier: $issueCommentIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePackageIssueCommentGQL extends Apollo.Mutation<DeletePackageIssueCommentMutation, DeletePackageIssueCommentMutationVariables> {
    document = DeletePackageIssueCommentDocument;
    
  }
export const DeletePackageIssuesDocument = gql`
    mutation DeletePackageIssues($packageIdentifier: PackageIdentifierInput!, $issuesIdentifiers: [PackageIssueIdentifierInput!]!) {
  deletePackageIssues(packageIdentifier: $packageIdentifier, issuesIdentifiers: $issuesIdentifiers)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePackageIssuesGQL extends Apollo.Mutation<DeletePackageIssuesMutation, DeletePackageIssuesMutationVariables> {
    document = DeletePackageIssuesDocument;
    
  }
export const DeleteRepositoryDocument = gql`
    mutation DeleteRepository($identifier: PackageIdentifierInput!, $connectorType: String!, $repositoryIdentifier: String!) {
  deleteRepository(identifier: $identifier, connectorType: $connectorType, repositoryIdentifier: $repositoryIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRepositoryGQL extends Apollo.Mutation<DeleteRepositoryMutation, DeleteRepositoryMutationVariables> {
    document = DeleteRepositoryDocument;
    
  }
export const DeleteUserCatalogPermissionsDocument = gql`
    mutation DeleteUserCatalogPermissions($usernameOrEmailAddress: String!, $identifier: CatalogIdentifierInput!) {
  deleteUserCatalogPermissions(usernameOrEmailAddress: $usernameOrEmailAddress, identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserCatalogPermissionsGQL extends Apollo.Mutation<DeleteUserCatalogPermissionsMutation, DeleteUserCatalogPermissionsMutationVariables> {
    document = DeleteUserCatalogPermissionsDocument;
    
  }
export const DeleteUserCollectionPermissionsDocument = gql`
    mutation DeleteUserCollectionPermissions($identifier: CollectionIdentifierInput!, $usernameOrEmailAddress: String!) {
  deleteUserCollectionPermissions(identifier: $identifier, usernameOrEmailAddress: $usernameOrEmailAddress)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserCollectionPermissionsGQL extends Apollo.Mutation<DeleteUserCollectionPermissionsMutation, DeleteUserCollectionPermissionsMutationVariables> {
    document = DeleteUserCollectionPermissionsDocument;
    
  }
export const DeleteVersionDocument = gql`
    mutation DeleteVersion($identifier: VersionIdentifierInput!) {
  deleteVersion(identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteVersionGQL extends Apollo.Mutation<DeleteVersionMutation, DeleteVersionMutationVariables> {
    document = DeleteVersionDocument;
    
  }
export const EmailAddressAvailableDocument = gql`
    query EmailAddressAvailable($emailAddress: String!) {
  emailAddressAvailable(emailAddress: $emailAddress)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EmailAddressAvailableGQL extends Apollo.Query<EmailAddressAvailableQuery, EmailAddressAvailableQueryVariables> {
    document = EmailAddressAvailableDocument;
    
  }
export const ForgotMyPasswordDocument = gql`
    mutation ForgotMyPassword($emailAddress: String!) {
  forgotMyPassword(emailAddress: $emailAddress)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotMyPasswordGQL extends Apollo.Mutation<ForgotMyPasswordMutation, ForgotMyPasswordMutationVariables> {
    document = ForgotMyPasswordDocument;
    
  }
export const GetFollowDocument = gql`
    query GetFollow($follow: FollowIdentifierInput!) {
  getFollow(follow: $follow) {
    notificationFrequency
    eventTypes
    changeType
    followAllPackages
    followAllPackageIssues
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFollowGQL extends Apollo.Query<GetFollowQuery, GetFollowQueryVariables> {
    document = GetFollowDocument;
    
  }
export const GroupDocument = gql`
    query Group($groupSlug: String!) {
  group(groupSlug: $groupSlug) {
    slug
    name
    description
    myPermissions
    packagePermissions {
      permissions
      package {
        identifier {
          registryURL
          catalogSlug
          packageSlug
        }
        catalog {
          identifier {
            catalogSlug
          }
          displayName
          myPermissions
          isPublic
          unclaimed
        }
        creator {
          username
          firstName
          lastName
          nameIsPublic
        }
        isPublic
        myPermissions
        displayName
        description
      }
    }
    collectionPermissions {
      permissions
      collection {
        identifier {
          collectionSlug
        }
        name
        description
        isPublic
        isRecommended
        myPermissions
      }
    }
    catalogPermissions {
      permissions
      packagePermissions
      catalog {
        identifier {
          registryURL
          catalogSlug
        }
        displayName
        description
        website
        myPermissions
        isPublic
        unclaimed
      }
    }
    users {
      user {
        username
        firstName
        lastName
        displayName
        nameIsPublic
      }
      permissions
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GroupGQL extends Apollo.Query<GroupQuery, GroupQueryVariables> {
    document = GroupDocument;
    
  }
export const GroupsByCatalogDocument = gql`
    query GroupsByCatalog($catalogIdentifier: CatalogIdentifierInput!) {
  groupsByCatalog(catalogIdentifier: $catalogIdentifier) {
    group {
      name
      description
      slug
      users {
        user {
          username
          firstName
          lastName
          nameIsPublic
          displayName
        }
        permissions
      }
    }
    permissions
    packagePermissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GroupsByCatalogGQL extends Apollo.Query<GroupsByCatalogQuery, GroupsByCatalogQueryVariables> {
    document = GroupsByCatalogDocument;
    
  }
export const GroupsByCollectionDocument = gql`
    query GroupsByCollection($collectionIdentifier: CollectionIdentifierInput!) {
  groupsByCollection(collectionIdentifier: $collectionIdentifier) {
    group {
      name
      slug
      description
      users {
        user {
          username
          firstName
          lastName
          nameIsPublic
          displayName
        }
        permissions
      }
    }
    permissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GroupsByCollectionGQL extends Apollo.Query<GroupsByCollectionQuery, GroupsByCollectionQueryVariables> {
    document = GroupsByCollectionDocument;
    
  }
export const GroupsByPackageDocument = gql`
    query GroupsByPackage($packageIdentifier: PackageIdentifierInput!) {
  groupsByPackage(packageIdentifier: $packageIdentifier) {
    group {
      name
      slug
      description
      users {
        user {
          username
          firstName
          lastName
          nameIsPublic
          displayName
        }
        permissions
      }
    }
    permissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GroupsByPackageGQL extends Apollo.Query<GroupsByPackageQuery, GroupsByPackageQueryVariables> {
    document = GroupsByPackageDocument;
    
  }
export const GetLatestCollectionsDocument = gql`
    query GetLatestCollections($offset: Int!, $limit: Int!) {
  latestCollections(offset: $offset, limit: $limit) {
    collections {
      identifier {
        collectionSlug
      }
      name
      description
      isPublic
      isRecommended
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLatestCollectionsGQL extends Apollo.Query<GetLatestCollectionsQuery, GetLatestCollectionsQueryVariables> {
    document = GetLatestCollectionsDocument;
    
  }
export const GetLatestPackagesDocument = gql`
    query GetLatestPackages($offset: Int!, $limit: Int!) {
  latestPackages(offSet: $offset, limit: $limit) {
    packages {
      identifier {
        catalogSlug
        packageSlug
      }
      updateMethods
      myPermissions
      isPublic
      displayName
      description
      creator {
        username
        firstName
        lastName
        nameIsPublic
      }
      catalog {
        identifier {
          catalogSlug
        }
        displayName
        isPublic
        unclaimed
      }
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLatestPackagesGQL extends Apollo.Query<GetLatestPackagesQuery, GetLatestPackagesQueryVariables> {
    document = GetLatestPackagesDocument;
    
  }
export const ListConnectorsDocument = gql`
    query ListConnectors {
  listConnectors {
    connectorType
    displayName
    hasSource
    hasSink
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListConnectorsGQL extends Apollo.Query<ListConnectorsQuery, ListConnectorsQueryVariables> {
    document = ListConnectorsDocument;
    
  }
export const ListRepositoriesDocument = gql`
    query ListRepositories($identifier: PackageIdentifierInput!, $limit: Int!, $offset: Int!) {
  listRepositories(identifier: $identifier, limit: $limit, offset: $offset) {
    repositories {
      repositoryIdentifier
      connectorType
      createdAt
      updatedAt
      package {
        identifier {
          catalogSlug
          packageSlug
        }
        catalog {
          identifier {
            catalogSlug
          }
        }
      }
      credentials {
        credentialIdentifier
        createdAt
        updatedAt
        creator {
          username
          firstName
          lastName
          nameIsPublic
        }
      }
      creator {
        username
        firstName
        lastName
        nameIsPublic
      }
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListRepositoriesGQL extends Apollo.Query<ListRepositoriesQuery, ListRepositoriesQueryVariables> {
    document = ListRepositoriesDocument;
    
  }
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
  }
export const LogoutDocument = gql`
    mutation Logout($username: String!, $password: String!) {
  logout
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LogoutGQL extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
    document = LogoutDocument;
    
  }
export const MeDocument = gql`
    query Me {
  me {
    isAdmin
    user {
      emailAddress
      displayName
      firstName
      lastName
      username
      description
      location
      twitterHandle
      gitHubHandle
      website
      nameIsPublic
      locationIsPublic
      twitterHandleIsPublic
      gitHubHandleIsPublic
      emailAddressIsPublic
      websiteIsPublic
      uiDarkModeEnabled
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    document = MeDocument;
    
  }
export const MovePackageDocument = gql`
    mutation MovePackage($identifier: PackageIdentifierInput!, $catalogIdentifier: CatalogIdentifierInput!) {
  movePackage(identifier: $identifier, catalogIdentifier: $catalogIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MovePackageGQL extends Apollo.Mutation<MovePackageMutation, MovePackageMutationVariables> {
    document = MovePackageDocument;
    
  }
export const MyAPIKeysDocument = gql`
    query MyAPIKeys {
  myAPIKeys {
    id
    label
    scopes
    createdAt
    lastUsed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyAPIKeysGQL extends Apollo.Query<MyAPIKeysQuery, MyAPIKeysQueryVariables> {
    document = MyAPIKeysDocument;
    
  }
export const MyActivityDocument = gql`
    query MyActivity($filter: ActivityLogFilterInput!) {
  myActivity(filter: $filter) {
    logs {
      user {
        emailAddress
        username
        firstName
        lastName
        displayName
      }
      targetPackage {
        creator {
          username
          emailAddress
        }
        identifier {
          catalogSlug
          packageSlug
        }
      }
      targetPackageIssue {
        issueNumber
        packageIdentifier {
          catalogSlug
          packageSlug
        }
        author {
          username
          firstName
          lastName
          emailAddress
        }
      }
      targetGroup {
        name
        slug
      }
      targetPackageVersion {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
      }
      targetCollection {
        identifier {
          collectionSlug
        }
        name
      }
      targetCatalog {
        identifier {
          catalogSlug
        }
        displayName
      }
      targetUser {
        emailAddress
        username
        firstName
        lastName
        displayName
      }
      propertiesEdited
      eventType
      changeType
      createdAt
      updatedAt
      permissions
    }
    count
    hasMore
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyActivityGQL extends Apollo.Query<MyActivityQuery, MyActivityQueryVariables> {
    document = MyActivityDocument;
    
  }
export const MyCatalogsDocument = gql`
    query MyCatalogs {
  myCatalogs {
    identifier {
      registryURL
      catalogSlug
    }
    displayName
    description
    website
    isPublic
    unclaimed
    myPermissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyCatalogsGQL extends Apollo.Query<MyCatalogsQuery, MyCatalogsQueryVariables> {
    document = MyCatalogsDocument;
    
  }
export const MyCollectionsDocument = gql`
    query MyCollections($offSet: Int!, $limit: Int!) {
  myCollections(offSet: $offSet, limit: $limit) {
    collections {
      identifier {
        collectionSlug
      }
      name
      description
      packages {
        identifier {
          catalogSlug
          packageSlug
        }
        displayName
        description
        latestVersion {
          identifier {
            versionMajor
            versionMinor
            versionPatch
          }
        }
      }
      isPublic
      isRecommended
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyCollectionsGQL extends Apollo.Query<MyCollectionsQuery, MyCollectionsQueryVariables> {
    document = MyCollectionsDocument;
    
  }
export const MyFollowingActivityDocument = gql`
    query MyFollowingActivity($offset: Int!, $limit: Int!) {
  myFollowingActivity(offset: $offset, limit: $limit) {
    logs {
      id
      targetCatalog {
        identifier {
          catalogSlug
        }
        displayName
      }
      targetPackage {
        identifier {
          catalogSlug
          packageSlug
        }
        displayName
      }
      targetGroup {
        name
        slug
      }
      targetPackageIssue {
        packageIdentifier {
          catalogSlug
          packageSlug
        }
        issueNumber
      }
      targetCollection {
        identifier {
          collectionSlug
        }
        name
      }
      user {
        firstName
        lastName
        username
      }
      eventType
      changeType
      createdAt
      updatedAt
      propertiesEdited
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyFollowingActivityGQL extends Apollo.Query<MyFollowingActivityQuery, MyFollowingActivityQueryVariables> {
    document = MyFollowingActivityDocument;
    
  }
export const MyFollowsDocument = gql`
    query MyFollows($type: FollowType!, $offset: Int!, $limit: Int!) {
  myFollows(type: $type, offset: $offset, limit: $limit) {
    follows {
      notificationFrequency
      eventTypes
      catalog {
        identifier {
          registryURL
          catalogSlug
        }
        displayName
      }
      package {
        identifier {
          registryURL
          catalogSlug
          packageSlug
        }
        displayName
      }
      packageIssue {
        issueNumber
        subject
        packageIdentifier {
          catalogSlug
          packageSlug
        }
      }
      collection {
        identifier {
          collectionSlug
        }
        name
      }
      user {
        firstName
        lastName
        username
        displayName
      }
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyFollowsGQL extends Apollo.Query<MyFollowsQuery, MyFollowsQueryVariables> {
    document = MyFollowsDocument;
    
  }
export const MyGroupsDocument = gql`
    query MyGroups {
  myGroups {
    slug
    name
    description
    myPermissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyGroupsGQL extends Apollo.Query<MyGroupsQuery, MyGroupsQueryVariables> {
    document = MyGroupsDocument;
    
  }
export const MyPackagesDocument = gql`
    query MyPackages($offset: Int!, $limit: Int!) {
  myPackages(offset: $offset, limit: $limit) {
    packages {
      myPermissions
      identifier {
        registryURL
        catalogSlug
        packageSlug
      }
      catalog {
        identifier {
          catalogSlug
        }
        myPermissions
        displayName
        isPublic
        unclaimed
      }
      isPublic
      displayName
      description
      latestVersion {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
        createdAt
      }
      creator {
        username
        firstName
        lastName
      }
      versions {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
        createdAt
      }
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyPackagesGQL extends Apollo.Query<MyPackagesQuery, MyPackagesQueryVariables> {
    document = MyPackagesDocument;
    
  }
export const MyRecentlyViewedCollectionsDocument = gql`
    query MyRecentlyViewedCollections($offset: Int!, $limit: Int!) {
  myRecentlyViewedCollections(offset: $offset, limit: $limit) {
    logs {
      targetCollection {
        identifier {
          collectionSlug
        }
        name
        description
        isPublic
        isRecommended
      }
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyRecentlyViewedCollectionsGQL extends Apollo.Query<MyRecentlyViewedCollectionsQuery, MyRecentlyViewedCollectionsQueryVariables> {
    document = MyRecentlyViewedCollectionsDocument;
    
  }
export const MyRecentlyViewedPackagesDocument = gql`
    query MyRecentlyViewedPackages($offset: Int!, $limit: Int!) {
  myRecentlyViewedPackages(offSet: $offset, limit: $limit) {
    logs {
      targetPackage {
        identifier {
          catalogSlug
          packageSlug
        }
        catalog {
          identifier {
            catalogSlug
          }
          displayName
          isPublic
          unclaimed
        }
        myPermissions
        isPublic
        displayName
        description
        creator {
          username
          firstName
          lastName
          nameIsPublic
        }
      }
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyRecentlyViewedPackagesGQL extends Apollo.Query<MyRecentlyViewedPackagesQuery, MyRecentlyViewedPackagesQueryVariables> {
    document = MyRecentlyViewedPackagesDocument;
    
  }
export const PackageDocument = gql`
    query Package($identifier: PackageIdentifierInput!) {
  package(identifier: $identifier) {
    identifier {
      registryURL
      catalogSlug
      packageSlug
    }
    catalog {
      identifier {
        catalogSlug
      }
      displayName
      myPermissions
      isPublic
      unclaimed
    }
    creator {
      username
      firstName
      lastName
      nameIsPublic
    }
    isPublic
    myPermissions
    displayName
    description
    myPermissions
    latestVersion {
      identifier {
        versionMajor
        versionMinor
        versionPatch
      }
      packageFile
      canonicalPackageFile
      createdAt
      updatedAt
    }
    versions {
      identifier {
        versionMajor
        versionMinor
        versionPatch
      }
      author {
        firstName
        lastName
        username
        displayName
      }
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageGQL extends Apollo.Query<PackageQuery, PackageQueryVariables> {
    document = PackageDocument;
    
  }
export const PackageActivitiesDocument = gql`
    query PackageActivities($identifier: PackageIdentifierInput!, $filter: ActivityLogFilterInput!) {
  packageActivities(identifier: $identifier, filter: $filter) {
    logs {
      user {
        emailAddress
        username
        firstName
        lastName
        displayName
      }
      targetPackage {
        creator {
          username
          emailAddress
        }
        identifier {
          catalogSlug
          packageSlug
        }
      }
      targetPackageVersion {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
      }
      targetCollection {
        identifier {
          collectionSlug
        }
        name
      }
      targetCatalog {
        identifier {
          catalogSlug
        }
        displayName
      }
      eventType
      changeType
      createdAt
      updatedAt
    }
    count
    hasMore
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageActivitiesGQL extends Apollo.Query<PackageActivitiesQuery, PackageActivitiesQueryVariables> {
    document = PackageActivitiesDocument;
    
  }
export const PackageCollectionsDocument = gql`
    query PackageCollections($packageIdentifier: PackageIdentifierInput!, $limit: Int!, $offset: Int!) {
  packageCollections(packageIdentifier: $packageIdentifier, limit: $limit, offset: $offset) {
    collections {
      identifier {
        collectionSlug
      }
      packages {
        identifier {
          catalogSlug
          packageSlug
        }
        displayName
        description
        updateMethods
        isPublic
        myPermissions
        creator {
          username
        }
        catalog {
          identifier {
            catalogSlug
          }
          displayName
          isPublic
          unclaimed
        }
      }
      name
      description
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageCollectionsGQL extends Apollo.Query<PackageCollectionsQuery, PackageCollectionsQueryVariables> {
    document = PackageCollectionsDocument;
    
  }
export const PackageFetchedDocument = gql`
    mutation PackageFetched($identifier: VersionIdentifierInput!) {
  packageFetched(identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageFetchedGQL extends Apollo.Mutation<PackageFetchedMutation, PackageFetchedMutationVariables> {
    document = PackageFetchedDocument;
    
  }
export const PackageFollowersDocument = gql`
    query PackageFollowers($identifier: PackageIdentifierInput!, $limit: Int!, $offset: Int!) {
  packageFollowers(identifier: $identifier, limit: $limit, offset: $offset) {
    followers {
      firstName
      lastName
      username
      displayName
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageFollowersGQL extends Apollo.Query<PackageFollowersQuery, PackageFollowersQueryVariables> {
    document = PackageFollowersDocument;
    
  }
export const PackageFollowersCountDocument = gql`
    query PackageFollowersCount($identifier: PackageIdentifierInput!) {
  packageFollowersCount(identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageFollowersCountGQL extends Apollo.Query<PackageFollowersCountQuery, PackageFollowersCountQueryVariables> {
    document = PackageFollowersCountDocument;
    
  }
export const PackageIssueDocument = gql`
    query PackageIssue($packageIdentifier: PackageIdentifierInput!, $packageIssueIdentifier: PackageIssueIdentifierInput!) {
  packageIssue(packageIdentifier: $packageIdentifier, packageIssueIdentifier: $packageIssueIdentifier) {
    issueNumber
    subject
    content
    author {
      firstName
      lastName
      username
      displayName
    }
    status
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageIssueGQL extends Apollo.Query<PackageIssueQuery, PackageIssueQueryVariables> {
    document = PackageIssueDocument;
    
  }
export const PackageIssueCommentsDocument = gql`
    query PackageIssueComments($packageIdentifier: PackageIdentifierInput!, $issueIdentifier: PackageIssueIdentifierInput!, $offset: Int!, $limit: Int!, $orderBy: OrderBy!) {
  packageIssueComments(packageIdentifier: $packageIdentifier, issueIdentifier: $issueIdentifier, offset: $offset, limit: $limit, orderBy: $orderBy) {
    comments {
      commentNumber
      content
      author {
        firstName
        lastName
        username
        displayName
      }
      createdAt
      updatedAt
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageIssueCommentsGQL extends Apollo.Query<PackageIssueCommentsQuery, PackageIssueCommentsQueryVariables> {
    document = PackageIssueCommentsDocument;
    
  }
export const PackageIssueFollowersDocument = gql`
    query PackageIssueFollowers($identifier: PackageIdentifierInput!, $issueIdentifier: PackageIssueIdentifierInput!, $limit: Int!, $offset: Int!) {
  packageIssueFollowers(identifier: $identifier, issueIdentifier: $issueIdentifier, limit: $limit, offset: $offset) {
    followers {
      firstName
      lastName
      username
      displayName
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageIssueFollowersGQL extends Apollo.Query<PackageIssueFollowersQuery, PackageIssueFollowersQueryVariables> {
    document = PackageIssueFollowersDocument;
    
  }
export const PackageIssueFollowersCountDocument = gql`
    query PackageIssueFollowersCount($identifier: PackageIdentifierInput!, $issueIdentifier: PackageIssueIdentifierInput!) {
  packageIssueFollowersCount(identifier: $identifier, issueIdentifier: $issueIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageIssueFollowersCountGQL extends Apollo.Query<PackageIssueFollowersCountQuery, PackageIssueFollowersCountQueryVariables> {
    document = PackageIssueFollowersCountDocument;
    
  }
export const PackageIssuesDocument = gql`
    query PackageIssues($packageIdentifier: PackageIdentifierInput!, $includeOpenIssues: Boolean!, $includeClosedIssues: Boolean!, $offset: Int!, $limit: Int!, $orderBy: OrderBy!) {
  packageIssues(packageIdentifier: $packageIdentifier, offset: $offset, includeOpenIssues: $includeOpenIssues, includeClosedIssues: $includeClosedIssues, limit: $limit, orderBy: $orderBy) {
    issues {
      issueNumber
      subject
      content
      author {
        firstName
        lastName
        username
        displayName
      }
      status
      createdAt
      updatedAt
    }
    hasMore
    count
    openIssuesCount
    closedIssuesCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageIssuesGQL extends Apollo.Query<PackageIssuesQuery, PackageIssuesQueryVariables> {
    document = PackageIssuesDocument;
    
  }
export const PackageVersionsDiffDocument = gql`
    query PackageVersionsDiff($newVersion: VersionIdentifierInput!, $oldVersion: VersionIdentifierInput!) {
  packageVersionsDiff(newVersion: $newVersion, oldVersion: $oldVersion) {
    newVersion {
      versionMajor
      versionMinor
      versionPatch
    }
    oldVersion {
      versionMajor
      versionMinor
      versionPatch
    }
    differences {
      type
      pointer
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageVersionsDiffGQL extends Apollo.Query<PackageVersionsDiffQuery, PackageVersionsDiffQueryVariables> {
    document = PackageVersionsDiffDocument;
    
  }
export const PackageVersionsDiffsDocument = gql`
    query PackageVersionsDiffs($packageIdentifier: PackageIdentifierInput!, $offset: Int!, $limit: Int!) {
  packageVersionsDiffs(packageIdentifier: $packageIdentifier, offset: $offset, limit: $limit) {
    newVersion {
      versionMajor
      versionMinor
      versionPatch
    }
    oldVersion {
      versionMajor
      versionMinor
      versionPatch
    }
    differences {
      type
      pointer
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackageVersionsDiffsGQL extends Apollo.Query<PackageVersionsDiffsQuery, PackageVersionsDiffsQueryVariables> {
    document = PackageVersionsDiffsDocument;
    
  }
export const GetPageContentDocument = gql`
    query GetPageContent($route: String!) {
  pageContent(route: $route) {
    catalog {
      identifier {
        registryURL
        catalogSlug
      }
      displayName
      description
      website
      myPermissions
      isPublic
      unclaimed
      packages {
        identifier {
          registryURL
          catalogSlug
          packageSlug
        }
        catalog {
          identifier {
            catalogSlug
          }
          displayName
          isPublic
          unclaimed
        }
        displayName
        description
        latestVersion {
          identifier {
            registryURL
            catalogSlug
            packageSlug
            versionMajor
            versionMinor
            versionPatch
          }
          createdAt
          updatedAt
          packageFile
        }
        updateMethods
        isPublic
        myPermissions
        creator {
          username
          displayName
        }
      }
    }
    user {
      emailAddress
      firstName
      lastName
      displayName
      username
      nameIsPublic
      location
      twitterHandle
      gitHubHandle
      website
      locationIsPublic
      twitterHandleIsPublic
      gitHubHandleIsPublic
      emailAddressIsPublic
      websiteIsPublic
      description
    }
    builderIOPage {
      apiKey
      template {
        key
        entry
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPageContentGQL extends Apollo.Query<GetPageContentQuery, GetPageContentQueryVariables> {
    document = GetPageContentDocument;
    
  }
export const PlatformSettingsDocument = gql`
    query PlatformSettings {
  platformSettings {
    key
    isPublic
    serializedSettings
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PlatformSettingsGQL extends Apollo.Query<PlatformSettingsQuery, PlatformSettingsQueryVariables> {
    document = PlatformSettingsDocument;
    
  }
export const PublicPlatformSettingsByKeyDocument = gql`
    query PublicPlatformSettingsByKey($key: String!) {
  publicPlatformSettingsByKey(key: $key) {
    serializedSettings
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PublicPlatformSettingsByKeyGQL extends Apollo.Query<PublicPlatformSettingsByKeyQuery, PublicPlatformSettingsByKeyQueryVariables> {
    document = PublicPlatformSettingsByKeyDocument;
    
  }
export const RecoverMyPasswordDocument = gql`
    mutation RecoverMyPassword($value: RecoverMyPasswordInput!) {
  recoverMyPassword(value: $value)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RecoverMyPasswordGQL extends Apollo.Mutation<RecoverMyPasswordMutation, RecoverMyPasswordMutationVariables> {
    document = RecoverMyPasswordDocument;
    
  }
export const RegistryStatusDocument = gql`
    query RegistryStatus {
  registryStatus {
    status
    version
    registryUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegistryStatusGQL extends Apollo.Query<RegistryStatusQuery, RegistryStatusQueryVariables> {
    document = RegistryStatusDocument;
    
  }
export const RemoveGroupFromCatalogDocument = gql`
    mutation RemoveGroupFromCatalog($groupSlug: String!, $catalogIdentifier: CatalogIdentifierInput!) {
  removeGroupFromCatalog(groupSlug: $groupSlug, catalogIdentifier: $catalogIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveGroupFromCatalogGQL extends Apollo.Mutation<RemoveGroupFromCatalogMutation, RemoveGroupFromCatalogMutationVariables> {
    document = RemoveGroupFromCatalogDocument;
    
  }
export const RemoveGroupFromCollectionDocument = gql`
    mutation RemoveGroupFromCollection($groupSlug: String!, $collectionIdentifier: CollectionIdentifierInput!) {
  removeGroupFromCollection(groupSlug: $groupSlug, collectionIdentifier: $collectionIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveGroupFromCollectionGQL extends Apollo.Mutation<RemoveGroupFromCollectionMutation, RemoveGroupFromCollectionMutationVariables> {
    document = RemoveGroupFromCollectionDocument;
    
  }
export const RemoveGroupFromPackageDocument = gql`
    mutation RemoveGroupFromPackage($groupSlug: String!, $packageIdentifier: PackageIdentifierInput!) {
  removeGroupFromPackage(groupSlug: $groupSlug, packageIdentifier: $packageIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveGroupFromPackageGQL extends Apollo.Mutation<RemoveGroupFromPackageMutation, RemoveGroupFromPackageMutationVariables> {
    document = RemoveGroupFromPackageDocument;
    
  }
export const RemovePackageFromCollectionDocument = gql`
    mutation RemovePackageFromCollection($collectionIdentifier: CollectionIdentifierInput!, $packageIdentifier: PackageIdentifierInput!) {
  removePackageFromCollection(collectionIdentifier: $collectionIdentifier, packageIdentifier: $packageIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemovePackageFromCollectionGQL extends Apollo.Mutation<RemovePackageFromCollectionMutation, RemovePackageFromCollectionMutationVariables> {
    document = RemovePackageFromCollectionDocument;
    
  }
export const RemovePackagePermissionsDocument = gql`
    mutation RemovePackagePermissions($usernameOrEmailAddress: String!, $identifier: PackageIdentifierInput!) {
  removePackagePermissions(usernameOrEmailAddress: $usernameOrEmailAddress, identifier: $identifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemovePackagePermissionsGQL extends Apollo.Mutation<RemovePackagePermissionsMutation, RemovePackagePermissionsMutationVariables> {
    document = RemovePackagePermissionsDocument;
    
  }
export const RemoveUserFromGroupDocument = gql`
    mutation RemoveUserFromGroup($username: String!, $groupSlug: String!) {
  removeUserFromGroup(username: $username, groupSlug: $groupSlug) {
    slug
    name
    users {
      user {
        username
        firstName
        lastName
        nameIsPublic
        displayName
      }
      permissions
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUserFromGroupGQL extends Apollo.Mutation<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables> {
    document = RemoveUserFromGroupDocument;
    
  }
export const RunJobDocument = gql`
    mutation RunJob($key: String!, $job: JobType!) {
  runJob(key: $key, job: $job)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RunJobGQL extends Apollo.Mutation<RunJobMutation, RunJobMutationVariables> {
    document = RunJobDocument;
    
  }
export const SavePlatformSettingsDocument = gql`
    mutation SavePlatformSettings($settings: PlatformSettingsInput!) {
  savePlatformSettings(settings: $settings) {
    key
    isPublic
    serializedSettings
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SavePlatformSettingsGQL extends Apollo.Mutation<SavePlatformSettingsMutation, SavePlatformSettingsMutationVariables> {
    document = SavePlatformSettingsDocument;
    
  }
export const SearchCatalogsDocument = gql`
    query SearchCatalogs($query: String!, $limit: Int!, $offset: Int!) {
  searchCatalogs(query: $query, limit: $limit, offSet: $offset) {
    catalogs {
      identifier {
        catalogSlug
      }
      displayName
      description
      myPermissions
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchCatalogsGQL extends Apollo.Query<SearchCatalogsQuery, SearchCatalogsQueryVariables> {
    document = SearchCatalogsDocument;
    
  }
export const SearchCollectionsDocument = gql`
    query SearchCollections($query: String!, $limit: Int!, $offset: Int!) {
  searchCollections(query: $query, limit: $limit, offset: $offset) {
    collections {
      creator {
        firstName
        lastName
        emailAddress
      }
      identifier {
        registryURL
        collectionSlug
      }
      name
      description
      packages {
        creator {
          firstName
          lastName
          emailAddress
        }
        identifier {
          catalogSlug
          packageSlug
        }
        catalog {
          identifier {
            catalogSlug
          }
          myPermissions
          displayName
          isPublic
          unclaimed
        }
        displayName
        description
        latestVersion {
          identifier {
            versionMajor
            versionMinor
            versionPatch
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchCollectionsGQL extends Apollo.Query<SearchCollectionsQuery, SearchCollectionsQueryVariables> {
    document = SearchCollectionsDocument;
    
  }
export const SearchPackagesDocument = gql`
    query SearchPackages($query: String!, $limit: Int!, $offset: Int!) {
  searchPackages(query: $query, limit: $limit, offSet: $offset) {
    packages {
      isPublic
      myPermissions
      creator {
        firstName
        lastName
        username
      }
      identifier {
        catalogSlug
        packageSlug
      }
      catalog {
        identifier {
          catalogSlug
        }
        displayName
        isPublic
        unclaimed
      }
      displayName
      description
      latestVersion {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
        createdAt
        updatedAt
      }
      updateMethods
      createdAt
      updatedAt
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchPackagesGQL extends Apollo.Query<SearchPackagesQuery, SearchPackagesQueryVariables> {
    document = SearchPackagesDocument;
    
  }
export const SearchUsersDocument = gql`
    query SearchUsers($value: String!, $limit: Int!, $offset: Int!) {
  searchUsers(value: $value, limit: $limit, offSet: $offset) {
    users {
      username
      firstName
      lastName
      displayName
      nameIsPublic
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchUsersGQL extends Apollo.Query<SearchUsersQuery, SearchUsersQueryVariables> {
    document = SearchUsersDocument;
    
  }
export const SetAsAdminDocument = gql`
    mutation SetAsAdmin($username: String!, $isAdmin: Boolean!) {
  setAsAdmin(username: $username, isAdmin: $isAdmin)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetAsAdminGQL extends Apollo.Mutation<SetAsAdminMutation, SetAsAdminMutationVariables> {
    document = SetAsAdminDocument;
    
  }
export const SetCatalogAvatarImageDocument = gql`
    mutation SetCatalogAvatarImage($identifier: CatalogIdentifierInput!, $image: Base64ImageUpload!) {
  setCatalogAvatarImage(identifier: $identifier, image: $image)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetCatalogAvatarImageGQL extends Apollo.Mutation<SetCatalogAvatarImageMutation, SetCatalogAvatarImageMutationVariables> {
    document = SetCatalogAvatarImageDocument;
    
  }
export const SetCatalogCoverImageDocument = gql`
    mutation SetCatalogCoverImage($identifier: CatalogIdentifierInput!, $image: Base64ImageUpload!) {
  setCatalogCoverImage(identifier: $identifier, image: $image)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetCatalogCoverImageGQL extends Apollo.Mutation<SetCatalogCoverImageMutation, SetCatalogCoverImageMutationVariables> {
    document = SetCatalogCoverImageDocument;
    
  }
export const SetCollectionCoverImageDocument = gql`
    mutation SetCollectionCoverImage($identifier: CollectionIdentifierInput!, $image: Base64ImageUpload!) {
  setCollectionCoverImage(identifier: $identifier, image: $image)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetCollectionCoverImageGQL extends Apollo.Mutation<SetCollectionCoverImageMutation, SetCollectionCoverImageMutationVariables> {
    document = SetCollectionCoverImageDocument;
    
  }
export const SetGroupAsAdminDocument = gql`
    mutation SetGroupAsAdmin($groupSlug: String!, $isAdmin: Boolean!) {
  setGroupAsAdmin(groupSlug: $groupSlug, isAdmin: $isAdmin)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetGroupAsAdminGQL extends Apollo.Mutation<SetGroupAsAdminMutation, SetGroupAsAdminMutationVariables> {
    document = SetGroupAsAdminDocument;
    
  }
export const SetMyAvatarImageDocument = gql`
    mutation SetMyAvatarImage($image: Base64ImageUpload!) {
  setMyAvatarImage(image: $image)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetMyAvatarImageGQL extends Apollo.Mutation<SetMyAvatarImageMutation, SetMyAvatarImageMutationVariables> {
    document = SetMyAvatarImageDocument;
    
  }
export const SetMyCoverImageDocument = gql`
    mutation SetMyCoverImage($image: Base64ImageUpload!) {
  setMyCoverImage(image: $image)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetMyCoverImageGQL extends Apollo.Mutation<SetMyCoverImageMutation, SetMyCoverImageMutationVariables> {
    document = SetMyCoverImageDocument;
    
  }
export const SetPackageCoverImageDocument = gql`
    mutation SetPackageCoverImage($identifier: PackageIdentifierInput!, $image: Base64ImageUpload!) {
  setPackageCoverImage(identifier: $identifier, image: $image)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetPackageCoverImageGQL extends Apollo.Mutation<SetPackageCoverImageMutation, SetPackageCoverImageMutationVariables> {
    document = SetPackageCoverImageDocument;
    
  }
export const SetPackagePermissionsDocument = gql`
    mutation SetPackagePermissions($identifier: PackageIdentifierInput!, $value: [SetPackagePermissionInput!]!, $message: String!) {
  setPackagePermissions(identifier: $identifier, value: $value, message: $message)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetPackagePermissionsGQL extends Apollo.Mutation<SetPackagePermissionsMutation, SetPackagePermissionsMutationVariables> {
    document = SetPackagePermissionsDocument;
    
  }
export const SetUserCatalogPermissionDocument = gql`
    mutation SetUserCatalogPermission($identifier: CatalogIdentifierInput!, $value: [SetUserCatalogPermissionInput!]!, $message: String!) {
  setUserCatalogPermission(identifier: $identifier, value: $value, message: $message)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetUserCatalogPermissionGQL extends Apollo.Mutation<SetUserCatalogPermissionMutation, SetUserCatalogPermissionMutationVariables> {
    document = SetUserCatalogPermissionDocument;
    
  }
export const SetUserCollectionPermissionsDocument = gql`
    mutation SetUserCollectionPermissions($identifier: CollectionIdentifierInput!, $value: [SetUserCollectionPermissionsInput!]!, $message: String!) {
  setUserCollectionPermissions(identifier: $identifier, value: $value, message: $message)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetUserCollectionPermissionsGQL extends Apollo.Mutation<SetUserCollectionPermissionsMutation, SetUserCollectionPermissionsMutationVariables> {
    document = SetUserCollectionPermissionsDocument;
    
  }
export const UpdateCatalogDocument = gql`
    mutation UpdateCatalog($identifier: CatalogIdentifierInput!, $value: UpdateCatalogInput!) {
  updateCatalog(identifier: $identifier, value: $value) {
    identifier {
      catalogSlug
    }
    displayName
    description
    myPermissions
    website
    isPublic
    unclaimed
    myPermissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCatalogGQL extends Apollo.Mutation<UpdateCatalogMutation, UpdateCatalogMutationVariables> {
    document = UpdateCatalogDocument;
    
  }
export const UpdateCollectionDocument = gql`
    mutation UpdateCollection($identifier: CollectionIdentifierInput!, $value: UpdateCollectionInput!) {
  updateCollection(identifier: $identifier, value: $value) {
    identifier {
      collectionSlug
    }
    name
    description
    myPermissions
    packages {
      identifier {
        catalogSlug
        packageSlug
      }
      displayName
      description
      latestVersion {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
      }
      creator {
        username
      }
      catalog {
        myPermissions
        identifier {
          catalogSlug
        }
        displayName
        description
      }
    }
    isPublic
    isRecommended
    myPermissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCollectionGQL extends Apollo.Mutation<UpdateCollectionMutation, UpdateCollectionMutationVariables> {
    document = UpdateCollectionDocument;
    
  }
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($groupSlug: String!, $name: String!, $description: String!) {
  updateGroup(groupSlug: $groupSlug, name: $name, description: $description) {
    slug
    name
    description
    myPermissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGroupGQL extends Apollo.Mutation<UpdateGroupMutation, UpdateGroupMutationVariables> {
    document = UpdateGroupDocument;
    
  }
export const UpdateMeDocument = gql`
    mutation UpdateMe($value: UpdateUserInput!) {
  updateMe(value: $value) {
    isAdmin
    user {
      emailAddress
      displayName
      firstName
      lastName
      username
      description
      location
      twitterHandle
      gitHubHandle
      website
      nameIsPublic
      locationIsPublic
      twitterHandleIsPublic
      gitHubHandleIsPublic
      emailAddressIsPublic
      websiteIsPublic
      uiDarkModeEnabled
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMeGQL extends Apollo.Mutation<UpdateMeMutation, UpdateMeMutationVariables> {
    document = UpdateMeDocument;
    
  }
export const UpdateMyPasswordDocument = gql`
    mutation UpdateMyPassword($value: UpdateMyPasswordInput!) {
  updateMyPassword(value: $value)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMyPasswordGQL extends Apollo.Mutation<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables> {
    document = UpdateMyPasswordDocument;
    
  }
export const UpdatePackageDocument = gql`
    mutation UpdatePackage($identifier: PackageIdentifierInput!, $value: UpdatePackageInput!) {
  updatePackage(identifier: $identifier, value: $value) {
    identifier {
      catalogSlug
      packageSlug
    }
    catalog {
      myPermissions
      displayName
    }
    displayName
    description
    myPermissions
    isPublic
    latestVersion {
      identifier {
        versionMajor
        versionMinor
        versionPatch
      }
      packageFile
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePackageGQL extends Apollo.Mutation<UpdatePackageMutation, UpdatePackageMutationVariables> {
    document = UpdatePackageDocument;
    
  }
export const UpdatePackageIssueDocument = gql`
    mutation UpdatePackageIssue($packageIdentifier: PackageIdentifierInput!, $issueIdentifier: PackageIssueIdentifierInput!, $issue: UpdatePackageIssueInput!) {
  updatePackageIssue(packageIdentifier: $packageIdentifier, issueIdentifier: $issueIdentifier, issue: $issue) {
    issueNumber
    subject
    content
    status
    author {
      firstName
      lastName
      username
      displayName
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePackageIssueGQL extends Apollo.Mutation<UpdatePackageIssueMutation, UpdatePackageIssueMutationVariables> {
    document = UpdatePackageIssueDocument;
    
  }
export const UpdatePackageIssueCommentDocument = gql`
    mutation UpdatePackageIssueComment($packageIdentifier: PackageIdentifierInput!, $issueIdentifier: PackageIssueIdentifierInput!, $issueCommentIdentifier: PackageIssueCommentIdentifierInput!, $comment: UpdatePackageIssueCommentInput!) {
  updatePackageIssueComment(packageIdentifier: $packageIdentifier, issueIdentifier: $issueIdentifier, issueCommentIdentifier: $issueCommentIdentifier, comment: $comment) {
    commentNumber
    content
    author {
      firstName
      lastName
      username
      displayName
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePackageIssueCommentGQL extends Apollo.Mutation<UpdatePackageIssueCommentMutation, UpdatePackageIssueCommentMutationVariables> {
    document = UpdatePackageIssueCommentDocument;
    
  }
export const UpdatePackageIssueStatusDocument = gql`
    mutation UpdatePackageIssueStatus($packageIdentifier: PackageIdentifierInput!, $issueIdentifier: PackageIssueIdentifierInput!, $status: UpdatePackageIssueStatusInput!) {
  updatePackageIssueStatus(packageIdentifier: $packageIdentifier, issueIdentifier: $issueIdentifier, status: $status) {
    issueNumber
    subject
    content
    status
    author {
      firstName
      lastName
      username
      displayName
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePackageIssueStatusGQL extends Apollo.Mutation<UpdatePackageIssueStatusMutation, UpdatePackageIssueStatusMutationVariables> {
    document = UpdatePackageIssueStatusDocument;
    
  }
export const UpdatePackageIssuesStatusesDocument = gql`
    mutation UpdatePackageIssuesStatuses($packageIdentifier: PackageIdentifierInput!, $issuesIdentifiers: [PackageIssueIdentifierInput!]!, $status: UpdatePackageIssueStatusInput!) {
  updatePackageIssuesStatuses(packageIdentifier: $packageIdentifier, issuesIdentifiers: $issuesIdentifiers, status: $status)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePackageIssuesStatusesGQL extends Apollo.Mutation<UpdatePackageIssuesStatusesMutation, UpdatePackageIssuesStatusesMutationVariables> {
    document = UpdatePackageIssuesStatusesDocument;
    
  }
export const UserDocument = gql`
    query User($username: String!) {
  user(username: $username) {
    emailAddress
    firstName
    lastName
    username
    displayName
    nameIsPublic
    location
    twitterHandle
    gitHubHandle
    website
    locationIsPublic
    twitterHandleIsPublic
    gitHubHandleIsPublic
    emailAddressIsPublic
    websiteIsPublic
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserGQL extends Apollo.Query<UserQuery, UserQueryVariables> {
    document = UserDocument;
    
  }
export const UserCatalogsDocument = gql`
    query UserCatalogs($username: String!, $offSet: Int!, $limit: Int!) {
  userCatalogs(username: $username, offSet: $offSet, limit: $limit) {
    catalogs {
      identifier {
        registryURL
        catalogSlug
      }
      displayName
      description
      website
      isPublic
      unclaimed
      myPermissions
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserCatalogsGQL extends Apollo.Query<UserCatalogsQuery, UserCatalogsQueryVariables> {
    document = UserCatalogsDocument;
    
  }
export const UserCollectionsDocument = gql`
    query UserCollections($username: String!, $offSet: Int!, $limit: Int!) {
  userCollections(username: $username, offSet: $offSet, limit: $limit) {
    collections {
      identifier {
        collectionSlug
      }
      name
      description
      myPermissions
      isPublic
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserCollectionsGQL extends Apollo.Query<UserCollectionsQuery, UserCollectionsQueryVariables> {
    document = UserCollectionsDocument;
    
  }
export const UserFollowersDocument = gql`
    query UserFollowers($username: String!, $limit: Int!, $offset: Int!) {
  userFollowers(username: $username, limit: $limit, offset: $offset) {
    followers {
      firstName
      lastName
      username
      displayName
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFollowersGQL extends Apollo.Query<UserFollowersQuery, UserFollowersQueryVariables> {
    document = UserFollowersDocument;
    
  }
export const UserFollowersCountDocument = gql`
    query UserFollowersCount($username: String!) {
  userFollowersCount(username: $username)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFollowersCountGQL extends Apollo.Query<UserFollowersCountQuery, UserFollowersCountQueryVariables> {
    document = UserFollowersCountDocument;
    
  }
export const UserPackagesDocument = gql`
    query UserPackages($username: String!, $offSet: Int!, $limit: Int!) {
  userPackages(username: $username, offSet: $offSet, limit: $limit) {
    packages {
      myPermissions
      identifier {
        registryURL
        catalogSlug
        packageSlug
      }
      catalog {
        identifier {
          catalogSlug
        }
        myPermissions
        displayName
        isPublic
        unclaimed
      }
      isPublic
      displayName
      description
      latestVersion {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
        createdAt
      }
      updateMethods
      creator {
        username
        firstName
        lastName
      }
      versions {
        identifier {
          versionMajor
          versionMinor
          versionPatch
        }
        createdAt
      }
    }
    hasMore
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserPackagesGQL extends Apollo.Query<UserPackagesQuery, UserPackagesQueryVariables> {
    document = UserPackagesDocument;
    
  }
export const UsernameAvailableDocument = gql`
    query UsernameAvailable($username: String!) {
  usernameAvailable(username: $username)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsernameAvailableGQL extends Apollo.Query<UsernameAvailableQuery, UsernameAvailableQueryVariables> {
    document = UsernameAvailableDocument;
    
  }
export const UsersByCatalogDocument = gql`
    query UsersByCatalog($identifier: CatalogIdentifierInput!) {
  usersByCatalog(identifier: $identifier) {
    user {
      username
      firstName
      lastName
      nameIsPublic
      displayName
    }
    permissions
    packagePermissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersByCatalogGQL extends Apollo.Query<UsersByCatalogQuery, UsersByCatalogQueryVariables> {
    document = UsersByCatalogDocument;
    
  }
export const UsersByCollectionDocument = gql`
    query UsersByCollection($identifier: CollectionIdentifierInput!) {
  usersByCollection(identifier: $identifier) {
    user {
      username
      firstName
      lastName
      nameIsPublic
      displayName
    }
    permissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersByCollectionGQL extends Apollo.Query<UsersByCollectionQuery, UsersByCollectionQueryVariables> {
    document = UsersByCollectionDocument;
    
  }
export const UsersByPackageDocument = gql`
    query UsersByPackage($identifier: PackageIdentifierInput!) {
  usersByPackage(identifier: $identifier) {
    user {
      username
      firstName
      lastName
      nameIsPublic
      displayName
    }
    permissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersByPackageGQL extends Apollo.Query<UsersByPackageQuery, UsersByPackageQueryVariables> {
    document = UsersByPackageDocument;
    
  }
export const VerifyEmailAddressDocument = gql`
    mutation VerifyEmailAddress($token: String!) {
  verifyEmailAddress(token: $token)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VerifyEmailAddressGQL extends Apollo.Mutation<VerifyEmailAddressMutation, VerifyEmailAddressMutationVariables> {
    document = VerifyEmailAddressDocument;
    
  }

export const typeDefs = gql`
"""
Whether the requesting user's authentication mechanism has API key management permission
"""
directive @hasAPIKeyManagementPermission on ARGUMENT_DEFINITION

directive @isAuthenticated on OBJECT | FIELD_DEFINITION

directive @validUsername on ARGUMENT_DEFINITION | INPUT_FIELD_DEFINITION

directive @validUsernameOrEmailAddress on ARGUMENT_DEFINITION | INPUT_FIELD_DEFINITION

directive @validPassword on ARGUMENT_DEFINITION | INPUT_FIELD_DEFINITION

directive @validEmailAddress on ARGUMENT_DEFINITION | INPUT_FIELD_DEFINITION

"""Validates the uploaded base64 image"""
directive @validBase64Image on ARGUMENT_DEFINITION

"""
Whether the requesting user has the specified permission on the catalog
"""
directive @hasCatalogPermission(permission: Permission) on OBJECT | FIELD_DEFINITION | ARGUMENT_DEFINITION

"""
Whether the requesting user has the specified permission on the package
"""
directive @hasPackagePermission(permission: Permission) on OBJECT | FIELD_DEFINITION | ARGUMENT_DEFINITION

"""
Whether the requesting user has the specified permission on the collection
"""
directive @hasCollectionPermission(permission: Permission) on OBJECT | FIELD_DEFINITION | ARGUMENT_DEFINITION

"""
Whether the requesting user has the specified permission on the group (not the associated resources)
"""
directive @hasGroupPermission(permission: Permission) on OBJECT | FIELD_DEFINITION | ARGUMENT_DEFINITION

"""Whether the catalog slug provided is valid"""
directive @validCatalogSlug on ARGUMENT_DEFINITION | INPUT_FIELD_DEFINITION

"""Whether the catalog slug provided is valid"""
directive @validGroupSlug on ARGUMENT_DEFINITION | INPUT_FIELD_DEFINITION

"""Whether the catalog slug provided is valid"""
directive @validPackageSlug on ARGUMENT_DEFINITION | INPUT_FIELD_DEFINITION

"""Whether the collection slug provided is valid"""
directive @validCollectionSlug(allowNull: Boolean) on ARGUMENT_DEFINITION | INPUT_FIELD_DEFINITION

"""Whether the markdown provided is valid"""
directive @validMarkdown(maxLength: Int) on ARGUMENT_DEFINITION | INPUT_FIELD_DEFINITION

"""Whether the input is a valid email or username"""
directive @validEmailOrUsername on ARGUMENT_DEFINITION | INPUT_FIELD_DEFINITION

directive @isAdmin on OBJECT | FIELD_DEFINITION

directive @isUserOrAdmin on OBJECT | FIELD_DEFINITION

directive @NgModule(module: String!) on OBJECT | FIELD

directive @namedClient(name: String!) on OBJECT | FIELD

"""Represents a logged user activity"""
type ActivityLog {
  id: Int!
  user: User
  targetPackage: Package
  targetPackageIssue: PackageIssue
  targetPackageVersion: Version
  targetCollection: Collection
  targetUser: User
  targetGroup: Group
  targetCatalog: Catalog
  permissions: [String!]
  eventType: ActivityLogEventType!
  changeType: ActivityLogChangeType
  createdAt: Date!
  updatedAt: Date!
  propertiesEdited: [String!]
}

enum ActivityLogChangeType {
  VERSION_FIRST_VERSION
  VERSION_TRIVIAL_CHANGE
  VERSION_PATCH_CHANGE
  VERSION_MINOR_CHANGE
  VERSION_MAJOR_CHANGE
  PUBLIC_ENABLED
  PUBLIC_DISABLED
  OPENED
  CLOSED
  UNCLAIMED_ENABLED
  UNCLAIMED_DISABLED
  COVER_IMAGE_UPDATED
  COVER_IMAGE_REMOVED
  AVATAR_IMAGE_UPDATED
  AVATAR_IMAGE_REMOVED
}

"""Activity Log Event types"""
enum ActivityLogEventType {
  PACKAGE_CREATED
  PACKAGE_DELETED
  PACKAGE_VIEWED
  PACKAGE_FETCHED
  PACKAGE_PUBLIC_CHANGED
  PACKAGE_EDIT
  PACKAGE_USER_PERMISSION_ADDED_UPDATED
  PACKAGE_USER_PERMISSION_REMOVED
  PACKAGE_GROUP_PERMISSION_ADDED_UPDATED
  PACKAGE_GROUP_PERMISSION_REMOVED
  DATA_BATCH_UPLOAD_STARTED
  DATA_BATCH_UPLOAD_STOPPED
  DATA_BATCH_DOWNLOAD_STARTED
  DATA_BATCH_DOWNLOAD_STOPPED
  DATA_BATCH_ACTIVE_CHANGED
  DATA_SINK_STATE_REQUESTED
  PACKAGE_ISSUE_CREATED
  PACKAGE_ISSUE_EDIT
  PACKAGE_ISSUE_COMMENT_CREATED
  PACKAGE_ISSUE_COMMENT_EDIT
  PACKAGE_ISSUE_COMMENT_DELETED
  PACKAGE_ISSUE_DELETED
  PACKAGE_ISSUE_STATUS_CHANGE
  PACKAGE_UPDATE_JOB_STARTED
  PACKAGE_UPDATE_JOB_ENDED
  PACKAGE_JOB_STARTED
  PACKAGE_JOB_ENDED
  FETCH_JOB_STARTED
  FETCH_JOB_ENDED
  VERSION_CREATED
  VERSION_UPDATED
  VERSION_DELETED
  COLLECTION_CREATED
  COLLECTION_DELETED
  COLLECTION_VIEWED
  COLLECTION_EDIT
  COLLECTION_PACKAGE_ADDED
  COLLECTION_PACKAGE_REMOVED
  COLLECTION_PUBLIC_CHANGED
  COLLECTION_USER_PERMISSION_ADDED_UPDATED
  COLLECTION_USER_PERMISSION_REMOVED
  COLLECTION_GROUP_PERMISSION_ADDED_UPDATED
  COLLECTION_GROUP_PERMISSION_REMOVED
  CATALOG_CREATED
  CATALOG_DELETED
  CATALOG_PUBLIC_CHANGED
  CATALOG_UNCLAIMED_CHANGED
  CATALOG_EDIT
  CATALOG_PACKAGE_ADDED
  CATALOG_PACKAGE_REMOVED
  CATALOG_USER_PERMISSION_ADDED_UPDATED
  CATALOG_USER_PERMISSION_REMOVED
  CATALOG_GROUP_PERMISSION_ADDED_UPDATED
  CATALOG_GROUP_PERMISSION_REMOVED
  USER_CREATED
  USER_DELETED
  USER_EDIT
  USER_STATUS_CHANGED
  GROUP_CREATED
  GROUP_DELETED
  GROUP_EDIT
  GROUP_MEMBER_REMOVED
  GROUP_MEMBER_PERMISSION_ADDED_UPDATED
}

"""For filtering Activity Logs by event type"""
input ActivityLogFilterInput {
  eventType: [ActivityLogEventType]
  limit: Int!
  offset: Int!
}

"""The response for a search term auto-complete request"""
type ActivityLogResult {
  logs: [ActivityLog!]!
  hasMore: Boolean!
  count: Int!
}

"""
Represents the API Key, not including the secret. API Keys are secrets that are
used provided as authorization by the registry clients.
"""
type APIKey {
  label: String!
  id: String!
  createdAt: Date
  lastUsed: Date
  scopes: [Scope!]!
}

"""
This response is only sent when creating a new API key, so that the secret is shared only once
"""
type APIKeyWithSecret {
  label: String!
  id: String!
  secret: String!
  createdAt: Date
  scopes: [Scope!]!
}

enum AUTHENTICATION_ERROR {
  WRONG_CREDENTIALS
  EMAIL_ADDRESS_NOT_VERIFIED
  USER_NOT_FOUND
  ACCOUNT_SUSPENDED
}

"""The response for a search term auto-complete request"""
type AutoCompleteResult {
  catalogs: [Catalog!]
  packages: [Package!]
  collections: [Collection!]
  users: [User!]
}

input Base64ImageUpload {
  base64: String!
}

"""The complete identifier for a package on a registry"""
type BasePackageIdentifier {
  catalogSlug: String!
  packageSlug: String!
}

"""Represents a single builderIO page"""
type BuilderIOPage {
  apiKey: String!
  template: BuilderIOTemplate
}

"""Platform template settings type"""
type BuilderIOSettings {
  apiKey: String!
  templates: [BuilderIOTemplate!]
}

"""Template type"""
type BuilderIOTemplate {
  key: String!
  entry: String!
}

"""A collection of packages offered by a person or an organization."""
type Catalog {
  identifier: CatalogIdentifier!
  displayName: String
  description: String
  website: String
  creator: User
  isPublic: Boolean
  unclaimed: Boolean

  """The permissions of the requesting user to the catalog"""
  myPermissions: [Permission!]
  packages: [Package]
}

"""The complete identifier for a catalog on a registry"""
type CatalogIdentifier {
  registryURL: String
  catalogSlug: String!
}

"""
The complete identifier for a catalog assuming the registry is based on the HTTP request URL
"""
input CatalogIdentifierInput {
  catalogSlug: String!
}

"""
A string that uniquely identifies a catalog - this is an intermediate type only for internal validation
"""
scalar CatalogSlug

"""The response for a catalog list request"""
type CatalogsResult {
  catalogs: [Catalog!]
  hasMore: Boolean!
  count: Int!
}

"""
 A collection is a curated list of one or more packages from across one or more
catalogs on a given topic. Collections are created and maintained
by consumer side users, and generally not the publishers
"""
type Collection {
  creator: User
  identifier: CollectionIdentifier!
  name: String
  description: String
  packages: [Package!]
  createdAt: Date
  updatedAt: Date
  isPublic: Boolean
  isRecommended: Boolean
  myPermissions: [Permission!]
}

"""The basic data used to display a collection"""
type CollectionBasicData {
  identifier: CollectionIdentifier!
  name: String
  description: String
}

"""The complete identifier for a collections on a registry"""
type CollectionIdentifier {
  registryURL: String
  collectionSlug: String!
}

"""
The complete identifier for a collection assuming the registry is based on the HTTP request URL
"""
input CollectionIdentifierInput {
  collectionSlug: String!
}

"""
Represents a single package's listing in a collection, and information about how it was added
"""
type CollectionPackage {
  collection: Collection!
  package: Package!
  addedByUser: User
}

"""
A string that uniquely identifies a collection - this is an intermediate type only for internal validation
"""
scalar CollectionSlug

"""The response for collections list request"""
type CollectionsResult {
  collections: [Collection!]
  hasMore: Boolean!
  count: Int!
}

"""Defines the a connector and it's properties"""
type ConnectorDescription {
  connectorType: String!
  displayName: String
  hasSource: Boolean
  hasSink: Boolean
}

"""The information necessary to create an API Key"""
input CreateAPIKeyInput {
  label: String!
  scopes: [Scope!]!
}

"""The properties for creating a catalog"""
input CreateCatalogInput {
  slug: String!
  displayName: String!
  description: String
  website: String
  isPublic: Boolean!
  unclaimed: Boolean
}

"""For creating a new collection on the registry."""
input CreateCollectionInput {
  collectionSlug: String!
  name: String!
  description: String
  isPublic: Boolean
}

"""The properties for creating a package list."""
input CreatePackageInput {
  packageSlug: String!
  catalogSlug: String!
  displayName: String!
  description: String
}

"""The input to create a package issue comment"""
input CreatePackageIssueCommentInput {
  content: String!
}

"""The package issue creation input"""
input CreatePackageIssueInput {
  subject: String!
  content: String!
}

"""For self service new user sign up"""
input CreateUserInput {
  firstName: String
  lastName: String
  emailAddress: String!
  username: String!
  password: String!
  uiDarkModeEnabled: Boolean
}

"""For creating an new user for other people as an administrator"""
input CreateUserInputAdmin {
  firstName: String
  lastName: String
  emailAddress: String!
  username: String!
  isAdmin: Boolean!
}

"""To create a new package version, submit a valid package file"""
input CreateVersionInput {
  packageFile: PackageFileJSON
}

type Credential {
  createdAt: Date
  updatedAt: Date
  creator: User
  credentialIdentifier: String
}

type CredentialsResult {
  credentials: [Credential!]
  hasMore: Boolean!
  count: Int!
}

"""Information about the current user"""
type CurrentUser {
  user: User!
  isAdmin: Boolean!
}

"""ISO 8601 serialized Date strings"""
scalar Date

"""
A string that uniquely identifies an email address - this is an intermediate type only for internal validation
"""
scalar EmailAddress

"""The follow type"""
type Follow {
  user: User
  notificationFrequency: NotificationFrequency!
  eventTypes: [NotificationEventType]!
  catalog: Catalog
  package: Package
  collection: Collection
  packageIssue: PackageIssue
  followAllPackages: Boolean
  followAllPackageIssues: Boolean
  changeType: [ActivityLogChangeType]
}

"""The response for followers list request"""
type FollowersResult {
  followers: [User!]
  hasMore: Boolean!
  count: Int!
}

"""The follow identifier input"""
input FollowIdentifierInput {
  catalog: CatalogIdentifierInput
  package: PackageIdentifierInput
  collection: CollectionIdentifierInput
  packageIssue: PackageIssueFullIdentifierInput
  user: UserIdentifierInput
}

"""The following entities activity logs type"""
type FollowingActivity {
  logs: [ActivityLog!]!
  hasMore: Boolean!
  count: Int!
}

"""Follow Type"""
enum FollowType {
  CATALOG
  COLLECTION
  PACKAGE
  PACKAGE_ISSUE
  USER
}

type Group {
  slug: String!
  name: String
  description: String
  users: [GroupUser!]
  myPermissions: [Permission!]
  createdAt: Date
  updatedAt: Date
  creator: User
  packagePermissions: [GroupPackagePermission!]
  catalogPermissions: [GroupCatalogPermission!]
  collectionPermissions: [GroupCollectionPermission!]
  isAdmin: Boolean
}

type GroupCatalogPermission {
  group: Group
  catalog: Catalog
  permissions: [Permission!]
  packagePermissions: [Permission!]
  createdAt: Date
  updatedAt: Date
  creator: User
}

type GroupCollectionPermission {
  group: Group
  collection: Collection
  permissions: [Permission!]
  createdAt: Date
  updatedAt: Date
  creator: User
}

type GroupPackagePermission {
  group: Group
  package: Package
  permissions: [Permission!]
  createdAt: Date
  updatedAt: Date
  creator: User
}

type GroupUser {
  user: User
  group: Group
  permissions: [Permission!]
  createdAt: Date
  updatedAt: Date
  creator: User
}

type Image {
  id: String
  type: String!
  mimeType: String!
}

enum IMAGE_UPLOAD_ERROR_TYPE {
  IMAGE_NOT_INITIALIZED
  IMAGE_FORMAT_NOT_SUPPORTED
  IMAGE_TOO_LARGE
  UNKNOWN
}

enum INVALID_EMAIL_ADDRESS_ERROR {
  REQUIRED
  TOO_LONG
  INVALID_EMAIL_ADDRESS_FORMAT
}

enum INVALID_PASSWORD_ERROR {
  PASSWORD_REQUIRED
  PASSWORD_TOO_SHORT
  PASSWORD_TOO_LONG
  INVALID_CHARACTERS
}

enum INVALID_USERNAME_ERROR {
  USERNAME_REQUIRED
  USERNAME_TOO_SHORT
  USERNAME_TOO_LONG
  INVALID_CHARACTERS
}

enum JobType {
  INSTANT_NOTIFICATIONS
  HOURLY_NOTIFICATIONS
  DAILY_NOTIFICATIONS
  WEEKLY_NOTIFICATIONS
  MONTHLY_NOTIFICATIONS
  PACKAGE_UPDATE
}

"""Generic object JSON serialized strings"""
scalar JSON

"""The response for latest packages request"""
type LatestPackagesResult {
  packages: [Package!]
  hasMore: Boolean!
  count: Int!
}

"""
A string of markdown content - this is an intermidiate type only for internal validation
"""
scalar markdown

type Mutation {
  """
  Creates a new user with the given input. Returns the user's authorization token.
  """
  createMe(value: CreateUserInput!): Void

  """Updates the requesting user's information. All fields optional."""
  updateMe(value: UpdateUserInput!): CurrentUser!

  """Updates the user's admin status."""
  setAsAdmin(username: String!, isAdmin: Boolean!): Void

  """Updates the group's admin status."""
  setGroupAsAdmin(groupSlug: String!, isAdmin: Boolean!): Void

  """Updates the user's status and sends a notification email to them"""
  adminSetUserStatus(username: String!, status: UserStatus!, message: String): Void

  """Updates the logged in users' password"""
  updateMyPassword(value: UpdateMyPasswordInput!): Void

  """Sends email to user if password is forgotten"""
  forgotMyPassword(emailAddress: String!): Void

  """Sends email to user to update their password with token"""
  recoverMyPassword(value: RecoverMyPasswordInput!): Void

  """Updates the avatar image of the authenticated user"""
  setMyCoverImage(image: Base64ImageUpload!): Void

  """Updates the avatar image of the authenticated user"""
  setMyAvatarImage(image: Base64ImageUpload!): Void

  """Deletes the requesting user's account"""
  deleteMe: Void

  """Deletes a user's account"""
  adminDeleteUser(usernameOrEmailAddress: String!): Void

  """Deletes a group"""
  adminDeleteGroup(groupSlug: String!): Void

  """
  Using a username or email address and a password, requests authentication information for the given user
  """
  login(username: String!, password: String!): String!

  """Destroys the current user's session."""
  logout: Void

  """Generates a new API Key for the requesting user"""
  createAPIKey(value: CreateAPIKeyInput!): APIKeyWithSecret!

  """Deletes an existing API Key for the requesting user"""
  deleteAPIKey(id: String!): APIKey!

  """Creates a new catalog which is owned by the requesting user"""
  createCatalog(value: CreateCatalogInput!): Catalog!

  """Updates an existing catalog."""
  updateCatalog(identifier: CatalogIdentifierInput!, value: UpdateCatalogInput!): Catalog!

  """Updates the cover image of the catalog"""
  setCatalogAvatarImage(identifier: CatalogIdentifierInput!, image: Base64ImageUpload!): Void

  """Updates the cover image of the catalog"""
  deleteCatalogAvatarImage(identifier: CatalogIdentifierInput!): Void

  """Updates the cover image of the catalog"""
  setCatalogCoverImage(identifier: CatalogIdentifierInput!, image: Base64ImageUpload!): Void

  """Updates the catalog permissions of a user"""
  setUserCatalogPermission(identifier: CatalogIdentifierInput!, value: [SetUserCatalogPermissionInput!]!, message: String!): Void

  """
  Deletes and existing catalog. All packages in the catalog are then deleted
  """
  deleteCatalog(identifier: CatalogIdentifierInput!): Void

  """
  Creates a new package based on the information provided, and gives the requesting user all permissions to the package
  """
  createPackage(value: CreatePackageInput!): Package!

  """
  Updates an existing package's descriptive information. See createVersion for how to update the package data definitions
  """
  updatePackage(identifier: PackageIdentifierInput!, value: UpdatePackageInput!): Package!

  """Moves a package to a different catalog"""
  movePackage(identifier: PackageIdentifierInput!, catalogIdentifier: CatalogIdentifierInput!): Void

  """Updates the cover image of the package"""
  setPackageCoverImage(identifier: PackageIdentifierInput!, image: Base64ImageUpload!): Void

  """Deletes an existing package"""
  deletePackage(identifier: PackageIdentifierInput!): Void

  """Called by a client to indicate a package has been fetched"""
  packageFetched(identifier: VersionIdentifierInput!): Void

  """Creates a package issue"""
  createPackageIssue(packageIdentifier: PackageIdentifierInput!, issue: CreatePackageIssueInput!): PackageIssue

  """Updates a package issue"""
  updatePackageIssue(packageIdentifier: PackageIdentifierInput!, issueIdentifier: PackageIssueIdentifierInput!, issue: UpdatePackageIssueInput!): PackageIssue

  """Updates a package issue's status"""
  updatePackageIssueStatus(packageIdentifier: PackageIdentifierInput!, issueIdentifier: PackageIssueIdentifierInput!, status: UpdatePackageIssueStatusInput!): PackageIssue

  """Updates selected package issues' statuses"""
  updatePackageIssuesStatuses(packageIdentifier: PackageIdentifierInput!, issuesIdentifiers: [PackageIssueIdentifierInput!]!, status: UpdatePackageIssueStatusInput!): Void

  """Updates selected package issues' statuses"""
  deletePackageIssues(packageIdentifier: PackageIdentifierInput!, issuesIdentifiers: [PackageIssueIdentifierInput!]!): Void

  """Delete a package issue"""
  deletePackageIssue(packageIdentifier: PackageIdentifierInput!, packageIssueIdentifier: PackageIssueIdentifierInput!): Void

  """Creates a package issue comment"""
  createPackageIssueComment(packageIdentifier: PackageIdentifierInput!, issueIdentifier: PackageIssueIdentifierInput!, comment: CreatePackageIssueCommentInput!): PackageIssueComment

  """Creates a package issue comment"""
  updatePackageIssueComment(packageIdentifier: PackageIdentifierInput!, issueIdentifier: PackageIssueIdentifierInput!, issueCommentIdentifier: PackageIssueCommentIdentifierInput!, comment: UpdatePackageIssueCommentInput!): PackageIssueComment

  """Deletes a package issue"""
  deletePackageIssueComment(packageIdentifier: PackageIdentifierInput!, issueIdentifier: PackageIssueIdentifierInput!, issueCommentIdentifier: PackageIssueCommentIdentifierInput!): Void

  """Creates a new collection, which is managed by the requesting user"""
  createCollection(value: CreateCollectionInput!): Collection!

  """Updates an existing collection"""
  updateCollection(identifier: CollectionIdentifierInput!, value: UpdateCollectionInput!): Collection!

  """Updates the cover image of the collection"""
  setCollectionCoverImage(identifier: CollectionIdentifierInput!, image: Base64ImageUpload!): Void

  """Deletes an existing collection."""
  deleteCollection(identifier: CollectionIdentifierInput!): Void

  """Includes an existing package in an existing collection"""
  addPackageToCollection(collectionIdentifier: CollectionIdentifierInput!, packageIdentifier: PackageIdentifierInput!): CollectionPackage!

  """Removes an existing package from a collection"""
  removePackageFromCollection(collectionIdentifier: CollectionIdentifierInput!, packageIdentifier: PackageIdentifierInput!): Void

  """Sets the permissions for a given user collection"""
  setUserCollectionPermissions(identifier: CollectionIdentifierInput!, value: [SetUserCollectionPermissionsInput!]!, message: String!): Void

  """
  Removes all permissions for a user on a given collection. Requires the manage collection permission
  """
  deleteUserCollectionPermissions(identifier: CollectionIdentifierInput!, usernameOrEmailAddress: String!): Void

  """
  Removes all permissions for a user on a given catalog. Requires the manage catalog permission
  """
  deleteUserCatalogPermissions(identifier: CatalogIdentifierInput!, usernameOrEmailAddress: String!): Void

  """Sets the permissions for a given set of users"""
  setPackagePermissions(identifier: PackageIdentifierInput!, value: [SetPackagePermissionInput!]!, message: String!): Void

  """
  Removes all permissions for a user on a given package. Requires the manage package permission
  """
  removePackagePermissions(identifier: PackageIdentifierInput!, usernameOrEmailAddress: String!): Void

  """
  Creates a new version of the package file on a package listing. There is no
  update, as any update should also be published as a new version
  """
  createVersion(identifier: PackageIdentifierInput!, value: CreateVersionInput!): Version!

  """
  Deletes a given version from a package. Use this sparingly, to allow for a viewable history of change for the package
  """
  deleteVersion(identifier: VersionIdentifierInput!): Void

  """Given a token, validate a user's email address"""
  verifyEmailAddress(token: String!): Void

  """Accept invite emails to finish creating user"""
  acceptInvite(token: String!, username: String!, password: String!): Void

  """Follow changes for a catalog, package, collection, issue or user."""
  saveFollow(follow: SaveFollowInput!): Void

  """Delete a follow"""
  deleteFollow(follow: FollowIdentifierInput!): Void

  """Delete all authenticated user's follows"""
  deleteAllMyFollows: Void

  """Create or update platform settings"""
  savePlatformSettings(settings: PlatformSettingsInput!): PlatformSettings!

  """
  Run a named job. Key is a shared key. Job is the name of the job to run
  """
  runJob(key: String!, job: JobType!): Void

  """
  Creates a repository used by the server to access a specific source/sink
  """
  createRepository(identifier: PackageIdentifierInput!, repositoryIdentifier: String!, connectorType: String!, connectionConfiguration: JSON!): Repository!

  """
  Set the credentials used by the server to access a specific source in a package
  """
  createCredential(identifier: PackageIdentifierInput!, repositoryIdentifier: String!, connectorType: String!, credentialIdentifier: String!, credential: JSON!): Credential!

  """Permanently deletes a repository matching the given parameters"""
  deleteRepository(identifier: PackageIdentifierInput!, repositoryIdentifier: String!, connectorType: String!): Void

  """Permanently deletes a credential matching the given parameters"""
  deleteCredential(identifier: PackageIdentifierInput!, repositoryIdentifier: String!, connectorType: String!, credentialIdentifier: String!): Void
  createGroup(groupSlug: String!, name: String!, description: String!): Group!
  updateGroup(groupSlug: String!, name: String!, description: String!): Group!
  deleteGroup(groupSlug: String!): Void
  addOrUpdateUserToGroup(groupSlug: String!, userPermissions: [SetUserGroupPermissionsInput!]!): Void
  removeUserFromGroup(groupSlug: String!, username: String!): Group!
  addOrUpdateGroupToCollection(groupSlug: String!, collectionIdentifier: CollectionIdentifierInput!, permissions: [Permission!]!): GroupCollectionPermission!
  removeGroupFromCollection(groupSlug: String!, collectionIdentifier: CollectionIdentifierInput!): Void
  addOrUpdateGroupToPackage(groupSlug: String!, packageIdentifier: PackageIdentifierInput!, permissions: [Permission!]!): GroupPackagePermission!
  removeGroupFromPackage(groupSlug: String!, packageIdentifier: PackageIdentifierInput!): Void
  addOrUpdateGroupToCatalog(groupSlug: String!, catalogIdentifier: CatalogIdentifierInput!, permissions: [Permission!]!, packagePermissions: [Permission!]!): GroupCatalogPermission!
  removeGroupFromCatalog(groupSlug: String!, catalogIdentifier: CatalogIdentifierInput!): Void
}

"""My Follows result"""
type MyFollowsResult {
  follows: [Follow!]
  hasMore: Boolean!
  count: Int!
}

"""Notification Event type"""
enum NotificationEventType {
  PACKAGE_CREATED
  VERSION_CREATED
  PACKAGE_PATCH_CHANGE
  PACKAGE_MINOR_CHANGE
  PACKAGE_MAJOR_CHANGE
  PACKAGE_EDIT
  COLLECTION_PACKAGE_ADDED
  COLLECTION_PACKAGE_REMOVED
  CATALOG_PACKAGE_ADDED
  CATALOG_PACKAGE_REMOVED
  PACKAGE_ISSUE_COMMENT_CREATED
  PACKAGE_ISSUE_STATUS_CHANGE
}

"""Notification frequency"""
enum NotificationFrequency {
  INSTANT
  HOURLY
  DAILY
  WEEKLY
  MONTHLY
  NEVER
}

"""Order by options"""
enum OrderBy {
  CREATED_AT
  UPDATED_AT
}

"""
 Represents a single offering on the registry. Packages contain one or more
versions of a Package File as it is updated over time. A package may contain one or more
data data sets, and is a member of one and only one catalog. A package may be included in zero or more collections.
"""
type Package {
  creator: User
  identifier: PackageIdentifier!
  catalog: Catalog
  displayName: String
  description: String
  latestVersion: Version
  versions: [Version]
  createdAt: Date
  updatedAt: Date
  isPublic: Boolean
  myPermissions: [Permission!]
  fetchedCount: Int
  viewedCount: Int
  updateMethods: [UpdateMethod!]
}

"""The basic data used to display a collection"""
type PackageCollectionsResult {
  collections: [Collection!]
  hasMore: Boolean!
  count: Int!
}

"""The package difference type"""
type PackageDifference {
  type: PackageDifferenceType
  pointer: String
}

"""The package differences response"""
type PackageDifferences {
  newVersion: VersionIdentifierValues
  oldVersion: VersionIdentifierValues
  differences: [PackageDifference]
}

"""Package difference types"""
enum PackageDifferenceType {
  REMOVE_SCHEMA
  REMOVE_HIDDEN_SCHEMA
  ADD_SCHEMA
  REMOVE_SOURCE
  CHANGE_PACKAGE_DISPLAY_NAME
  CHANGE_PACKAGE_DESCRIPTION
  CHANGE_SOURCE
  CHANGE_SOURCE_CONFIGURATION
  CHANGE_SOURCE_CONNECTION
  CHANGE_SOURCE_CREDENTIALS
  CHANGE_SOURCE_URIS
  CHANGE_STREAM_STATS
  CHANGE_STREAM_UPDATE_HASH
  CHANGE_STREAM_UPDATE_METHOD
  ADD_PROPERTY
  HIDE_PROPERTY
  UNHIDE_PROPERTY
  REMOVE_PROPERTY
  REMOVE_HIDDEN_PROPERTY
  CHANGE_PROPERTY_TYPE
  CHANGE_PROPERTY_FORMAT
  CHANGE_PROPERTY_DESCRIPTION
  CHANGE_PROPERTY_UNIT
  CHANGE_GENERATED_BY
  CHANGE_UPDATED_DATE
  CHANGE_VERSION
  CHANGE_README_MARKDOWN
  CHANGE_LICENSE_MARKDOWN
  CHANGE_README_FILE
  CHANGE_LICENSE_FILE
  CHANGE_WEBSITE
  CHANGE_CONTACT_EMAIL
  REMOVE_STREAM_SET
}

"""Package files are JSON serialized and validated using JSONSchema"""
scalar PackageFileJSON

"""The complete identifier for a package on a registry"""
type PackageIdentifier {
  registryURL: String!
  catalogSlug: String!
  packageSlug: String!
}

"""
The complete identifier for a package, assuming the registry is based on the HTTP request URL
"""
input PackageIdentifierInput {
  catalogSlug: String!
  packageSlug: String!
}

"""
A package issue represents an issue raised for a topic related to the package.
"""
type PackageIssue {
  issueNumber: Int!
  subject: String!
  content: String
  author: User
  packageIdentifier: BasePackageIdentifier
  status: PackageIssueStatus
  createdAt: Date
  updatedAt: Date
}

type PackageIssueComment {
  commentNumber: Int!
  content: String!
  author: User!
  createdAt: Date!
  updatedAt: Date
}

"""The complete identifier for a package issue comment"""
input PackageIssueCommentIdentifierInput {
  commentNumber: Int!
}

"""The response for a package issue comments request"""
type PackageIssueCommentsResult {
  comments: [PackageIssueComment!]
  hasMore: Boolean!
  count: Int!
}

"""The complete identifier for a package issue"""
input PackageIssueFullIdentifierInput {
  packageIdentifier: PackageIdentifierInput!
  issueNumber: Int!
}

"""The complete identifier for a package issue"""
input PackageIssueIdentifierInput {
  issueNumber: Int!
}

"""The response for a package issues request"""
type PackageIssuesResult {
  issues: [PackageIssue!]
  hasMore: Boolean!
  count: Int!
  openIssuesCount: Int!
  closedIssuesCount: Int!
}

"""The package issue status"""
enum PackageIssueStatus {
  OPEN
  CLOSED
}

"""
A string that uniquely identifies a package - this is an intermediate type only for internal validation
"""
scalar PackageSlug

"""The response for packages list request"""
type PackagesResult {
  packages: [Package!]
  hasMore: Boolean!
  count: Int!
}

"""Root page content"""
type PageContent {
  builderIOPage: BuilderIOPage
  catalog: Catalog
  user: User
}

"""
A string that is a user's password - this is an intermediate type only for internal validation
"""
scalar Password

"""
General permission values, which are applied to users for catalogs, package, and other access
"""
enum Permission {
  MANAGE
  VIEW
  EDIT
  NONE
}

"""Platform settings type"""
type PlatformSettings {
  key: String!
  serializedSettings: String!
  isPublic: Boolean!
}

"""Platform settings type"""
input PlatformSettingsInput {
  key: String!
  serializedSettings: String!
  isPublic: Boolean!
}

type Query {
  """Whether the specified username is available for sign up"""
  usernameAvailable(username: String!): Boolean!

  """Whether the specified email address is avialable for sign up"""
  emailAddressAvailable(emailAddress: String!): Boolean!

  """Who am i"""
  me: CurrentUser!

  """A list of the requesting user's exisiting API key information"""
  myAPIKeys: [APIKey!]

  """Returns all of the users for a given catalog"""
  usersByPackage(identifier: PackageIdentifierInput!): [UserPackagePermissions!]!

  """Returns all of the users for a given catalog"""
  usersByCatalog(identifier: CatalogIdentifierInput!): [UserCatalogPermissions!]!

  """Returns all of the users for a given collection"""
  usersByCollection(identifier: CollectionIdentifierInput!): [UserCollectionPermissions!]!

  """Returns all of the users for a given catalog"""
  groupsByPackage(packageIdentifier: PackageIdentifierInput!): [GroupPackagePermission!]!

  """Returns all of the users for a given catalog"""
  groupsByCatalog(catalogIdentifier: CatalogIdentifierInput!): [GroupCatalogPermission!]!

  """Returns all of the users for a given collection"""
  groupsByCollection(collectionIdentifier: CollectionIdentifierInput!): [GroupCollectionPermission!]!

  """
  Return a user by a given username, and returns only the properties on that user the requester should be able to view
  """
  user(username: String!): User!

  """
  Returns package and collections with slugs that start with the given parameter
  """
  autoComplete(startsWith: String!): AutoCompleteResult!

  """For testing whether a given catalog slug is available to be reserved"""
  catalogSlugAvailable(catalogSlug: String!): Boolean!

  """
  Returns the catalogs the requesting user either owns or has edit or manage permission to
  """
  myCatalogs: [Catalog]!

  """Returns all groups the user can view"""
  myGroups: [Group!]!

  """Returns a single group"""
  group(groupSlug: String!): Group!

  """
  Returns catalogs matching the query string (which can include boolean logic combinations) with pagination
  """
  searchCatalogs(query: String!, offSet: Int!, limit: Int!): SearchCatalogsResult!

  """
  Returns the catalog of the given identifier. Requires permission to view the catalog
  """
  catalog(identifier: CatalogIdentifierInput!): Catalog!

  """
  Returns the packages of the given identifier. Requires permission to view the catalog packages
  """
  catalogPackages(identifier: CatalogIdentifierInput!, offset: Int!, limit: Int!): [Package!]!

  """
  Returns the package for the given identifier. Requires permission to view the package
  """
  package(identifier: PackageIdentifierInput!): Package!

  """Package versions diff"""
  packageVersionsDiff(newVersion: VersionIdentifierInput!, oldVersion: VersionIdentifierInput!): PackageDifferences!

  """Package versions diffs"""
  packageVersionsDiffs(packageIdentifier: PackageIdentifierInput!, offset: Int!, limit: Int!): [PackageDifferences!]

  """Package related collections"""
  packageCollections(packageIdentifier: PackageIdentifierInput!, offset: Int!, limit: Int!): PackageCollectionsResult

  """Get package issue"""
  packageIssue(packageIdentifier: PackageIdentifierInput!, packageIssueIdentifier: PackageIssueIdentifierInput!): PackageIssue

  """Get package issues"""
  packageIssues(packageIdentifier: PackageIdentifierInput!, includeOpenIssues: Boolean!, includeClosedIssues: Boolean!, offset: Int!, limit: Int!, orderBy: OrderBy!): PackageIssuesResult

  """Get package issue comments"""
  packageIssueComments(packageIdentifier: PackageIdentifierInput!, issueIdentifier: PackageIssueIdentifierInput!, offset: Int!, limit: Int!, orderBy: OrderBy!): PackageIssueCommentsResult

  """Returns list of the requesting users packages"""
  myPackages(offset: Int!, limit: Int!): PackagesResult!

  """
  Returns packages that match the given search query (which can contain boolean search logic). This request is paginated.
  """
  searchPackages(query: String!, offSet: Int!, limit: Int!): SearchPackagesResult!

  """Returns a list of packages the requesting user has recently viewed"""
  myRecentlyViewedPackages(offSet: Int!, limit: Int!): ActivityLogResult!

  """Returns users that match the given search query"""
  searchUsers(value: String!, limit: Int!, offSet: Int!): SearchUsersResult!

  """Returns users that match the given search query with no restrictions"""
  adminSearchUsers(value: String!, limit: Int!, offSet: Int!): SearchUsersResult!

  """Returns users that match the given search query with no restrictions"""
  adminSearchGroups(value: String!, limit: Int!, offSet: Int!): SearchGroupsResult!

  """Returns the latest packages"""
  latestPackages(offSet: Int!, limit: Int!): LatestPackagesResult!

  """Whether the specified colleciton slug is available"""
  collectionSlugAvailable(collectionSlug: String!): Boolean!

  """
  Returns the collection specified only if the user has permission to view it
  """
  collection(identifier: CollectionIdentifierInput!): Collection!

  """Returns all of the collections the user has permission to view"""
  collections: [Collection!]

  """Returns list of authenicated users collections"""
  myCollections(offSet: Int!, limit: Int!): CollectionsResult!

  """Returns list of collections for the given user"""
  userCollections(username: String!, offSet: Int!, limit: Int!): CollectionsResult!

  """Returns list of catalogs for the given user"""
  userCatalogs(username: String!, offSet: Int!, limit: Int!): CatalogsResult!

  """Returns list of packages for the given user"""
  userPackages(username: String!, offSet: Int!, limit: Int!): PackagesResult!

  """
  Returns collections whose names match the given query. This request is paginated
  """
  searchCollections(query: String!, offset: Int!, limit: Int!): SearchCollectionResult!

  """Returns the latest collections. This request is paginated"""
  latestCollections(offset: Int!, limit: Int!): CollectionsResult!

  """Returns a list of collections the requesting user has recently viewed"""
  myRecentlyViewedCollections(offset: Int!, limit: Int!): ActivityLogResult!

  """
  Returns collections equal to collectionSlug, gives all packages associated with collectionSlug
  """
  collectionPackages(identifier: CollectionIdentifierInput!, offset: Int!, limit: Int!): [Package!]!

  """Returns activity for the requesting user"""
  myActivity(filter: ActivityLogFilterInput!): ActivityLogResult!

  """Activity for a given collection"""
  collectionActivities(identifier: CollectionIdentifierInput!, filter: ActivityLogFilterInput!): ActivityLogResult!

  """Activity for a given package"""
  packageActivities(identifier: PackageIdentifierInput!, filter: ActivityLogFilterInput!): ActivityLogResult!

  """Activity for a given catalog"""
  catalogActivities(identifier: CatalogIdentifierInput!, filter: ActivityLogFilterInput!): ActivityLogResult!

  """Follow object for a target entity"""
  getFollow(follow: FollowIdentifierInput!): Follow

  """For validating that the server is up, and requesting it's status"""
  registryStatus: RegistryStatusResult!

  """Get authenticated user's follows"""
  myFollows(type: FollowType!, offset: Int!, limit: Int!): MyFollowsResult!

  """Get platform settings"""
  platformSettings: [PlatformSettings!]

  """Get platform settings"""
  publicPlatformSettingsByKey(key: String!): PlatformSettings!

  """Get root page content"""
  pageContent(route: String!): PageContent!

  """Get following entities activities"""
  myFollowingActivity(offset: Int!, limit: Int!): FollowingActivity

  """Get package followers"""
  packageFollowers(identifier: PackageIdentifierInput!, offset: Int!, limit: Int!): FollowersResult!

  """Get package issue followers"""
  packageIssueFollowers(identifier: PackageIdentifierInput!, issueIdentifier: PackageIssueIdentifierInput!, offset: Int!, limit: Int!): FollowersResult!

  """Get catalog followers"""
  catalogFollowers(identifier: CatalogIdentifierInput!, offset: Int!, limit: Int!): FollowersResult!

  """Get collection followers"""
  collectionFollowers(identifier: CollectionIdentifierInput!, offset: Int!, limit: Int!): FollowersResult!

  """Get user followers"""
  userFollowers(username: String!, offset: Int!, limit: Int!): FollowersResult!

  """Get package followers count"""
  packageFollowersCount(identifier: PackageIdentifierInput!): Int!

  """Get package issue followers count"""
  packageIssueFollowersCount(identifier: PackageIdentifierInput!, issueIdentifier: PackageIssueIdentifierInput!): Int!

  """Get catalog followers count"""
  catalogFollowersCount(identifier: CatalogIdentifierInput!): Int!

  """Get collection followers count"""
  collectionFollowersCount(identifier: CollectionIdentifierInput!): Int!

  """Get user followers count"""
  userFollowersCount(username: String!): Int!

  """Lists the credential identifiers, but not the credentials themselves"""
  listRepositories(identifier: PackageIdentifierInput!, limit: Int!, offset: Int!): RepositoriesResult!

  """Lists the connectors available to use via the web server"""
  listConnectors: [ConnectorDescription!]!
}

"""For sending a token response to recover and update users password"""
input RecoverMyPasswordInput {
  token: String!
  newPassword: String!
}

"""Server status states"""
enum RegistryStatus {
  SERVING_REQUESTS
}

type RegistryStatusResult {
  status: RegistryStatus!
  version: String!
  registryUrl: String!
}

type RepositoriesResult {
  repositories: [Repository!]
  hasMore: Boolean!
  count: Int!
}

type Repository {
  createdAt: Date
  updatedAt: Date
  package: Package
  credentials: [Credential!]
  creator: User
  connectorType: String
  repositoryIdentifier: String
}

"""The save follow identifier input"""
input SaveFollowInput {
  notificationFrequency: NotificationFrequency!
  catalog: CatalogIdentifierInput
  package: PackageIdentifierInput
  collection: CollectionIdentifierInput
  packageIssue: PackageIssueFullIdentifierInput
  user: UserIdentifierInput
  followAllPackages: Boolean
  followAllPackageIssues: Boolean
  changeType: [ActivityLogChangeType]
}

"""Scopes for an API Key"""
enum Scope {
  MANAGE_API_KEYS
  READ_PRIVATE_ASSETS
  MANAGE_PRIVATE_ASSETS
}

"""The response for a catalog search request"""
type SearchCatalogsResult {
  catalogs: [Catalog!]
  hasMore: Boolean!
  count: Int!
}

"""" The response for a collection search request"""
type SearchCollectionResult {
  collections: [Collection!]
  hasMore: Boolean!
  count: Int!
}

"""The response for searching users"""
type SearchGroupsResult {
  groups: [Group]!
  hasMore: Boolean!
  count: Int!
}

"""The response for a package search request"""
type SearchPackagesResult {
  packages: [Package!]
  hasMore: Boolean!
  count: Int!
}

"""The response for searching users"""
type SearchUsersResult {
  users: [User]!
  hasMore: Boolean!
  count: Int!
}

"""The properties for updating a specific user's permissions on a package"""
input SetPackagePermissionInput {
  usernameOrEmailAddress: String!
  permissions: [Permission!]!
}

input SetUserCatalogPermissionInput {
  usernameOrEmailAddress: String!

  """The users permissions for the catalog itself"""
  permission: [Permission!]!

  """The users permissions for all packages in the catalog"""
  packagePermissions: [Permission!]!
}

"""
The properties for setting a specific user's permissions on a collection
"""
input SetUserCollectionPermissionsInput {
  usernameOrEmailAddress: String!
  permissions: [Permission!]!
}

"""The properties for setting a specific user's permissions in a group"""
input SetUserGroupPermissionsInput {
  usernameOrEmailAddress: String!
  permissions: [Permission!]!
}

"""
The properties of a catalog that can be updated. All are optional. Only the ones specified are applied.
"""
input UpdateCatalogInput {
  newSlug: String
  displayName: String
  description: String
  website: String
  isPublic: Boolean
  unclaimed: Boolean
}

"""
For updating an existing collection. All properties are optional, and only the ones specified are applied
"""
input UpdateCollectionInput {
  newCollectionSlug: String
  name: String
  description: String
  isPublic: Boolean
}

enum UpdateMethod {
  CONTINUOUS
  BATCH_FULL_SET
  APPEND_ONLY_LOG
}

"""For updating a user's password"""
input UpdateMyPasswordInput {
  oldPassword: String!
  newPassword: String!
}

"""
The properties for updating an existing package. All are optional. Only the ones specified are applied.
"""
input UpdatePackageInput {
  newCatalogSlug: String
  newPackageSlug: String
  displayName: String
  description: String
  isPublic: Boolean
}

"""The input to update a package issue comment"""
input UpdatePackageIssueCommentInput {
  content: String!
}

"""The package issue update input"""
input UpdatePackageIssueInput {
  subject: String!
  content: String!
}

"""The package issue update input"""
input UpdatePackageIssueStatusInput {
  status: PackageIssueStatus!
}

"""
For updating a user, or your own user. All values are optional, and only those specified are applied.
"""
input UpdateUserInput {
  username: String
  firstName: String
  lastName: String
  emailAddress: String
  location: String
  description: String
  twitterHandle: String
  gitHubHandle: String
  website: String
  websiteIsPublic: Boolean
  nameIsPublic: Boolean
  locationIsPublic: Boolean
  twitterHandleIsPublic: Boolean
  gitHubHandleIsPublic: Boolean
  emailAddressIsPublic: Boolean
  uiDarkModeEnabled: Boolean
}

"""Represents one real world person, and their information"""
type User {
  username: String!
  displayName: String!
  firstName: String
  lastName: String
  location: String
  twitterHandle: String
  description: String
  website: String
  emailAddress: String
  gitHubHandle: String
  nameIsPublic: Boolean
  locationIsPublic: Boolean
  twitterHandleIsPublic: Boolean
  gitHubHandleIsPublic: Boolean
  emailAddressIsPublic: Boolean
  websiteIsPublic: Boolean
  isAdmin: Boolean
  status: UserStatus
  uiDarkModeEnabled: Boolean
}

"""Represents users, and their permissions on a catalog"""
type UserCatalogPermissions {
  user: User!
  permissions: [Permission!]!
  packagePermissions: [Permission!]!
}

"""Represents users, and their permissions on a collection"""
type UserCollectionPermissions {
  user: User!
  permissions: [Permission!]!
}

input UserIdentifierInput {
  username: String!
}

"""
A string that uniquely identifies a Username - this is an intermediate type only for internal validation
"""
scalar Username

"""
A string that uniquely identifies a Username or Email address - this is an intermediate type only for internal validation
"""
scalar UsernameOrEmailAddress

"""Represents a user, and their permissions on a package"""
type UserPackagePermissions {
  user: User
  permissions: [Permission!]!
}

enum UserStatus {
  PENDING_SIGN_UP
  ACTIVE
  SUSPENDED
}

"""
ValidationString is used at runtime by the validate***Directive classes to allow apollo a intermediate type for conversions
"""
scalar ValidationString

"""
A single version of a package file for a package listing on the registry
"""
type Version {
  identifier: VersionIdentifier!
  createdAt: Date
  updatedAt: Date
  package: Package

  """The user that created this version"""
  author: User

  """
  A package file that may be modified by the server to remove sensitive information. See the PackageFile.canonical value
  to determine whether it has been modified.
  """
  packageFile: PackageFileJSON

  """
  An unmodified copy of the package file that is only returned when the requesting user has EDIT permissions on the package.
  """
  canonicalPackageFile: PackageFileJSON
  updateMethods: [UpdateMethod!]
}

"""Potential responses when creating a new version on a package"""
enum VersionConflict {
  VERSION_EXISTS
  HIGHER_VERSION_EXISTS
  HIGHER_VERSION_REQUIRED
}

"""The complete identifier for a version of a package on a registry"""
type VersionIdentifier {
  registryURL: String
  catalogSlug: String!
  packageSlug: String!
  versionMajor: Int!
  versionMinor: Int!
  versionPatch: Int!
}

"""
The complete identifier for a version of a package, assuming the registry is based on the HTTP request URL
"""
input VersionIdentifierInput {
  catalogSlug: String!
  packageSlug: String!
  versionMajor: Int!
  versionMinor: Int!
  versionPatch: Int!
}

"""The version values"""
type VersionIdentifierValues {
  versionMajor: Int!
  versionMinor: Int!
  versionPatch: Int!
}

"""A no-return value, mapped to void in typescript"""
scalar Void

`;
