<div class="container">
    <div class="row pl-0 pt-3 pr-0 pb-3 desktop-menu">
        <div class="px-0 col-md-10 col-sm-12">
            <div class="d-flex flex-row bd-highlight">
                <div class="pr-2 pt-2 pb-2 bd-highlight align-self-center logo-div">
                    <h1 class="mr-4 mb-0"><a class="dpm-text-logo" (click)="goHome()">datapm</a></h1>
                </div>
                <div class="p-2 bd-highlight w-100">
                    <div class="input-group mt-1 search-box">
                        <form class="search-form">
                            <app-input
                                placeholder="Search For Data..."
                                [control]="searchControl"
                                class="rounded-input search-input"
                                matInput
                                [matAutocomplete]="auto"
                            ></app-input>
                            <mat-autocomplete
                                #auto="matAutocomplete"
                                class="search-autocomplete"
                                (optionSelected)="autoCompleteOptionSelected($event)"
                            >
                                <mat-option *ngIf="!autoCompleteResult && searchControl.value?.length > 1">
                                    <mat-spinner [diameter]="30" class="ml-3"></mat-spinner>
                                </mat-option>
                                <ng-container *ngIf="autoCompleteResult">
                                    <mat-option
                                        *ngFor="let option of autoCompleteResult.catalogs"
                                        [value]="option.identifier.catalogSlug"
                                    >
                                        <div class="d-flex">
                                            <div>
                                                <app-avatar class="align-top user-img" [catalog]="option" [size]="40">
                                                </app-avatar>
                                            </div>

                                            <div class="autocomplete-contents">
                                                <div class="autocomplete-title">
                                                    <a href="javascript:void(0)">{{ option.identifier.catalogSlug }}</a>
                                                </div>

                                                <div class="autocomplete-description">{{ option.description }}</div>
                                            </div>
                                        </div>
                                    </mat-option>

                                    <mat-option
                                        *ngFor="let option of autoCompleteResult.collections"
                                        [value]="'collection/' + option.identifier.collectionSlug"
                                    >
                                        <div class="d-flex">
                                            <div style="width: 40px; height: 40px">&nbsp;</div>

                                            <div class="autocomplete-contents">
                                                <div class="autocomplete-title">
                                                    <a href="javascript:void(0)">collection/{{ option.name }}</a>
                                                </div>

                                                <div class="autocomplete-description">{{ option.description }}</div>
                                            </div>
                                        </div>
                                    </mat-option>

                                    <mat-option
                                        *ngFor="let option of autoCompleteResult.packages"
                                        [value]="option.identifier.catalogSlug + '/' + option.identifier.packageSlug"
                                    >
                                        <div class="d-flex">
                                            <div>
                                                <app-avatar
                                                    class="align-top user-img"
                                                    [user]="option.creator"
                                                    [size]="40"
                                                >
                                                </app-avatar>
                                            </div>

                                            <div class="autocomplete-contents">
                                                <div class="autocomplete-title">
                                                    <a href="javascript:void(0)">{{
                                                        option.identifier.catalogSlug +
                                                            "/" +
                                                            option.identifier.packageSlug
                                                    }}</a>
                                                </div>

                                                <div class="autocomplete-description">{{ option.description }}</div>
                                            </div>
                                        </div>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                            <button mat-icon-button (click)="search()">
                                <mat-icon>search</mat-icon>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-sm-12 header-right pr-0">
            <div class="d-flex flex-row bd-highlight">
                <div class="bd-highlight main-menu-title d-flex align-items-end">
                    <a (click)="publishClicked()">Publish</a>
                </div>
                <div class="bd-highlight ml-auto mr-2 avatar">
                    <app-avatar
                        [user]="currentUser.user"
                        [matMenuTriggerFor]="beforeMenu"
                        *ngIf="currentUser"
                        [size]="40"
                    ></app-avatar>
                    <button
                        mat-fab
                        class="app-fab-button"
                        [matMenuTriggerFor]="beforeMenu"
                        aria-label="Person"
                        *ngIf="!currentUser"
                    >
                        <mat-icon>person</mat-icon>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button *ngIf="currentUser?.isAdmin" mat-menu-item (click)="goToAdminDashboard()">Admin</button>
                        <button *ngIf="currentUser" mat-menu-item (click)="goToMyDetails()">My Profile</button>
                        <button *ngIf="!currentUser" mat-menu-item (click)="openLoginDialog()">Log In</button>
                        <button *ngIf="!currentUser" mat-menu-item (click)="openSignUpDialog()">Sign Up</button>
                        <button *ngIf="currentUser" mat-menu-item (click)="logout()">Log Out</button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mobile-menu">
    <div class="row pt-3 px-3">
        <div class="col-7 logo-div d-flex align-items-center">
            <h1 routerLink="/"><a class="dpm-text-logo" (click)="goHome()">datapm</a></h1>
        </div>

        <div class="col-5 text-right d-flex justify-content-end align-items-center">
            <div class="d-flex flex-row bd-highlight d-flex justify-content-end">
                <div class="bd-highlight mobile-person">
                    <app-avatar
                        [user]="currentUser.user"
                        [matMenuTriggerFor]="beforeMenu"
                        *ngIf="currentUser"
                    ></app-avatar>
                    <button
                        mat-fab
                        class="app-fab-button"
                        [matMenuTriggerFor]="beforeMenu"
                        aria-label="Person"
                        *ngIf="!currentUser"
                    >
                        <mat-icon>person</mat-icon>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button *ngIf="currentUser" mat-menu-item (click)="goToMyDetails()">My Profile</button>
                        <button *ngIf="!currentUser" mat-menu-item (click)="openLoginDialog()">Log In</button>
                        <button *ngIf="!currentUser" mat-menu-item (click)="openSignUpDialog()">Sign Up</button>
                        <button *ngIf="currentUser" mat-menu-item (click)="logout()">Log Out</button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
    <div class="row px-3">
        <div class="col-12 mobile-search-box mt-3">
            <form class="search-form w-100">
                <app-input
                    placeholder="Search For Data..."
                    [control]="searchControl"
                    class="rounded-input search-input"
                    matInput
                    [matAutocomplete]="auto"
                ></app-input>
                <mat-autocomplete
                    #auto="matAutocomplete"
                    class="search-autocomplete"
                    (optionSelected)="autoCompleteOptionSelected($event)"
                >
                    <mat-option *ngIf="!autoCompleteResult && searchControl.value?.length > 1">
                        <mat-spinner [diameter]="30" class="ml-3"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="autoCompleteResult">
                        <mat-option
                            *ngFor="let option of autoCompleteResult.packages"
                            [value]="option.identifier.catalogSlug + '/' + option.identifier.packageSlug"
                        >
                            <div class="d-flex">
                                <div>
                                    <app-avatar class="align-top user-img" [user]="option.creator" [size]="40">
                                    </app-avatar>
                                </div>

                                <div class="autocomplete-contents">
                                    <div class="autocomplete-title">
                                        {{ option.identifier.catalogSlug + " / " + option.identifier.packageSlug }}
                                    </div>

                                    <div class="autocomplete-description">{{ option.description }}</div>
                                </div>
                            </div>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
                <button mat-icon-button (click)="search()">
                    <mat-icon>search</mat-icon>
                </button>
            </form>
        </div>
    </div>
</div>
