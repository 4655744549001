<div class="container" id="user-following">
    <div class="row">
        <div class="col-md-12">
            <div class="panel-div pl-2">
                <div class="text-main-title">My Follows</div>
                <div class="text-left" *ngIf="!hasFollows">
                    <p class="text-dark-gray">
                        Use the 'follow' button throughout this registry to be notified of changes. You are currently
                        not following any packages, users, collections, or catalogs.
                    </p>
                </div>

                <div class="text-left" *ngIf="hasFollows">
                    <p class="text-dark-gray">
                        Below are the resources you've chosen to follow by clicking the "Follow" button throughout this
                        site.
                    </p>
                </div>

                <div class="user-div mt-4" [hidden]="stats.userFollowsCount == 0">
                    <div class="text-main-title font-16">Users</div>
                    <app-user-type-following
                        [itemsName]="'users'"
                        [stats]="stats"
                        [type]="FollowType.USER"
                    ></app-user-type-following>
                </div>

                <div class="collection-div mt-4" [hidden]="stats.collectionFollowsCount == 0">
                    <div class="text-main-title font-16">Collections</div>
                    <app-user-type-following
                        [itemsName]="'collections'"
                        [stats]="stats"
                        [type]="FollowType.COLLECTION"
                    ></app-user-type-following>
                </div>

                <div class="package-div mt-4" [hidden]="stats.packageFollowsCount == 0">
                    <div class="text-main-title font-16">Packages</div>
                    <app-user-type-following
                        [itemsName]="'packages'"
                        [stats]="stats"
                        [type]="FollowType.PACKAGE"
                    ></app-user-type-following>
                </div>

                <div class="user-div mt-4" [hidden]="stats.packageIssueFollowsCount == 0">
                    <div class="text-main-title font-16">Package Issues</div>
                    <app-user-type-following
                        [itemsName]="'package issues'"
                        [stats]="stats"
                        [type]="FollowType.PACKAGE_ISSUE"
                    ></app-user-type-following>
                </div>

                <div class="catalog-div mt-4" [hidden]="stats.catalogFollowsCount == 0">
                    <div class="text-main-title font-16">Catalogs</div>
                    <app-user-type-following
                        [itemsName]="'catalogs'"
                        [stats]="stats"
                        [type]="FollowType.CATALOG"
                    ></app-user-type-following>
                </div>
            </div>
        </div>
    </div>
</div>
