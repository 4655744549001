<div class="container">
    <div class="row">
        <form [formGroup]="form" (submit)="submit($event)" id="addPackage">
            <div mat-dialog-title id="contentTitle" class="mb-3">Add Package To Collection</div>
            <div mat-dialog-content class="py-3" id="contentBorder">
                <p class="mt-0 mb-4 add-package-text">Select the package and collection below.</p>
                <div class="d-flex bd-highlight shadow-div my-3">
                    <div class="bd-highlight div-question py-2">Package</div>
                    <div class="bd-highlight div-answer">
                        <app-package-autocomplete [form]="form"></app-package-autocomplete>
                    </div>
                </div>
                <div class="d-flex bd-highlight shadow-div mb-3">
                    <div class="bd-highlight div-question my-2">
                        <span class="mt-2">Collection</span>
                    </div>
                    <div class="bd-highlight div-answer">
                        <app-input
                            class="flat-input px-0"
                            [group]="form"
                            controlName="collectionName"
                            inputStyle="flat"
                            placeholder="Collection"
                            [matAutocomplete]="collectionAutoComplete"
                            [error]="form | inputError: 'collectionName' | async"
                        ></app-input>
                        <mat-autocomplete
                            autoActiveFirstOption
                            #collectionAutoComplete="matAutocomplete"
                            (optionSelected)="selectCollection($event.option.value)"
                            [displayWith]="displayCollection"
                        >
                            <mat-option *ngFor="let collection of collections" [value]="collection">
                                {{ collection.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

                <div class="text-danger mt-3" *ngIf="state === 'ERROR'">
                    <ng-container [ngSwitch]="error">
                        <ng-container *ngSwitchCase="'CATALOG_NOT_FOUND'">
                            No catalog with that identifier is found on this registry
                        </ng-container>
                        <ng-container *ngSwitchCase="'PACKAGE_NOT_FOUND'">
                            No package with that identifier is found on this registry.
                        </ng-container>
                        <ng-container *ngSwitchCase="'COLLECTION_NOT_FOUND'">
                            No collection with that identifier is found on this registry.
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            Error occured while adding a package to the collection. Please try again later, or contact
                            support.
                        </ng-container>
                    </ng-container>
                </div>

                <ng-container *ngIf="!searchCollectionsTimeout">
                    <div
                        class="text-warn mt-3"
                        *ngIf="shouldShowCreatingNewCollectionLabel && selectedValidCollectionSlug"
                    >
                        A new collection will be created.
                    </div>

                    <div
                        class="text-danger mt-3"
                        *ngIf="shouldShowCreatingNewCollectionLabel && !selectedValidCollectionSlug"
                    >
                        You do not have permission to edit this collection. Please choose another name.
                    </div>
                </ng-container>
            </div>
            <div mat-dialog-actions class="mat-dialog-actions">
                <button type="submit" class="ml-auto modal-submit-btn" [disabled]="state == 'LOADING'">
                    <span>Add Package To Collection</span>
                </button>
            </div>
        </form>
    </div>
</div>
