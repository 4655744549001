<div class="container" id="followDialog">
    <div class="row">
        <div class="col-md-12 p-0">
            <div mat-dialog-title id="contentTitle">Following</div>
            <div mat-dialog-content id="contentBorder">
                <p class="text-dark-gray">Notification Frequency</p>
                <mat-form-field appearance="fill" floatLabel="never">
                    <mat-select [(value)]="selectedFrequency">
                        <mat-option *ngFor="let frequency of frequencies" [value]="frequency">
                            {{ frequency | titlecase }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="canFollowAllPackages()">
                    <mat-checkbox class="example-margin" [(ngModel)]="followAllPackages">
                        Follow all packages
                    </mat-checkbox>
                </div>

                <div class="mt-3" *ngIf="canFollowPackageContent()">
                    <p class="text-dark-gray">Change Type</p>
                    <mat-form-field appearance="fill" floatLabel="never">
                        <mat-select [disabled]="!followAllPackages" [(value)]="selectedChangeType">
                            <mat-option *ngFor="let changeType of PACKAGE_CHANGE_TYPES" [value]="changeType">
                                {{ changeType.label | titlecase }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="mt-3" *ngIf="canFollowPackageContent()">
                    <mat-checkbox
                        [disabled]="!followAllPackages"
                        class="example-margin"
                        [(ngModel)]="followAllPackageIssues"
                    >
                        Follow all package issues
                    </mat-checkbox>
                </div>

                <div class="alert-div">
                    <div class="alert-warn" *ngIf="submitState == 'ERROR'">
                        There was a problem communicating with the server. Please try again, or contact support.
                    </div>
                </div>
            </div>
            <div mat-dialog-actions class="d-flex justify-content-between pb-3 pt-2">
                <button *ngIf="isFollowing" (click)="deleteFollow()" mat-button type="submit" class="modal-close-btn">
                    <span>Unfollow</span>
                </button>
                <button *ngIf="!isFollowing" (click)="close()" mat-button type="submit" class="modal-close-btn">
                    <span>Cancel</span>
                </button>
                <button mat-button type="submit" class="modal-submit-btn" (click)="save()">
                    <span>Save</span>
                </button>
            </div>
        </div>
    </div>
</div>
