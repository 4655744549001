<app-cover [username]="user?.username" id="profileCover"></app-cover>
<div class="row pl-2" *ngIf="user" id="detailsHeader">
    <div class="col-lg-12">
        <div class="d-flex user-header">
            <div class="p-2">
                <app-avatar class="user-img" [user]="user" [size]="74"></app-avatar>
            </div>
            <div class="p-2 align-self-center" [class.not-current-user]="!isCurrentUser">
                <span *ngIf="user.firstName && user.lastName && (user.nameIsPublic || isCurrentUser)">
                    <span class="text-main-title font-22">{{ user | username }}</span>
                    <span *ngIf="user.nameIsPublic" class="public-text">public</span>
                    <span *ngIf="!user.nameIsPublic" class="not-public-text">not public</span><br />
                </span>

                <div class="username dpm-text-body my-2">
                    <span [class.text-main-title]="!user.firstName && !user.lastName">{{ "@" + user.username }}</span>
                    <span class="public-text">public</span>
                </div>
            </div>
            <div *ngIf="!isCurrentUser" class="p-2 ml-auto d-flex align-items-center">
                <button class="app-raised-button follow-btn" (click)="follow()">
                    <span *ngIf="isFollowing"><em class="fas fa-bell-slash"></em>Following</span>
                    <span *ngIf="!isFollowing"><em class="fas fa-bell"></em>Follow</span>
                </button>
            </div>
        </div>
        <div class="px-3 pt-1">
            <div *ngIf="user.website && (user.websiteIsPublic || isCurrentUser)" class="pb-1">
                <span class="social-link">
                    <a target="_blank" href="{{ user.website }}">{{ user.website }}</a>
                    <span *ngIf="user.websiteIsPublic && isCurrentUser" class="public-text">public</span>
                    <span *ngIf="!user.websiteIsPublic && isCurrentUser" class="not-public-text">not public</span>
                </span>
            </div>

            <div>
                <span class="social-link" *ngIf="user.emailAddressIsPublic || isCurrentUser">
                    <a target="_blank" href="mailto:{{ user.emailAddress }}" class="social-img">
                        <em class="far fa-envelope"></em>
                    </a>
                    <span *ngIf="user.emailAddressIsPublic && isCurrentUser" class="public-text">public</span>
                    <span *ngIf="!user.emailAddressIsPublic && isCurrentUser" class="not-public-text">not public</span>
                </span>

                <span class="social-link" *ngIf="user.twitterHandle && (user.twitterHandleIsPublic || isCurrentUser)">
                    <a target="_blank" href="https://twitter.com/{{ user.twitterHandle }}" class="social-img">
                        <em class="fab fa-twitter"></em>
                    </a>
                    <span *ngIf="user.twitterHandleIsPublic && isCurrentUser" class="public-text">public</span>
                    <span *ngIf="!user.twitterHandleIsPublic && isCurrentUser" class="not-public-text">not public</span>
                </span>

                <span class="social-link" *ngIf="user.gitHubHandle && (user.gitHubHandleIsPublic || isCurrentUser)">
                    <a target="_blank" href="https://github.com/{{ user.gitHubHandle }}" class="social-img">
                        <em class="fab fa-github"></em>
                    </a>
                    <span *ngIf="user.gitHubHandleIsPublic && isCurrentUser" class="public-text">public</span>
                    <span *ngIf="!user.gitHubHandleIsPublic && isCurrentUser" class="not-public-text">not public</span>
                </span>
            </div>

            <p class="text-dark-gray pt-1">
                {{ user.description }}
            </p>
        </div>
    </div>
</div>
