<div
    class="avatar raised"
    [class.avatar-circle]="circled"
    [style.width]="size + 'px'"
    [style.height]="size + 'px'"
    [style.fontSize]="size + 'px'"
>
    <span *ngIf="state == State.LOADED">
        <img [src]="selectedImageData" alt="avatar" *ngIf="selectedImageData" />
        <div class="letter" *ngIf="!selectedImageData" [style.background]="backgroundColor">{{ letter }}</div>
        <button class="add-image-btn" (click)="uploadFile()" *ngIf="editable">
            <mat-icon>add_a_photo</mat-icon>
            <span class="helper-text">click to change</span>
        </button>
    </span>
</div>
