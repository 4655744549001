<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div id="catalogsPage">
                <div class="catalogs-div panel-div">
                    <ng-container *ngIf="catalogState !== State.LOADING">
                        <div class="text-main-title col-md-12 no-pd-mobile">
                            {{ isCurrentUser ? "My Catalogs" : "Catalogs" }}
                        </div>
                        <p class="col-lg-8 no-pd-mobile">
                            <span *ngIf="isCurrentUser" class="text-dark-gray"
                                >Catalogs represent a single individual or organization. All data packages belong to one
                                and only one catalog.</span
                            >
                            <span *ngIf="!isCurrentUser && myCatalogs.length > 0" class="text-dark-gray"
                                >Catalogs created or contributed to by
                                <span style="font-weight: 500">{{ username }}</span>.
                            </span>

                            <span *ngIf="!isCurrentUser && myCatalogs.length == 0" class="text-dark-gray">
                                Either <span style="font-weight: 500">{{ username }}</span> has not created any
                                catalogs, or you do not have permission to view any of the catalogs they have created.
                                
                            </span>
                            <a target="_blank" href="/docs/organize-data">
                                <span style="font-size: 13px"> Learn more about catalogs.</span>
                            </a>
                        </p>

                        <div
                            class="mb-4 mt-4 col-md-12 no-pd-mobile"
                            *ngIf="catalogState !== State.LOADING && isCurrentUser"
                        >
                            <a class="app-outlined-button" (click)="createCatalog()">Create A Catalog</a>
                        </div>
                    </ng-container>

                    <div class="col-lg-10 col-md-12 no-pd-mobile">
                        <app-catalog-item
                            *ngFor="let catalog of myCatalogs"
                            [item]="catalog"
                            (click)="clickCatalog(catalog)"
                            (edited)="refreshCatalogs()" 
                            (deleted)="refreshCatalogs()" 
                        ></app-catalog-item>
                        <div class="loading-container" *ngIf="catalogState === State.LOADING">
                            <mat-spinner [diameter]="40"></mat-spinner>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template #deleteMyUsercatalog>
                <h2 matDialogTitle>Cannot delete your user catalog</h2>
                <mat-dialog-content>
                    <p>You can not delete the catalog that's the same as your username.</p>
                </mat-dialog-content>
                <mat-dialog-actions align="end">
                    <button mat-button matDialogClose>Close</button>
                </mat-dialog-actions>
            </ng-template>
        </div>
    </div>
</div>
