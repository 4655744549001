<div class="table-content">
    <table mat-table [dataSource]="follows" class="w-100 gray-table" aria-labelledby="user-following">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef scope="col">Name</th>
            <td mat-cell *matCellDef="let follow">
                <a [routerLink]="getFollowEntityLink(follow)">{{ getFollowEntityName(follow) }}</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="frequency">
            <th mat-header-cell *matHeaderCellDef scope="col">Notification Frequency</th>
            <td mat-cell *matCellDef="let follow">
                <mat-form-field appearance="fill" class="permission-div">
                    <mat-select [(value)]="follow.notificationFrequency" (selectionChange)="updateFollow(follow)">
                        <mat-option [value]="frequency" *ngFor="let frequency of NOTIFICATION_FREQUENCIES"
                            >{{ frequency | titlecase }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef scope="col">Action</th>
            <td mat-cell *matCellDef="let follow">
                <button (click)="openFollowModal(follow)" class="app-outlined-button">
                    <span class="">Options</span>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="COLUMNS"></tr>
        <tr mat-row *matRowDef="let row; columns: COLUMNS"></tr>
    </table>

    <div class="text-right mt-1">
        <a *ngIf="hasMore" [disabled]="loading" (click)="loadFollows()" class="cursor-pointer"
            >Show more {{ itemsName }}
        </a>
    </div>
</div>
