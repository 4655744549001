import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-few-packages-alert",
    templateUrl: "./few-packages-alert.component.html",
    styleUrls: ["./few-packages-alert.component.scss"]
})
export class FewPackagesAlertComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
