<div class="dialog-wrapper-account">
    <div class="dialog-close-wrapper">
        <button mat-button mat-dialog-close class="dialog-close">close</button>
    </div>
    <h1 class="dialog-title">datapm</h1>

    <div *ngIf="state != State.SUCCESS && state != State.ERROR_AFTER_SIGNUP">
        <form [formGroup]="form" (ngSubmit)="formSubmit()">
            <div class="py-4">
                <p class="dialog-subtile pb-0">Forgot password recovery</p>

                <app-input
                    class="rounded-input"
                    placeholder="Your Email Address"
                    [group]="form"
                    controlName="emailAddress"
                    [error]="
                        form
                            | inputError
                                : 'emailAddress'
                                : {
                                      required: 'Email is required',
                                      notAvailable: 'That email address does not exist. Please use correct email.'
                                  }
                            | async
                    "
                >
                </app-input>
            </div>

            <div class="text-center mb-2">
                <button type="submit" class="app-raised-button" [disabled]="form.invalid">
                    <span class="btn-text">Send Password Reset Email</span>
                </button>
            </div>
            <div class="text-center mt-3">
                <a href="#" (click)="backToLogin($event)">Back to login</a>
            </div>
        </form>
    </div>

    <div class="div-with-errors" *ngIf="state == State.SUCCESS">
        <div class="success-msg">
            If the email you submitted is a registered account, you will receive a 'Recover Your Password' email. Click
            the link in that email to recover your password.
        </div>
        <div class="text-center">
            <button mat-dialog-close class="app-raised-button my-4">
                <span class="close-text">Back to browse</span>
            </button>
        </div>
    </div>

    <div class="div-with-errors" *ngIf="state == State.ERROR_AFTER_SIGNUP">
        <div class="alert-danger" style="margin: 35px 0px">
            {{ error }}
        </div>
        <div class="text-center">
            <button mat-dialog-close class="app-raised-button my-4">
                <span class="close-text">Back to browse</span>
            </button>
        </div>
    </div>
</div>
