<div class="container">
    <div class="row">
        <form id="createCollection" [formGroup]="form">
            <div mat-dialog-title id="contentTitle">Create collection</div>
            <app-cover [editable]="true" (upload)="addCoverImage($event)"></app-cover>

            <div mat-dialog-content id="contentBorder">
                <div class="d-flex bd-highlight">
                    <div class="pr-3 py-1 bd-highlight div-question long-txt">Make this collection public</div>
                    <div class="ml-auto py-1 bd-highlight slide-right d-flex align-items-center">
                        <span *ngIf="isPublicControl.value" class="public-text mr-2">public</span>
                        <span *ngIf="!isPublicControl.value" class="not-public-text mr-2">not public</span>
                        <mat-slide-toggle class="app-slide-toggle" formControlName="isPublic"></mat-slide-toggle>
                    </div>
                </div>
                <div class="d-flex bd-highlight shadow-div my-3">
                    <div class="pr-3 py-2 bd-highlight div-question">Collection Name*</div>
                    <div class="bd-highlight div-answer">
                        <input
                            formControlName="name"
                            class="flat-input"
                            placeholder="Collection Name"
                            cdkFocusInitial
                        />
                    </div>
                </div>
                <div class="d-flex bd-highlight shadow-div mb-3">
                    <div class="pr-4 py-2 bd-highlight div-question">Description</div>
                    <div class="bd-highlight div-answer">
                        <textarea
                            formControlName="description"
                            rows="5"
                            class="flat-input"
                            placeholder="Description"
                        ></textarea>
                    </div>
                </div>
                <div class="text-danger pl-4" *ngIf="state === 'ERROR'">
                    {{ error }}
                </div>
            </div>
            <div mat-dialog-actions class="text-right">
                <button (click)="submit()" class="ml-auto modal-submit-btn" [class.disabled]="form.invalid">
                    <span *ngIf="state !== 'LOADING'">create</span>
                    <mat-spinner [diameter]="20" *ngIf="state === 'LOADING'"></mat-spinner>
                </button>
            </div>
        </form>
    </div>
</div>
