<form id="editAccount" [formGroup]="form">
    <app-cover [username]="currentUser.user.username" [editable]="true" (upload)="uploadCover($event)"></app-cover>

    <app-avatar class="user-img" [user]="currentUser" [size]="100" [editable]="true" (upload)="uploadAvatar($event)">
    </app-avatar>
    <p class="small-paragraph">public</p>

    <div mat-dialog-content id="contentBorder">
        <div class="dialog-content-text">
            <div class="d-flex bd-highlight shadow-div mb-3">
                <div class="px-4 py-2 bd-highlight div-question">email</div>
                <div class="bd-highlight div-answer">
                    <input
                        class="flat-input"
                        formControlName="emailAddress"
                        type="email"
                        class="flat-input"
                        placeholder="Add your email"
                    />
                </div>
                <div class="ml-auto px-4 py-2 bd-highlight slide-right">
                    <mat-slide-toggle formControlName="emailAddressIsPublic"></mat-slide-toggle>
                </div>
            </div>

            <div class="d-flex bd-highlight shadow-div mb-3">
                <div class="px-4 py-2 bd-highlight div-question">username</div>
                <div class="bd-highlight div-answer">
                    <input
                        class="flat-input"
                        (focus)="openConfirmDialog()"
                        formControlName="username"
                        type="text"
                        class="flat-input"
                        placeholder="Add your username"
                    />
                    <div class="div-with-errors">
                        <div class="alert-warn" *ngIf="username.errors?.REQUIRED">Username is required.</div>
                        <div class="alert-warn" *ngIf="username.errors?.INVALID_CHARACTERS">
                            Username must contain only numbers, letters, hyphens, and may not start or end with a
                            hyphen.
                        </div>
                        <div class="alert-warn" *ngIf="username.errors?.USERNAME_TOO_LONG">
                            Username must be less than 40 characters long.
                        </div>
                        <div class="alert-warn" *ngIf="username.errors?.NOT_AVAILABLE">
                            That username is not available.
                        </div>
                    </div>
                </div>
                <div class="ml-auto px-4 py-2 bd-highlight slide-right">
                    <mat-slide-toggle [checked]="true" [disabled]="true"></mat-slide-toggle>
                </div>
            </div>

            <div class="d-flex bd-highlight shadow-div mb-3">
                <div class="px-4 py-2 bd-highlight div-question">first name</div>
                <div class="bd-highlight div-answer">
                    <input formControlName="firstName" type="text" class="flat-input" placeholder="First name" />
                </div>
                <div class="ml-auto px-4 py-2 bd-highlight slide-right">
                    <mat-slide-toggle [checked]="nameIsPublic" (change)="toggleNameIsPublic($event)"></mat-slide-toggle>
                </div>
            </div>

            <div class="d-flex bd-highlight shadow-div mb-3">
                <div class="px-4 py-2 bd-highlight div-question">last name</div>
                <div class="bd-highlight div-answer">
                    <input formControlName="lastName" type="text" class="flat-input" placeholder="Last name" />
                </div>
                <div class="ml-auto px-4 py-2 bd-highlight slide-right">
                    <mat-slide-toggle [checked]="nameIsPublic" (change)="toggleNameIsPublic($event)"></mat-slide-toggle>
                </div>
            </div>

            <div class="d-flex bd-highlight shadow-div mb-3">
                <div class="px-4 py-2 bd-highlight div-question">bio</div>
                <div class="bd-highlight div-answer">
                    <textarea formControlName="description" class="flat-input" placeholder="Add your bio"></textarea>
                </div>
                <div class="ml-auto px-4 py-2 bd-highlight slide-right">
                    <mat-slide-toggle [checked]="true" [disabled]="true"></mat-slide-toggle>
                </div>
            </div>

            <div class="d-flex bd-highlight shadow-div mb-3">
                <div class="px-4 py-2 bd-highlight div-question">location</div>
                <div class="bd-highlight div-answer">
                    <input formControlName="location" type="email" class="flat-input" placeholder="Add your location" />
                </div>
                <div class="ml-auto px-4 py-2 bd-highlight slide-right">
                    <mat-slide-toggle formControlName="locationIsPublic"></mat-slide-toggle>
                </div>
            </div>

            <div class="d-flex bd-highlight shadow-div mb-3">
                <div class="px-4 py-2 bd-highlight div-question">website</div>
                <div class="bd-highlight div-answer">
                    <input formControlName="website" type="email" class="flat-input" placeholder="Add your website" />
                </div>
                <div class="ml-auto px-4 py-2 bd-highlight slide-right">
                    <mat-slide-toggle formControlName="websiteIsPublic"></mat-slide-toggle>
                </div>
            </div>

            <div class="d-flex bd-highlight shadow-div mb-3">
                <div class="px-4 py-2 bd-highlight div-question">twitter</div>
                <div class="bd-highlight div-answer">
                    <input
                        formControlName="twitterHandle"
                        type="email"
                        class="flat-input"
                        placeholder="Add your twitter handle"
                    />
                </div>
                <div class="ml-auto px-4 py-2 bd-highlight slide-right">
                    <mat-slide-toggle formControlName="twitterHandleIsPublic"></mat-slide-toggle>
                </div>
            </div>

            <div class="d-flex bd-highlight shadow-div mb-3">
                <div class="px-4 py-2 bd-highlight div-question">github</div>
                <div class="bd-highlight div-answer">
                    <input
                        formControlName="gitHubHandle"
                        type="email"
                        class="flat-input"
                        placeholder="Add your github username"
                    />
                </div>
                <div class="ml-auto px-4 py-2 bd-highlight slide-right">
                    <mat-slide-toggle formControlName="gitHubHandleIsPublic"></mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button
            [mat-dialog-close]="true"
            (click)="submit()"
            class="ml-auto modal-submit-btn"
            [class.disabled]="form.invalid || form.pending"
            [disabled]="form.invalid || form.pending"
        >
            <span>save</span>
        </button>
    </div>
</form>
